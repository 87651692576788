import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";

import type React from "react";
import { useEffect, useMemo, useState } from "react";
import FormLabel from "components/atoms/FormLabel";
import { licenses } from "constants/teachingLicenses";

type ProfileContentProps = {
  isEdit?: boolean;
};

const LicenseField: React.FC<ProfileContentProps> = () => {
  const theme = useTheme();
  const { setValue, getValues } = useFormContext();
  const [specialSubjectValues, setSpecialSubjectValues] = useState<
    (string | null)[]
  >([null, null, null, null, null]);

  const teachingLicenses = useMemo(
    () => getValues("teaching_licenses") || [],
    [getValues("teaching_licenses")]
  );

  const handleChange = (id: number, checked: boolean) => {
    if (checked) {
      const newLicense = { id, special_subject: specialSubjectValues[id - 1] };
      setValue("teaching_licenses", [...teachingLicenses, newLicense], {
        shouldValidate: true,
        shouldDirty: true
      });
    } else {
      setValue(
        "teaching_licenses",
        teachingLicenses.filter((license: { id: number }) => license.id !== id),
        { shouldValidate: true, shouldDirty: true }
      );
    }
  };

  const handleSpecialSubjectChange = (id: number) => {
    const updatedLicenses = teachingLicenses.map((license: { id: any }) =>
      license.id === id
        ? { ...license, special_subject: specialSubjectValues[id - 1] }
        : license
    );
    setValue("teaching_licenses", updatedLicenses, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  // special_subjectの初期値を代入
  useEffect(() => {
    setSpecialSubjectValues(
      (prev) =>
        prev.map(
          (value, index) =>
            teachingLicenses.find(
              (license: { id: number }) => license.id === index + 1
            )?.special_subject
        ) || null
    );
  }, [teachingLicenses]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <FormLabel labelName="お持ちの教員免許（複数選択可）と専門科目" />
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        {licenses.map((license) => (
          <Grid
            key={license.id}
            item
            xs={12}
            sx={{
              padding: "1rem",
              border: `1px solid ${theme.palette.muted?.main}`,
              borderRadius: "4px"
            }}
          >
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={(e) => handleChange(license.id, e.target.checked)}
                    checked={teachingLicenses.some(
                      (l: { id: number }) => l.id === license.id
                    )}
                  />
                }
                label={license.label}
                labelPlacement="end"
              />
              {license.hasSpecialSubject && (
                <TextField
                  variant="outlined"
                  margin="dense"
                  placeholder={license.specialLabel}
                  value={specialSubjectValues[license.id - 1]}
                  disabled={
                    !teachingLicenses.some(
                      (l: { id: number }) => l.id === license.id
                    )
                  }
                  onChange={(e) =>
                    setSpecialSubjectValues((prev) =>
                      prev.map((value, index) =>
                        index === license.id - 1 ? e.target.value : value
                      )
                    )
                  }
                  onBlur={() => handleSpecialSubjectChange(license.id)}
                  sx={{
                    margin: ".5rem .5rem .5rem 28px"
                  }}
                />
              )}
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LicenseField;
