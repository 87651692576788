import React from "react";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import Document from "components/atoms/Document";
import FlowStepContents from "components/molecules/lessons/FlowStepContents";
import { Methods as UpdateLessonMethods } from "api/api/v1/lessons/_lessonId@string";
import { useAtomValue } from "jotai";
import MeetingDateField from "components/molecules/lessons/MeetingDateField";
import MeetingPlaceField from "components/molecules/lessons/MeetingPlaceField";
import { Box, Stack } from "@mui/material";
import theme from "themes/theme";
import LessonPlaceField from "components/molecules/lessons/LessonPlaceField";
import dayjs from "libs/dayjs";
import EditableText from "components/atoms/lessons/EditableText";
import { useHistory } from "react-router-dom";
import Link from "components/atoms/mui/Link";
import RatingShow from "components/molecules/lessons/RatingShow";
import { formatDateTimeStringWithDay } from "utils/date";
import {
  bankAccountAtom,
  detailsAtom,
  ratingsAtom,
  activeStepAtom
} from "../store";

// MEMO: activeStepNumはpropsで受け取り、値取得はuseEditPageApiで行う
type LessonFlowSectionProps = {
  updateLesson: (data: UpdateLessonMethods["patch"]["reqBody"]) => void;
};

const LessonFlowSection: React.FC<LessonFlowSectionProps> = (props) => {
  const { updateLesson } = props;
  const details = useAtomValue(detailsAtom);
  const bankAccount = useAtomValue(bankAccountAtom);
  const rating = useAtomValue(ratingsAtom);
  const activeStep = useAtomValue(activeStepAtom);

  const history = useHistory();

  const isAfterLesson = !!(
    details?.lesson_completed_at || dayjs().isAfter(details?.exact_start_at)
  );

  return (
    <section>
      <Typography
        variant="h2"
        sx={{
          padding: { xs: "0 1rem", sm: 0 }
        }}
      >
        授業実施までの流れ
      </Typography>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{
          padding: "1rem 0",
          "& .MuiStepConnector-root .MuiStepConnector-line": {
            borderWidth: { xs: 0 }
          },
          "& .MuiStepConnector-root:nth-child(1)": {
            display: "none"
          }
        }}
      >
        <FlowStepContents
          index={0}
          title="打ち合わせを行う"
          stepContents={[
            {
              name: "打ち合わせ日時",
              content: (
                <MeetingDateField
                  meeting_start_at={details?.meeting_start_at}
                  onSubmit={updateLesson}
                />
              )
            },
            {
              name: "打ち合わせ場所",
              content: (
                <MeetingPlaceField
                  is_meeting_online={details?.is_meeting_online}
                  meeting_url={details?.meeting_url}
                  meeting_place={details?.meeting_place}
                  onSubmit={updateLesson}
                />
              )
            },
            {
              name: "打ち合わせ資料",
              content: (
                <>
                  <Document
                    title="授業準備シート"
                    caption="いつでも編集できます"
                    path={`/teachers/mypage/requests/${details?.id}/agenda`}
                  />
                  <Typography mt={1}>
                    授業の時間配分の記入率{" "}
                    <strong>{details?.timeline_input_rate}%</strong>
                  </Typography>
                  <Box
                    sx={{
                      width: 240,
                      height: 6,
                      backgroundColor: theme.palette.sub.lighter,
                      position: "relative"
                    }}
                  >
                    <Box
                      sx={{
                        width: `${details?.timeline_input_rate}%`,
                        height: "100%",
                        backgroundColor: theme.palette.primary.main,
                        position: "absolute",
                        top: 0,
                        left: 0
                      }}
                    />
                  </Box>
                </>
              )
            }
          ]}
        />
        <FlowStepContents
          index={1}
          title="授業を実施する"
          stepContents={[
            {
              name: "授業日程",
              content: details?.exact_start_at
                ? formatDateTimeStringWithDay(details.exact_start_at)
                : "（未定）"
            },
            {
              name: "授業場所",
              content: (
                <LessonPlaceField
                  is_online={details?.is_online}
                  place={details?.place}
                  lesson_url={details?.lesson_url}
                  onSubmit={updateLesson}
                  disabled={isAfterLesson}
                />
              )
            }
          ]}
        />
        <FlowStepContents
          index={2}
          title="授業後の対応"
          stepContents={[
            {
              name: "謝礼振込先口座",
              content: (
                <EditableText
                  value={
                    // MEMO: APIでnullなりを返す方針もあり
                    bankAccount && bankAccount.financial_institution_name
                      ? `${bankAccount.financial_institution_name} ***${bankAccount.account_number_last_four}`
                      : "振込先が登録されていません"
                  }
                  onChange={() =>
                    history.push(
                      "/teachers/mypage/settings?accordion=bank-account"
                    )
                  }
                  strong
                />
              )
            },
            {
              name: "アンケート",
              content: (
                <Link href="https://forms.gle/Qqz2LsVsRgXCvmvFA" newTab>
                  https://forms.gle/Qqz2LsVsRgXCvmvFA
                </Link>
              )
            },
            {
              name: "学校からの評価",
              content: (
                <Stack gap="16px">
                  <RatingShow
                    rating={rating ? rating.lesson_rating / 10 : 0}
                    title="授業の満足度"
                    reason={
                      rating
                        ? rating.lesson_rating_reason
                        : "学校側が評価をすると表示されます"
                    }
                    icon="lesson"
                  />
                  <RatingShow
                    rating={rating ? rating.teacher_rating / 10 : 0}
                    title="講師の満足度"
                    reason={
                      rating
                        ? rating.teacher_rating_reason
                        : "学校側が評価をすると表示されます"
                    }
                    icon="teacher"
                  />
                </Stack>
              )
            }
          ]}
          hasLabel
          isLast
        />
      </Stepper>
    </section>
  );
};

export default LessonFlowSection;
