import Typography from "@mui/material/Typography";
import PublishIcon from "@mui/icons-material/Publish";
import { useDropzone } from "react-dropzone";

import { useFormContext, Controller } from "react-hook-form";

import type React from "react";
import { CommonDialogProps } from "types/commonDialogProps";
import CommonDialog from "components/molecules/CommonDialog";
import { Box, useTheme } from "@mui/material";

type FileUploadModalProps = CommonDialogProps & {
  onSubmit: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
};

const FileUploadModal: React.FC<FileUploadModalProps> = (props) => {
  const { isOpen, onClose, onSubmit } = props;
  const theme = useTheme();
  const { control, setValue } = useFormContext();

  const onDrop = async (files: File[]) => {
    setValue("file", files[0]);
    // ファイル名から拡張子を削除
    const fileName = files[0].name.split(".")[0];
    setValue("file_name", fileName);
    onClose();
    await onSubmit();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Controller
        control={control}
        name="file"
        render={({ field: { onChange } }) => (
          <Box
            {...getRootProps()}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              minHeight: "400px"
            }}
          >
            <input
              {...getInputProps()}
              onChange={(e) => onChange(e.target.files)}
            />

            <PublishIcon
              sx={{
                fontSize: "4.5rem",
                color: isDragActive ? theme.palette.primary.main : "inherit"
              }}
            />
            <Typography
              align="center"
              sx={{
                color: isDragActive ? theme.palette.primary.main : "inherit"
              }}
            >
              スライド・画像・動画など、
              <br />
              授業で利用する資料をドラッグ＆ドロップしてください。
            </Typography>
            <Typography align="center" variant="caption">
              もしくは、ここをクリックしてファイルを選択してください。
              <br />
              ※アップロードには時間がかかる場合があります。
              <br />
              ※アップロードできるファイルのサイズは最大100MBです。
            </Typography>
          </Box>
        )}
      />
    </CommonDialog>
  );
};

export default FileUploadModal;
