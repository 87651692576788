import * as yup from "yup";
import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";

export type postChatFormSchemaType = Methods["post"]["reqBody"];

// 許可されたファイルのMIMEタイプ
const FILE_EXTENSIONS = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/pdf",
  "video/mp4",
  "application/vnd.apple.keynote",
  "video/quicktime",
  "image/jpeg",
  "image/png"
];
// 最大ファイルサイズ（100MB）
const MAX_FILE_SIZE = 100 * 1024 * 1024;

const postChatFormSchema: yup.ObjectSchema<postChatFormSchemaType> = yup
  .object()
  .shape({
    comment: yup
      .string()
      .optional()
      .test("required", "メッセージを入力してください", (value, context) => {
        if (context.parent.file || value) return true;
        return false;
      }),
    file: yup
      .mixed<File>()
      .optional()
      .test("required", "ファイルを選択してください", (value, context) => {
        if (context.parent.comment || value) return true;
        return false;
      })
      .test(
        "check-file",
        "ファイル形式は次の形式のみアップロード可能です。.ppt, .pptx, .pdf, .mp4, .key, .mov, .jpg, .jpeg, .png",
        (value) => {
          if (!value) return true;
          if (
            value.type === "application/vnd.ms-powerpoint" ||
            value.type ===
              "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
            value.type === "application/pdf" ||
            value.type === "video/mp4" ||
            value.type === "application/vnd.apple.keynote" ||
            value.type === "video/quicktime" ||
            value.type === "image/jpeg" ||
            value.type === "image/png"
          ) {
            return true;
          }
          return false;
        }
      )
      .test(
        "check-size",
        "ファイルは100MB以下のみアップロード可能です。",
        (value) => {
          if (value) {
            return value.size <= 100000000;
          }
          return true;
        }
      ),
    is_office_notification: yup.boolean().optional()
  });

export default postChatFormSchema;
