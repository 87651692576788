import { atom } from "jotai";

import { Methods } from "api/api/v1/teachers/_teacherId@string/lessons";

export type kindOfTeacherLessonsType =
  | ""
  | "lesson_end"
  | "preparation"
  | "canceled";

export const teacherLessonsAtom = atom<Methods["get"]["resBody"] | null>(null);

export const kindOfTeacherLessonsAtom =
  atom<kindOfTeacherLessonsType>("preparation");
