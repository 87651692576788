import React from "react";
import {
  Select as MuiSelect,
  MenuItem,
  FormControl,
  FormHelperText,
  SelectProps as MuiSelectProps,
  Typography
} from "@mui/material";

type SelectProps = {
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  width?: string;
  options: {
    label: string | React.ReactNode;
    value: string | number | boolean;
  }[];
} & MuiSelectProps;

const Select: React.FC<SelectProps> = ({
  error,
  helperText,
  width = "350px",
  options,
  ...props
}) => (
  <FormControl
    fullWidth={props.fullWidth}
    error={error}
    sx={{ width }}
    size={props.size}
  >
    <MuiSelect {...props} sx={{ fontSize: "1rem" }}>
      {options.map((option, index) => (
        <MenuItem key={index} value={String(option.value)}>
          <Typography component="span">{option.label}</Typography>
        </MenuItem>
      ))}
    </MuiSelect>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);

export default Select;
