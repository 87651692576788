import { OpenInNew } from "@mui/icons-material";
import {
  LinkProps as MuiLinkProps,
  Link as MuiLink,
  Typography,
  useTheme
} from "@mui/material";

// TODO: アプリケーション全体のLinkを以下に置き換える

export interface LinkProps extends MuiLinkProps {
  newTab?: boolean;
}

const Link: React.FC<LinkProps> = ({ href, children, newTab = false }) => {
  const theme = useTheme();
  return (
    <MuiLink
      href={href}
      target={newTab ? "_blank" : "_self"}
      rel={newTab ? "noopener noreferrer" : undefined}
      sx={{
        display: "inline-flex",
        alignItems: "center",
        gap: "8px",
        width: "auto"
      }}
      color={theme.palette.link?.main}
    >
      <Typography color={theme.palette.link?.main} component="span">
        {children}
      </Typography>
      {newTab && <OpenInNew sx={{ height: "16px", width: "16px" }} />}
    </MuiLink>
  );
};

export default Link;
