import React from "react";
import { useAtomValue } from "jotai";

import dayjs from "libs/dayjs";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Button from "components/atoms/mui/Button";

import { updateLessonFormSchemaType } from "constants/UpdateLessonFormSchemaBase";
import { formatDateStringWithDay } from "utils/date";
import { detailsAtom } from "../store";

type LessonCompleteReportBlockProps = {
  updateLesson: (data: updateLessonFormSchemaType) => void;
};

const LessonCompleteReportBlock: React.FC<LessonCompleteReportBlockProps> = (
  props
) => {
  const { updateLesson } = props;
  const theme = useTheme();

  const details = useAtomValue(detailsAtom);
  // 授業開始時の一週間後を報告期限に設定
  const deadline = formatDateStringWithDay(
    dayjs(details?.exact_start_at).add(1, "week")
  );

  const handleLessonComplete = () => {
    updateLesson({
      lesson_completed_at: dayjs().format()
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%",
        boxSizing: "border-box"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Typography variant="h2">授業お疲れ様でした！</Typography>
          <Typography>
            下記ボタンを押して完了報告をお願いします。
            <br />
            授業完了報告をすることで報酬の支払いが行われます。
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "flex-end"
          }}
        >
          <Typography fontWeight="bold" fontSize="1rem" sx={{ width: "100%" }}>
            報告期限：{deadline}まで
          </Typography>
          <Box>
            <Button onClick={handleLessonComplete}>授業終了しました</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LessonCompleteReportBlock;
