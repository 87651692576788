import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import RatingDisplay from "features/teachers/id/atoms/RatingDisplay";

import type React from "react";

type TotalRatingType = {
  rating: number;
};

const TotalRating: React.FC<TotalRatingType> = (props) => {
  const { rating } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0 1rem"
      }}
    >
      <Typography fontSize="1.2rem" fontWeight="bold">
        総合
      </Typography>
      <RatingDisplay rating={rating / 10} size="1.5rem" />
      <Typography fontSize="1.5rem" fontWeight="bold">
        {rating ? (rating / 10).toFixed(1) : "-"}
      </Typography>
    </Box>
  );
};

export default TotalRating;
