import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import useTeacherRankBadge from "hooks/useTeacherRankBadge";

type RequestItemDataProps = {
  organizationName: string;
  teacherName: string;
  teacherImage: string;
  teacherRank: string;
};

const RequestItemData: React.FC<RequestItemDataProps> = (props) => {
  const { organizationName, teacherName, teacherImage, teacherRank } = props;
  const teacherRankBadge = useTeacherRankBadge(teacherRank);
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex" }}>
      <Avatar
        src={teacherImage}
        alt={teacherName}
        sx={{
          width: "3.5rem",
          height: "3.5rem",
          marginRight: "1rem"
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".25rem",
          justifyContent: "center"
        }}
      >
        <Typography fontSize=".8rem" color={theme.palette.sub.main}>
          {organizationName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography fontWeight="bold">{teacherName}</Typography>
          {teacherRankBadge.src && (
            <Box
              component="img"
              src={teacherRankBadge.src}
              alt={teacherRankBadge.alt}
              sx={{
                display: "block",
                margin: "0 .5rem",
                // MEMO: 解像度を合わせるための調整
                width: "85px"
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RequestItemData;
