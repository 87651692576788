import React from "react";
import {
  Radio as MuiRadio,
  FormControlLabelProps,
  FormControlLabel,
  useTheme
} from "@mui/material";

type RadioProps = {
  noDecoration?: boolean;
  fullWidth?: boolean;
  backgroundColor?: string;
} & Omit<FormControlLabelProps, "control">;

const Radio: React.FC<RadioProps> = (props) => {
  const theme = useTheme();
  const outlinedStyle = {
    padding: "12px 16px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.border?.main}`,
    backgroundColor: props.backgroundColor || "inherit",
    width: props.fullWidth ? "100%" : "auto",
    "&:hover": {
      borderColor: theme.palette.textColor.main
    },
    "&.Mui-disabled": {
      borderColor: theme.palette.border?.main
    }
  };
  return (
    <FormControlLabel
      {...props}
      sx={
        props.noDecoration
          ? {
              width: props.fullWidth ? "100%" : "auto",
              backgroundColor: props.backgroundColor || "inherit"
            }
          : outlinedStyle
      }
      control={<MuiRadio />}
    />
  );
};

export default Radio;
