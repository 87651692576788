import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const HowToWriteAccountHolder: React.FC = () => (
  <Typography variant="caption">
    通帳（Web口座の場合「口座情報」）を確認の上、正確に入力をお願いします。
    <br />
    個人の場合：姓と名の間には「スペース」の入力をお願いします。
    <br />
    【複業太郎】の場合 フクギョウ タロウ
    <br />
    法人の場合：以下を参考に法人格は略称で入力ください。代表者名は不要です。
    <br />
    【株式会社複業先生】の場合 カ）フクギョウセンセイ
    <br />
    【複業先生株式会社】の場合 フクギョウセンセイ（カ
    <br />
    【NPO法人複業先生】の場合 トクヒ）フクギョウセンセイ
    <br />
    <br />
    <Box
      component="table"
      sx={{
        border: "1px solid #ccc",
        borderCollapse: "collapse",
        "& th": {
          border: "1px solid #ccc",
          padding: ".1rem"
        },
        "& td": {
          border: "1px solid #ccc",
          padding: ".1rem"
        }
      }}
    >
      <thead>
        <tr>
          <th>用語</th>
          <th>略語（前）</th>
          <th>略語（後）</th>
          <th>用語</th>
          <th>略語（前）</th>
          <th>略語（後）</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>株式会社</td>
          <td>カ）</td>
          <td>（カ</td>
          <td>医療法人(医療法人社団/財団)</td>
          <td>イ）</td>
          <td>（イ</td>
        </tr>
        <tr>
          <td>有限会社</td>
          <td>合同会社</td>
          <td>ド）</td>
          <td>（ド</td>
          <td>社団法人(一般/公益社団法人)</td>
          <td>シャ）</td>
          <td>（シャ</td>
        </tr>
        <tr>
          <td>合名会社</td>
          <td>メ）</td>
          <td>（メ</td>
          <td>宗教法人</td>
          <td>シュウ）</td>
          <td>（シュウ</td>
        </tr>
        <tr>
          <td>合資会社</td>
          <td>シ）</td>
          <td>（シ</td>
          <td>学校法人</td>
          <td>ガク）</td>
          <td>（ガク</td>
        </tr>
        <tr>
          <td>特定非営利活動法人</td>
          <td>トクヒ）</td>
          <td>（トクヒ</td>
          <td>社会福祉法人</td>
          <td>フク）</td>
          <td>（フク</td>
        </tr>
      </tbody>
    </Box>
  </Typography>
);

export default HowToWriteAccountHolder;
