import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";

import { teacherVideosAtom } from "store/TeacherVideosStore";
import type React from "react";
import { Grid } from "@mui/material";
import VideoJS from "components/atoms/VideoJS";
import IntroVideoField from "../molecules/IntroVideoField";
import IntroVideoPreview from "../molecules/IntroVideoPreview";
import ProfileEditContentsAccordion from "./ProfileEditContentsAccordion";

import { postTeacherVideo } from "../types/teacherVideoApiTypes";
import SelfVideoField from "../molecules/SelfVideoFIeld";

type IntroVideosCardProps = {
  postTeacherVideo: postTeacherVideo;
  removeTeacherVideo: (videoId: number) => Promise<void>;
};

const IntroVideosCard: React.FC<IntroVideosCardProps> = (props) => {
  const { postTeacherVideo, removeTeacherVideo } = props;
  const teacherVideos = useAtomValue(teacherVideosAtom);

  const videoKeys = ["intro1", "intro2", "intro3"];
  const selfIntroVideo = teacherVideos.find(
    (video) => video.key === "self_introduction"
  );

  return (
    <ProfileEditContentsAccordion title="紹介動画" label="intro-videos">
      <Typography variant="caption">
        ※アップした動画は、審査完了後に公開されます。
        <br />
        自己紹介動画を1分くらいで撮影してみましょう。動画を撮影することで学校の先生から選ばれやすくなります。
        <br />
        内容についてはサンプル動画を参考に、「自己紹介」、「どんな授業ができるか」、「意気込み」などを話して下さい。
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          {selfIntroVideo ? (
            <IntroVideoPreview
              id={selfIntroVideo.id}
              url={selfIntroVideo.file.url}
              isPublic={selfIntroVideo.is_public}
              removeTeacherVideo={removeTeacherVideo}
            />
          ) : (
            <SelfVideoField postTeacherVideo={postTeacherVideo} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" align="center" mb={2}>
            サンプル動画
          </Typography>
          <VideoJS
            options={{
              controls: true,
              aspectRatio: "16:9",
              fluid: true,
              sources: [
                {
                  // サンプル動画のURL
                  src: "https://fukugyo-sensei-bucket.s3.ap-northeast-1.amazonaws.com/sample/self_sample_video.mp4",
                  type: "video/mp4"
                }
              ]
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="caption">
        自分で編集した動画などもアップロードすることが可能です
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        {videoKeys.map((key) => {
          const video = teacherVideos.find((video) => video.key === key);
          return (
            <Box
              sx={{
                width: { xs: "100%", md: "calc(100% / 3 - 1rem)" },
                flexGrow: 1
              }}
            >
              {video ? (
                <IntroVideoPreview
                  id={video.id}
                  url={video.file.url}
                  isPublic={video.is_public}
                  removeTeacherVideo={removeTeacherVideo}
                />
              ) : (
                <IntroVideoField
                  keyForApi={key}
                  postTeacherVideo={postTeacherVideo}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </ProfileEditContentsAccordion>
  );
};

export default IntroVideosCard;
