import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

import ContactBannerForPC from "assets/ContactBannerForPC.png";
import ContactBannerForSP from "assets/ContactBannerForSP.png";
import useScroll from "hooks/useScroll";

import type React from "react";

const ToSupportBannerBottom: React.FC = () => {
  // MEMO: 一回閉じたら開かないように
  const [canOpen, setCanOpen] = useState(true);
  const theme = useTheme();
  const inXs = useMediaQuery(theme.breakpoints.down("md"));
  const scrollY = useScroll();

  if (inXs) {
    return (
      <Fade in={canOpen && scrollY > 600}>
        <Box
          sx={{
            position: "fixed",
            bottom: 12,
            right: "5%"
          }}
        >
          <Box
            sx={{
              position: "relative"
            }}
          >
            <IconButton
              size="small"
              onClick={() => setCanOpen(false)}
              sx={{
                position: "absolute",
                top: 3,
                right: 3,
                color: theme.palette.common.white
              }}
            >
              <CloseIcon sx={{ color: theme.palette.sub.main }} />
            </IconButton>
            <a href="https://forms.gle/4QxSJSHgzE33SxLA6" target="blank">
              <Box
                component="img"
                src={ContactBannerForSP}
                alt="複業先生選びに迷っている方へ"
                sx={{
                  width: "240px",
                  margin: "0 auto"
                }}
              />
            </a>
          </Box>
        </Box>
      </Fade>
    );
  }

  return (
    <Fade in={canOpen && scrollY > 600}>
      <Box
        sx={{
          position: "fixed",
          bottom: 36,
          right: 36
        }}
      >
        <Box sx={{ position: "relative" }}>
          <IconButton
            size="small"
            onClick={() => setCanOpen(false)}
            sx={{
              position: "absolute",
              top: 3,
              right: 3,
              color: theme.palette.common.white
            }}
          >
            <CloseIcon sx={{ color: theme.palette.sub.main }} />
          </IconButton>
          <a href="https://forms.gle/4QxSJSHgzE33SxLA6" target="blank">
            <Box
              component="img"
              src={ContactBannerForPC}
              alt="複業先生選びに迷っている方へ"
              sx={{
                width: "210px"
              }}
            />
          </a>
        </Box>
      </Box>
    </Fade>
  );
};

export default ToSupportBannerBottom;
