import { Box, useMediaQuery } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";

import ModalTransition from "components/organisms/ModalTransition";

import type React from "react";
import CommonDialog from "components/molecules/CommonDialog";
import { CommonDialogProps } from "types/commonDialogProps";
import { noRatingLessonsAtom } from "../store";
import RatingAttentionBlock from "../molecules/RatingAttentionBlock";

const RatingsAttentionModal: React.FC<CommonDialogProps> = ({
  isOpen,
  onClose
}) => {
  const noRatingLessons = useAtomValue(noRatingLessonsAtom);

  const theme = useTheme();
  const inXs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <CommonDialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={ModalTransition}
      maxWidth="md"
      fullScreen={inXs}
    >
      <Box>
        <Typography
          align="center"
          fontWeight="bold"
          fontSize="1.1rem"
          sx={{
            margin: "1.5rem auto .75rem",
            lineHeight: 1.8
          }}
        >
          授業はどうでしたか？
          <br />
          評価をお願いします！
        </Typography>
        <Typography
          align="center"
          color={theme.palette.sub.main}
          sx={{
            margin: ".75rem auto 1rem"
          }}
        >
          まだ評価していない授業：{noRatingLessons?.length}件
        </Typography>
        <div>
          {noRatingLessons?.map((lesson) => (
            <RatingAttentionBlock
              key={lesson.details.id}
              id={lesson.details.id}
              lessonStartAt={lesson.details.exact_start_at}
              avatar={lesson.teacher.image.url}
              organization={lesson.teacher.organization_name}
              teacherName={lesson.teacher.name}
            />
          ))}
        </div>
      </Box>
    </CommonDialog>
  );
};

export default RatingsAttentionModal;
