import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { Link } from "react-router-dom";

import type React from "react";

type ImportantNoticeItemProps = {
  title: string;
  content: string;
  link?: string;
};

const ImportantNoticeItem: React.FC<ImportantNoticeItemProps> = (props) => {
  const theme = useTheme();
  const { title, content, link } = props;

  return (
    <Box
      sx={{
        background: "linear-gradient(to right, #FFEFC7, #FFDBAA)",
        padding: { xs: "0 1rem", sm: "0 1.5rem" }
      }}
    >
      <Box
        sx={{
          display: "flex",
          minHeight: "1rem",
          gap: { xs: 0, sm: ".5rem" },
          width: { xs: "100%", sm: "90%" },
          maxWidth: theme.breakpoints.values.lg,
          margin: ".5rem auto",
          flexDirection: { xs: "column", sm: "row" }
        }}
      >
        <Typography fontWeight="bold" fontSize=".8rem">
          {title}
        </Typography>
        {link ? (
          <Typography
            component={Link}
            to={link}
            sx={{
              color: theme.palette.link?.main,
              fontWeight: "bold",
              fontSize: ".8rem",
              textDecoration: "none"
            }}
          >
            {content}
          </Typography>
        ) : (
          <Typography fontSize=".8rem">{content}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default ImportantNoticeItem;
