import React, { useState } from "react";
import { useAtomValue } from "jotai";

import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import SectionLabel from "components/atoms/lessons/SectionLabel";

import convertToCircleNumber from "services/convertToCircleNumber";
import { Divider } from "@mui/material";
import FormSubmitButton from "components/molecules/FormSubmitButton";

import { activeStepAtom as activiStepForSchoolAtom } from "features/schools/mypage/lessons/id/store";
import { activeStepAtom as activeStepForTeacherAtom } from "features/teachers/mypage/lessons/id/store";

type StepContentProps = {
  name: string;
  content: React.ReactNode;
};

type FlowStepContentsProps = {
  hasLabel?: boolean;
  isRequired?: boolean;
  index: number;
  title: string;
  stepContents: Array<StepContentProps>;
  isLast?: boolean;
  submitText?: string;
  disabledText?: string;
};

const FlowStepContents: React.FC<FlowStepContentsProps> = (props) => {
  const {
    index,
    title,
    stepContents,
    hasLabel,
    isRequired,
    isLast,
    submitText,
    disabledText
  } = props;
  const theme = useTheme();
  const activeStepForTeacher = useAtomValue(activeStepForTeacherAtom);
  const activeStepForSchool = useAtomValue(activiStepForSchoolAtom);

  const activeStep = activeStepForTeacher || activeStepForSchool;
  const [isExpanded, setIsExpanded] = useState(activeStep === index + 1);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Step
      key={index}
      active
      sx={{
        display: "flex",
        position: "relative",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Divider
          orientation="vertical"
          sx={{
            height: "28px",
            borderWidth: index === 0 ? 0 : 2,
            borderColor:
              activeStep > index
                ? theme.palette.primary.main
                : theme.palette.sub.lighter
          }}
        />
        <Box
          sx={{
            width: 18,
            height: 18,
            borderRadius: "50%",
            border: `2px solid ${activeStep > index ? theme.palette.primary.main : theme.palette.sub.lighter}`,
            backgroundColor:
              activeStep > index
                ? theme.palette.primary.main
                : theme.palette.sub.lighter,
            flexShrink: 0
          }}
        />
        <Divider
          orientation="vertical"
          sx={{
            height: "100%",
            borderWidth: isLast ? 0 : 2,
            borderColor:
              activeStep > index + 1
                ? theme.palette.primary.main
                : theme.palette.sub.lighter
          }}
        />
      </Box>
      <StepContent
        sx={{
          marginLeft: { xs: 0, sm: "18px" },
          paddingLeft: 0,
          paddingRight: 0,
          borderLeft: 0,
          width: "100%"
        }}
      >
        <Accordion
          elevation={0}
          expanded={isExpanded}
          onChange={handleExpand}
          sx={{
            border: `1px solid ${theme.palette.sub.lighter}`,
            borderRadius: { xs: "0 !important", sm: "8px !important" },
            padding: "8px"
          }}
        >
          <AccordionSummary
            expandIcon={
              isExpanded ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".25rem",
                    flexShrink: 0
                  }}
                >
                  <Remove
                    sx={{
                      color: theme.palette.link?.main,
                      height: "1rem",
                      width: "1rem"
                    }}
                  />
                  <Typography
                    color={theme.palette.link?.main}
                    fontSize=".8rem"
                    sx={{
                      textDecoration: "underline"
                    }}
                  >
                    閉じる
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".25rem",
                    flexShrink: 0
                  }}
                >
                  <Add
                    sx={{
                      color: theme.palette.link?.main,
                      height: "1rem",
                      width: "1rem"
                    }}
                  />
                  <Typography
                    color={theme.palette.link?.main}
                    fontSize=".8rem"
                    sx={{
                      textDecoration: "underline"
                    }}
                  >
                    開く
                  </Typography>
                </Box>
              )
            }
            sx={{
              "& .MuiAccordionSummary-expandIcon": {
                transform: "none"
              },
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "none"
              },
              "& .MuiAccordionSummary-content": {
                margin: "20px 0"
              },
              zIndex: 10
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: ".25rem",
                width: "100%"
              }}
            >
              <Typography variant="h3">
                {convertToCircleNumber(index)}
                {title}
              </Typography>
              {hasLabel && <SectionLabel isRequired={isRequired} />}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {disabledText && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  zIndex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center"
                }}
              >
                <Typography sx={{ color: "text.primary", fontWeight: "bold" }}>
                  {disabledText}
                </Typography>
              </Box>
            )}

            <TableContainer
              component={Paper}
              elevation={0}
              sx={{
                border: `1px solid ${theme.palette.sub.lighter}`
              }}
            >
              <Table>
                <TableBody>
                  {stepContents.map((stepContent, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        display: { xs: "flex", sm: "table-row" },
                        flexDirection: { xs: "column", sm: "row" }
                      }}
                    >
                      <TableCell
                        component="th"
                        sx={{
                          width: { xs: "100%", sm: "200px" },
                          backgroundColor: theme.palette.backgroundColor?.main
                        }}
                      >
                        {stepContent.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: theme.spacing(2)
                        }}
                      >
                        {stepContent.content}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {submitText && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <FormSubmitButton label={submitText} />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </StepContent>
    </Step>
  );
};

export default FlowStepContents;
