import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import useQueryParams from "hooks/useQueryParams";
import { useAtom } from "jotai";
import aspida from "libs/aspida";
import { paginationData } from "store/MasterData";
import { commonRecruitmentsAtom } from "./store";

type QueryParamsType = {
  pages?: number;
};

const useIndexPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const { getQueryParams } = useQueryParams<QueryParamsType>();
  const [, setCommonRecruitments] = useAtom(commonRecruitmentsAtom);
  const [, setPagination] = useAtom(paginationData);

  const query = getQueryParams(["pages"]);

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.lesson_recruitments,
    {
      query,
      onSuccess: (response) => {
        setCommonRecruitments(response);
        setPagination(response.pagination || null);
      },
      onError: () => {
        addErrorMessage("募集情報の取得に失敗しました");
      }
    }
  );

  return { isLoading };
};

export default useIndexPageApi;
