import useAspidaSWR from "@aspida/swr";
import { yupResolver } from "@hookform/resolvers/yup";
import useHeaders from "hooks/useHeaders";
import { useAtom } from "jotai";
import aspida from "libs/aspida";
import dayjs from "libs/dayjs";
import { useForm } from "react-hook-form";
import { useAlertMessages } from "hooks/useAlertMessages";
import {
  schoolGradesAtom,
  stepAtom,
  tagsAtom,
  createdRecruitmentIdAtom
} from "./store";
import newRecruitmentFormSchema from "./constants/newRecruitementFormSchema";

const useNewRecruitmentPageApi = () => {
  const headers = useHeaders();
  const [, setSchoolGrades] = useAtom(schoolGradesAtom);
  const [, setTags] = useAtom(tagsAtom);
  const [, setStep] = useAtom(stepAtom);
  const [, setId] = useAtom(createdRecruitmentIdAtom);
  const { addErrorMessage } = useAlertMessages();

  const newRecruitmentForm = useForm({
    resolver: yupResolver(newRecruitmentFormSchema),
    defaultValues: {
      recruitment_dead_at: dayjs().add(1, "week").startOf("day").format(),
      proposed_dates: [
        dayjs().add(14, "day").set("hour", 12).set("minute", 0).format()
      ]
    },
    mode: "all",
    reValidateMode: "onChange"
  });

  useAspidaSWR(aspida(headers).api.v1.lesson_recruitments.new, {
    onSuccess: (data) => {
      setSchoolGrades(data.school_grades);
      setTags(data.tags);
    }
  });

  const createRecruitment = async () => {
    const data = newRecruitmentForm.getValues();
    try {
      const response = await aspida(headers).api.v1.lesson_recruitments.post({
        body: data
      });
      if (response.status === 200) {
        setId(response.body.details.id);
        setStep(3);
      }
    } catch (error) {
      addErrorMessage("募集の作成に失敗しました。入力内容を確認してください。");
    }
  };

  return { newRecruitmentForm, createRecruitment };
};

export default useNewRecruitmentPageApi;
