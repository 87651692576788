import React from "react";
import { useAtomValue } from "jotai";
import { useWatch } from "react-hook-form";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import FormTextField from "components/molecules/FormTextField";
import FormSelect from "components/molecules/FormSelect";
import FormLabel from "components/atoms/FormLabel";

import { CommonDialogProps } from "types/commonDialogProps";
import {
  schoolDivisionsData,
  schoolTypesData,
  prefecturesData
} from "../store";

const ExpectionSchoolFields: React.FC<CommonDialogProps> = (props) => {
  const schoolDivisions = useAtomValue(schoolDivisionsData);
  const schoolTypes = useAtomValue(schoolTypesData);
  const prefectures = useAtomValue(prefecturesData);

  // FIXME: この辺りの処理はもっとスマートに書けるはず。MasterSchoolField_v2とも部分的に被っている。
  const schoolName = useWatch({
    name: "school_name"
  });
  const schoolDivisionId = useWatch({
    name: "school_division_id"
  });
  const schoolTypeId = useWatch({
    name: "school_type_id"
  });
  const prefectureId = useWatch({
    name: "school_prefecture_id"
  });
  const schoolAddress = useWatch({
    name: "school_address"
  });

  const schoolDivisionsOptions = schoolDivisions.map((schoolDivision) => ({
    value: schoolDivision.id,
    label: schoolDivision.name
  }));

  const schoolTypesOptions = schoolTypes.map((schoolType) => ({
    value: schoolType.id,
    label: schoolType.name
  }));

  const prefecturesOptions = prefectures.map((prefecture) => ({
    value: prefecture.id,
    label: prefecture.name
  }));

  const isClickable = () => {
    if (
      schoolName &&
      schoolDivisionId &&
      schoolTypeId &&
      prefectureId &&
      schoolAddress
    ) {
      return true;
    }
    return false;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography align="center" fontSize="1.2rem" fontWeight="bold">
        学校検索
      </Typography>
      <FormTextField
        name="school_name"
        label="学校名"
        placeholder="例：都立〇〇高等学校"
        fullWidth
        isRequired
      />
      <FormSelect
        name="school_type_id"
        label="運営主体（公立・私立・国立・付属）"
        options={schoolTypesOptions}
        fullWidth
        isRequired
      />
      <FormSelect
        name="school_division_id"
        label="学校区分（小・中・高・大・高専）"
        options={schoolDivisionsOptions}
        fullWidth
        isRequired
      />
      <Box>
        <FormLabel labelName="学校住所" isRequired />
        <FormSelect
          name="school_prefecture_id"
          label=""
          options={prefecturesOptions}
          fullWidth
          isRequired
          noLabel
        />
        <FormTextField
          name="school_address"
          label=""
          placeholder="例：〇〇市〇〇町3丁目2-1"
          fullWidth
          isRequired
          noLabel
        />
      </Box>
      <Button
        sx={{
          fontWeight: "bold"
        }}
        disabled={!isClickable()}
        onClick={props.onClose}
      >
        入力完了
      </Button>
    </Box>
  );
};

export default ExpectionSchoolFields;
