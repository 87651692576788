import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import type React from "react";
import { teacherLessonRequestsAtom } from "../store";

import PastClassCard from "./PastClassCard";

const PastClassesBox: React.FC = () => {
  const teacherLessonRequests = useAtomValue(teacherLessonRequestsAtom);

  return (
    <div>
      <Typography variant="h4" fontSize="1rem" sx={{ margin: "0 auto .5rem" }}>
        過去の授業
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "0 1rem",
          overflowX: "auto",
          whiteSpace: "nowrap"
        }}
      >
        {teacherLessonRequests.map((teacherLessonRequest, index) => (
          <PastClassCard
            key={index}
            school_grade={teacherLessonRequest.school_grade}
            theme={teacherLessonRequest.theme}
            timelines={teacherLessonRequest.timelines}
          />
        ))}
      </Box>
    </div>
  );
};

export default PastClassesBox;
