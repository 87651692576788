import { useAtomValue } from "jotai";
import React from "react";

import CommonLoading from "components/atoms/CommonLoading";
import { useTheme, Box, Stack, Typography, Divider } from "@mui/material";
import { schoolRecruitmentsAtom } from "../store";
import RecruitmentListItem from "../molecules/RecruitmentListItem";

interface RecruitmentListProps {
  isLoading: boolean;
}

const RecruitmentList: React.FC<RecruitmentListProps> = ({ isLoading }) => {
  const theme = useTheme();
  const schoolRecruitments = useAtomValue(schoolRecruitmentsAtom);

  return (
    <Box component="section" sx={{ width: "100%" }}>
      <Stack
        spacing={2}
        sx={{
          padding: "1rem",
          backgroundColor: theme.palette.common.white,
          width: "100%"
        }}
      >
        {/* タイトルヘッダー */}
        <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
          <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
            <Typography fontWeight="bold" sx={{ flex: 13 }}>
              募集タイトル
            </Typography>
            <Typography flex={4} align="center" fontWeight="bold">
              ステータス
            </Typography>
            <Typography flex={3} align="center" fontWeight="bold">
              応募人数
            </Typography>
            <Typography flex={3} align="center" fontWeight="bold">
              募集期限
            </Typography>
          </Stack>
          <Divider sx={{ width: "100%" }} />
        </Box>

        {/* ローディング中 */}
        {isLoading ? (
          <CommonLoading />
        ) : schoolRecruitments && schoolRecruitments.data.length ? (
          // 募集リスト
          schoolRecruitments.data.map((schoolRecruitment, index) => (
            <React.Fragment key={schoolRecruitment.details.id}>
              <RecruitmentListItem
                id={schoolRecruitment.details.id}
                title={schoolRecruitment.details.title}
                deadline={schoolRecruitment.details.recruitment_dead_at}
                status={schoolRecruitment.details.step}
                candidatesCount={schoolRecruitment.candidates_count}
              />
              {index < schoolRecruitments.data.length - 1 && (
                <Divider sx={{ width: "100%" }} />
              )}
            </React.Fragment>
          ))
        ) : (
          // 募集がない場合の表示
          <Typography color={theme.palette.sub.main}>
            募集がありません。
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default RecruitmentList;
