import { ConfigType } from "dayjs";
import dayjs from "libs/dayjs";

// 時間関連のユーテリティ関数
export const tommorowNoon = () =>
  dayjs().add(1, "day").startOf("day").add(12, "hour");

// 2024年9月1日
export const formatDateString = (date: ConfigType) => {
  const parsedDate = dayjs(date);
  if (!parsedDate.isValid()) {
    return ""; // 無効な日付の場合は空文字を返す
  }
  return parsedDate.format("YYYY年M月D日");
};

// 2024年9月1日 12時00分
export const formatDateTimeString = (date: ConfigType) => {
  const parsedDate = dayjs(date);
  if (!parsedDate.isValid()) {
    return ""; // 無効な日付の場合は空文字を返す
  }
  return parsedDate.format("YYYY年M月D日 HH時mm分");
};

// 2024年9月1日（火）
export const formatDateStringWithDay = (date: ConfigType) => {
  const parsedDate = dayjs(date);
  if (!parsedDate.isValid()) {
    return ""; // 無効な日付の場合は空文字を返す
  }
  return parsedDate.locale("ja").format("YYYY年M月D日（ddd）");
};

// 2024年9月1日（火）12時00分
export const formatDateTimeStringWithDay = (date: ConfigType) => {
  const parsedDate = dayjs(date);
  if (!parsedDate.isValid()) {
    return ""; // 無効な日付の場合は空文字を返す
  }
  return parsedDate.locale("ja").format("YYYY年M月D日（ddd）HH時mm分");
};

// 2024/09/01
export const formatDate = (date: ConfigType) => {
  const parsedDate = dayjs(date);
  if (!parsedDate.isValid()) {
    return ""; // 無効な日付の場合は空文字を返す
  }
  return parsedDate.format("YYYY/MM/DD");
};

// 2024/09/01 12:00
export const formatDateTime = (date: ConfigType) => {
  const parsedDate = dayjs(date);
  if (!parsedDate.isValid()) {
    return ""; // 無効な日付の場合は空文字を返す
  }
  return parsedDate.format("YYYY/MM/DD HH:mm");
};

// 返答期限を計算する関数
export const checkDeadline = (acceptDeadAt: ConfigType) => {
  const today = dayjs().startOf("day");
  const deadline = dayjs(acceptDeadAt).startOf("day");

  // 今日と受け取り期限の差を計算
  const limit = deadline.diff(today, "day") + 1;

  // 期限が過ぎているかどうか
  const dead = limit < 0;

  return { limit, dead };
};
