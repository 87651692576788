import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Footer from "components/organisms/Footer";
import Header from "components/organisms/Header";
import HeaderSp from "components/organisms/HeaderSp";
import SpDrawer from "components/organisms/SpDrawer";
import { currentUserAtom, isLoggedInAtom } from "store/AuthStore";

import type { Theme } from "@mui/material";
import AlertMessages from "components/molecules/AlertMessages";
import Loading from "components/atoms/Loading";
import ImportantNoticeItem from "components/molecules/ImportantNoticeItem";
import { currentSchoolAtom, schoolIsLoggedInAtom } from "store/SchoolAuthStore";

interface CommonLayoutProps {
  isLoading?: boolean;
  children: React.ReactNode;
}

const CommonLayout: React.FC<CommonLayoutProps> = ({ isLoading, children }) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const currentUser = useAtomValue(currentUserAtom);
  const isSchoolLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const currentSchool = useAtomValue(currentSchoolAtom);

  // Mouseflowにログインユーザー情報を送信
  useEffect(() => {
    if (isSchoolLoggedIn) {
      window._mfq = window._mfq || [];
      window._mfq.push([
        "setVariable",
        "schoolTeacherId",
        currentSchool.id.toString()
      ]);
    } else if (isLoggedIn) {
      window._mfq = window._mfq || [];
      window._mfq.push(["setVariable", "teacherId", currentUser.id.toString()]);
    }
  }, [isSchoolLoggedIn, isLoggedIn, currentUser, currentSchool]);

  return (
    <Box
      id="layout-root"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div>
        {isMobile ? (
          <HeaderSp isOpen={drawerIsOpen} setIsOpen={setDrawerIsOpen} />
        ) : (
          <Header />
        )}
      </div>
      {location.pathname !== "/" && <Box sx={theme.mixins.toolbar} />}
      {location.pathname !== "/" &&
        isLoggedIn &&
        !currentUser.is_identification && (
          <ImportantNoticeItem
            title="本人確認のお願い"
            content="授業の実施前にこちらから本人登録をお願いします"
            link="/teachers/mypage/settings?accordion=identification-check"
          />
        )}
      {/* MEMO: 削除中系のアラートを追加 */}
      {((location.pathname !== "/" &&
        isLoggedIn &&
        currentUser.is_deletion_request_in_progress) ||
        (isSchoolLoggedIn &&
          currentSchool.is_deletion_request_in_progress)) && (
        <ImportantNoticeItem
          title="退会申請中"
          content="退会申請を受け付けました。退会手続きが完了するまでお待ちください。"
        />
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column"
        }}
      >
        {isLoading ? <Loading headerOnly /> : children}
      </Box>
      <Footer />
      {isMobile && (
        <SpDrawer isOpen={drawerIsOpen} setIsOpen={setDrawerIsOpen} />
      )}
      <AlertMessages />
    </Box>
  );
};

export default CommonLayout;
