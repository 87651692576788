import { atom } from "jotai";

import { Methods as NoRatingLessonsMethods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lessons/no_rating";
import { Methods as GetLessonMethods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lessons";
import { Methods as GetRecruitmentMethods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lesson_recruitments";
import { Methods as GetRequestMethods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lesson_requests";

// TODO: 他の一覧系もstore + aspidaの形に変更していく
export const noRatingLessonsAtom = atom<
  NoRatingLessonsMethods["get"]["resBody"]["data"] | undefined
>(undefined);

export const schoolRequestsAtom = atom<
  GetRequestMethods["get"]["resBody"] | null
>(null);

export const schoolLessonsAtom = atom<
  GetLessonMethods["get"]["resBody"] | null
>(null);

export const schoolRecruitmentsAtom = atom<
  GetRecruitmentMethods["get"]["resBody"] | null
>(null);
