import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type React from "react";
import CommonDialog from "components/molecules/CommonDialog";
import { CommonDialogProps } from "types/commonDialogProps";
import Button from "components/atoms/mui/Button";

type DeleteCheckModalProps = {
  messageId: number;
  deleteRequestComments?: (commentId: string) => void;
} & CommonDialogProps;

const DeleteCheckModal: React.VFC<DeleteCheckModalProps> = (props) => {
  const { isOpen, onClose, messageId, deleteRequestComments } = props;

  const handleDelete = () => {
    if (deleteRequestComments !== undefined) {
      deleteRequestComments(messageId.toString());
      onClose();
    }
  };

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Stack gap="1rem" alignItems="center">
        <Typography variant="h6">
          メッセージを削除しても よろしいですか？
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem"
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            キャンセル
          </Button>
          <Button variant="contained" color="primary" onClick={handleDelete}>
            削除
          </Button>
        </Box>
      </Stack>
    </CommonDialog>
  );
};

export default DeleteCheckModal;
