import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import FirstViewImages from "../molecules/FirstViewImages";
import FirstViewImagesSP from "../molecules/FirstViewImagesSP";
import FirstViewLabels from "../molecules/FirstViewLabels";

const FirstView: React.FC = () => {
  const theme = useTheme();
  const underSm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box component="section" sx={{ backgroundColor: "#FFF6DE" }}>
      {underSm && <Box sx={theme.mixins.toolbar} />}
      <Box
        sx={{
          margin: "0 auto",
          width: { xs: "auto", md: "90%", lg: "100%" },
          display: { xs: "block", md: "flex" },
          alignItems: "center",
          justifyContent: "space-around"
        }}
      >
        <Box sx={{ paddingTop: { xs: 0, sm: "2rem" } }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "2rem", lg: "2.4rem" },
              textAlign: { xs: "center", md: "left" },
              margin: { xs: "1rem auto", sm: 0 },
              lineHeight: "2 !important"
            }}
          >
            外部人材を活用した
            <br />
            授業をもっと気軽に。
          </Typography>
          <div>
            <FirstViewLabels />
            <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                size="large"
                href="https://docs.google.com/forms/d/e/1FAIpQLSeNq4_e6RM47SrgHKQzlIqlG11678zPcrUceuuuCPwHqb2huQ/viewform"
                sx={{
                  height: "64px",
                  padding: "0 2rem",
                  fontSize: "1.1rem",
                  fontWeight: 600,
                  margin: "1rem auto",
                  textAlign: "center"
                }}
              >
                授業を依頼したい方はこちら
              </Button>
            </Box>
          </div>
        </Box>
        {underSm ? <FirstViewImagesSP /> : <FirstViewImages />}
      </Box>
    </Box>
  );
};

export default FirstView;
