import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";

import useHeaders from "hooks/useHeaders";

import { useAlertMessages } from "hooks/useAlertMessages";
import useCurrentTeacher from "hooks/useCurrentTeacher";
import useCurrentSchool from "hooks/useCurrentSchool";
import { Methods } from "api/api/v1/account_deletion_requests";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";
import { leavingReasonsAtom } from "./store";
import { accountDeletionRequestFormSchema } from "./constants/accountDeletionRequestFormSchema";

const useAccountDeletionApi = () => {
  const headers = useHeaders();
  const history = useHistory();
  const [, setLeavingReason] = useAtom(leavingReasonsAtom);
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  const [schoolIsLoggedIn] = useAtom(schoolIsLoggedInAtom);
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const { mutate: mutateTeacher } = useCurrentTeacher();
  const { mutate: mutateSchool } = useCurrentSchool();

  const methods = useForm<Methods["post"]["reqBody"]>({
    resolver: yupResolver(accountDeletionRequestFormSchema),
    mode: "all",
    defaultValues: {
      feedback: "",
      leaving_reasons: []
    }
  });

  useAspidaSWR(aspida(headers).api.v1.account_deletion_requests.new, {
    onSuccess: (data) => {
      setLeavingReason(data);
    },
    onError: () => {
      addErrorMessage("アカウント削除の取得に失敗しました");
    }
  });

  const requestAccountDeletion = async () => {
    try {
      const params = methods.getValues();
      const response = await aspida(
        headers
      ).api.v1.account_deletion_requests.post({
        body: params
      });
      if (response.status === 200) {
        if (isLoggedIn) {
          mutateTeacher();
          history.push("/teachers/mypage/account-deletion-request-complete");
        }
        if (schoolIsLoggedIn) {
          mutateSchool();
          history.push("/schools/mypage/account-deletion-request-complete");
        }
      } else {
        addErrorMessage("アカウント削除のリクエストに失敗しました");
      }
    } catch (e) {
      addErrorMessage("アカウント削除のリクエストに失敗しました");
    }
  };

  return { requestAccountDeletion, methods };
};

export default useAccountDeletionApi;
