import { atom } from "jotai";

import { Methods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lesson_requests";

export type kindOfSchoolRequestsType =
  | "requesting"
  | "only_request"
  | "denied_or_canceled"
  | "accepted";

export const schoolRequestsAtom = atom<Methods["get"]["resBody"] | null>(null);

export const kindOfSchoolRequestsAtom =
  atom<kindOfSchoolRequestsType>("only_request");
