import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import Meta from "components/organisms/Meta";
import ELearningLayout from "components/layouts/ELearningLayout";
import type React from "react";
import { FormProvider } from "react-hook-form";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import Part1 from "./organisms/Part1";
import Part2 from "./organisms/Part2";
import Part3 from "./organisms/Part3";
import Part4 from "./organisms/Part4";
import Video from "./organisms/Video";
import useELearningPageApi from "./useApi";

import SendAnswersLeading from "./atoms/SendAnswersLeading";

const New: React.FC = () => {
  const theme = useTheme();
  const { postElerningForm, postELearning } = useELearningPageApi();

  return (
    <ELearningLayout>
      <Meta
        title="複業先生｜e-learning"
        description="学校での授業に向けて、必要なこと・大切なことを動画で学びましょう。"
      />
      <FormProvider {...postElerningForm}>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            padding: { xs: "0", md: "inherit" }
          }}
        >
          <Typography sx={{ padding: { xs: "0 1.5rem", md: 0 } }}>
            以下の動画を見て、穴埋め問題に解答してください。
          </Typography>
          <Box
            component="form"
            sx={{
              backgroundColor: theme.palette.common.white,
              borderRadius: "4px",
              border: `1px solid ${theme.palette.border?.main}`,
              padding: "1.5rem",
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem"
            }}
            onSubmit={postElerningForm.handleSubmit(postELearning)}
          >
            <Video />
            <Part1 />
            <Part2 />
            <Part3 />
            <Part4 />
            <SendAnswersLeading />
            <FormSubmitButton label="解答する" fullWidth />
          </Box>
        </Container>
      </FormProvider>
    </ELearningLayout>
  );
};

export default New;
