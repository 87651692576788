import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { useAtomValue } from "jotai";
import React from "react";

import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import RecruitmentItem from "features/teachers/mypage/index/molecules/RecruitmentItem";
import CommonLoading from "components/atoms/CommonLoading";
import NoContentItem from "features/teachers/mypage/index/molecules/NoContentItem";

import RecruitmentIcon from "assets/common/sidebar/unselected/teachers/recruitment.svg";
import dayjs from "libs/dayjs";
import { teacherRecruitmentsAtom } from "../store";

interface HomeRecruitmentsProps {
  isLoading: boolean;
}

const HomeRecruitments: React.FC<HomeRecruitmentsProps> = ({ isLoading }) => {
  const theme = useTheme();
  const teacherRecruitments = useAtomValue(teacherRecruitmentsAtom);

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        width: "100%",
        height: "100%"
      }}
    >
      <CommonMypageHomeSectionTitle
        Icon={<img src={RecruitmentIcon} alt="応募済み" />}
        title="応募済み"
        count={teacherRecruitments?.data_count || 0}
        linkName="すべての応募を見る"
        link="/teachers/mypage/recruitments"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
          backgroundColor: theme.palette.common.white,
          width: "100%",
          height: "100%"
        }}
      >
        {!isLoading ? (
          teacherRecruitments && teacherRecruitments.data.length > 0 ? (
            teacherRecruitments.data.map((teacherRecruitment, index) => (
              <React.Fragment key={index}>
                <RecruitmentItem
                  id={teacherRecruitment.details.id}
                  title={teacherRecruitment.details.title}
                  deadLine={dayjs(
                    teacherRecruitment.details.recruitment_dead_at
                  )}
                  status={teacherRecruitment.details.step}
                  candidateStatus={teacherRecruitment.candidate.status || ""}
                />
                <Divider sx={{ width: "100%" }} />
              </React.Fragment>
            ))
          ) : (
            <NoContentItem
              title="応募済みの授業はありません"
              lead="興味のある授業に応募してみましょう"
              link="/recruitments"
              buttonText="授業の募集を探す"
            />
          )
        ) : (
          <CommonLoading />
        )}
      </Box>
    </Box>
  );
};

export default HomeRecruitments;
