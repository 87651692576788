import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { Methods } from "api/api/v1/school_teachers/login_school_teacher";

type currentSchoolAtomProps = Methods["get"]["resBody"] & {
  school_teacher_profile_id: number;
};

const currentSchoolAtom = atomWithStorage<currentSchoolAtomProps>(
  "currentSchool",
  {
    allow_password_change: false,
    deleted_at: "",
    email: "",
    id: 0,
    is_public: false,
    is_term_accepted: false,
    provider: "",
    school_teacher_profile_id: 0,
    uid: "",
    user_id: 0,
    school_name: "",
    name: "",
    image: { url: "" },
    user_type: "",
    is_match_accepted: false,
    is_deletion_request_in_progress: false
  },
  undefined,
  {
    getOnInit: true
  }
);
const schoolIsLoggedInAtom = atomWithStorage(
  "schoolIsLoggedIn",
  false,
  undefined,
  {
    getOnInit: true
  }
);
const schoolAccessTokenAtom = atomWithStorage(
  "schoolAccessToken",
  "",
  undefined,
  {
    getOnInit: true
  }
);
const schoolClientAtom = atomWithStorage("schoolClient", "", undefined, {
  getOnInit: true
});
const schoolUidAtom = atomWithStorage("schoolUid", "", undefined, {
  getOnInit: true
});
const schoolTokenTypeAtom = atomWithStorage("schoolTokenType", "", undefined, {
  getOnInit: true
});

const loginAgainModalAtom = atom(false);

const prevLoginPathAtom = atom("");

const registeredEmailAtom = atom("");

export {
  currentSchoolAtom,
  schoolIsLoggedInAtom,
  schoolAccessTokenAtom,
  schoolClientAtom,
  schoolUidAtom,
  schoolTokenTypeAtom,
  loginAgainModalAtom,
  prevLoginPathAtom,
  registeredEmailAtom
};
