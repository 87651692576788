import * as yup from "yup";

import { futureDateStringSchema } from "utils/yupUtils";

import {
  updateLessonFormSchemaBase,
  updateLessonFormSchemaType
} from "constants/UpdateLessonFormSchemaBase";

export const patchMeetingDatimesFormSchema: yup.ObjectSchema<updateLessonFormSchemaType> =
  updateLessonFormSchemaBase.concat(
    yup.object().shape({
      meeting_start_at:
        futureDateStringSchema.required("打ち合わせ日時を選択してください"),
      is_meeting_online: yup.boolean().required("実施方法を選択してください")
    })
  );
