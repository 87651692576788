export const afterQuestionnairesProductionFormKeys = {
  formUrl:
    "https://docs.google.com/forms/u/0/d/e/1FAIpQLSewTm4MjQ8mjOYgDPdb_ZcFHykJlbrnzSQLpi12UNB0m93Kzw/formResponse",
  lessonRequestIdKeys: "entry.464159861",
  schoolPrefectureNameKeys: "entry.699512014",
  schoolNameKeys: "entry.1960217994",
  // 以下、入力値のキー
  schoolClassKeys: "entry.835137462",
  studentIDNumberKeys: "entry.1892306227",
  learnWantedToKnowKeys: "entry.465579464",
  isUsefulKeys: "entry.1590982158",
  wantedToKnowMoreKeys: "entry.2133585917",
  wantedToTellKeys: "entry.1764615136"
};

export const afterQuestionnairesDevelopmentFormKeys = {
  formUrl:
    "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfXL-I7EuMTJPLWKmrb7w8aSKG9Ik-VDTAd1SgA56Azkeym1Q/formResponse",
  lessonRequestIdKeys: "entry.464159861",
  schoolPrefectureNameKeys: "entry.699512014",
  schoolNameKeys: "entry.1960217994",
  // 以下、入力値のキー
  schoolClassKeys: "entry.835137462",
  studentIDNumberKeys: "entry.1892306227",
  learnWantedToKnowKeys: "entry.465579464",
  isUsefulKeys: "entry.1590982158",
  wantedToKnowMoreKeys: "entry.2133585917",
  wantedToTellKeys: "entry.1764615136"
};
