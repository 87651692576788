import Button from "@mui/material/Button";
import Alert from "@mui/lab/Alert";
import { Link } from "react-router-dom";

import type React from "react";

const IdentificationAlert: React.FC = () => (
  <Alert
    severity="error"
    sx={{
      borderRadius: 4,
      display: "flex",
      alignItems: "center"
    }}
    action={
      <Button
        variant="text"
        color="secondary"
        size="small"
        component={Link}
        to="/teachers/mypage/settings?accordion=identification-check"
        sx={{ fontWeight: "bold", padding: ".5rem" }}
      >
        本人確認へ
      </Button>
    }
  >
    授業依頼を承諾するには本人確認が必要になります。
  </Alert>
);

export default IdentificationAlert;
