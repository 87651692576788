import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import AlertMessages from "components/molecules/AlertMessages";

import Header from "../organisms/QuestionnariesHeader";

type QuestionnairesLayoutProps = {
  title: string;
  children: any;
};

const QuestionnairesLayout: React.FC<QuestionnairesLayoutProps> = (props) => {
  const { title, children } = props;
  const theme = useTheme();

  return (
    <div id="layout-root">
      <Header title={title} />
      <Box sx={theme.mixins.toolbar} />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          padding: "1.5rem 0",
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {children}
      </Box>
      <AlertMessages />
    </div>
  );
};

export default QuestionnairesLayout;
