import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { useAtom } from "jotai";
import { useLocation, useHistory, Link } from "react-router-dom";

import HeaderSchoolContents from "components/molecules/HeaderSchoolContents";
import HeaderTeacherContents from "components/molecules/HeaderTeacherContents";
import { currentUserAtom, isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom, currentSchoolAtom } from "store/SchoolAuthStore";

import type { Theme } from "@mui/material";
import type React from "react";
import Button from "components/atoms/mui/Button";
import HeaderLogo from "components/atoms/HeaderLogo";
import useTeacherCommonData from "hooks/useTeacherCommonData";
import useSchoolTeacherCommonData from "hooks/useSchoolTeacherCommonData";
import { teacherCommonDataAtom } from "store/TeacherCommonDataStore";
import HeaderNotices from "components/molecules/HeaderNotices";

const Header: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [currentUser] = useAtom(currentUserAtom);
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  const [schoolIsLoggedIn] = useAtom(schoolIsLoggedInAtom);
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [teacherCommonData] = useAtom(teacherCommonDataAtom);
  const theme = useTheme();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const underSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  useTeacherCommonData();
  useSchoolTeacherCommonData();

  const handleToSignup = () => {
    history.push("/signup");
  };

  const handleToLogin = () => {
    history.push("/teachers/login");
  };

  const handleToSchoolLp = () => {
    history.push("/lp/school");
  };

  const handleToHome = () => {
    history.push("/");
  };

  const handleToSchoolLogin = () => {
    history.push("/schools/login");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        color={location.pathname === "/" ? "transparent" : "inherit"}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <Box
            component={Link}
            to="/"
            sx={{
              display: "flex",
              textDecoration: "none",
              color: theme.palette.textColor.main
            }}
          >
            <HeaderLogo />
          </Box>
          {!isXs && location.pathname !== "/lp/school" && (
            <Box
              component={Link}
              to="/teachers"
              sx={{
                display: "flex",
                textDecoration: "none",
                color: theme.palette.textColor.main,
                margin: "0 1rem",
                alignItems: "center",
                "&:hover": {
                  color: theme.palette.primary.main,
                  borderBottom: `solid 1px ${theme.palette.primary.main}`
                }
              }}
            >
              <SearchIcon
                sx={{
                  display: "block",
                  margin: ".2rem"
                }}
              />
              <Typography color="inherit">複業先生を探す</Typography>
            </Box>
          )}
          {!isXs && location.pathname !== "/lp/school" && !schoolIsLoggedIn && (
            <Box
              component={Link}
              to="/recruitments"
              sx={{
                display: "flex",
                textDecoration: "none",
                color: theme.palette.textColor.main,
                margin: "0 1rem",
                alignItems: "center",
                "&:hover": {
                  color: theme.palette.primary.main,
                  borderBottom: `solid 1px ${theme.palette.primary.main}`
                }
              }}
            >
              <SearchIcon
                sx={{
                  display: "block",
                  margin: ".2rem"
                }}
              />
              <Typography color="inherit">
                授業の募集を探す
                {teacherCommonData?.lesson_recruitments_counts
                  ? `（${teacherCommonData?.lesson_recruitments_counts}件）`
                  : null}
              </Typography>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {!isLoggedIn && !schoolIsLoggedIn && (
            <Stack direction="row" gap={1}>
              {!underSm && location.pathname !== "/lp/school" && (
                <Button isLink onClick={handleToSchoolLp}>
                  学校や塾の担当者の方はこちら
                </Button>
              )}
              {!underSm && location.pathname === "/lp/school" && (
                <Button isLink onClick={handleToHome}>
                  学校で授業したい方はこちら
                </Button>
              )}
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                sx={{ margin: `0 ${theme.spacing(1)}` }}
                size="small"
                onClick={
                  location.pathname !== "/lp/school"
                    ? handleToLogin
                    : handleToSchoolLogin
                }
              >
                ログイン
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                size="small"
                sx={{ margin: `0 ${theme.spacing(1)}` }}
                onClick={handleToSignup}
              >
                新規登録
              </Button>
            </Stack>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginLeft: "auto"
            }}
          >
            {(isLoggedIn || schoolIsLoggedIn) && <HeaderNotices />}
            {isLoggedIn && currentUser.image && <HeaderTeacherContents />}
            {schoolIsLoggedIn && currentSchool.image && (
              <HeaderSchoolContents />
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
