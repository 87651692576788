import React, { useState } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import SearchIcon from "@mui/icons-material/Search";

import CommonOrganismPagination from "components/organisms/CommonOrganismPagination";

import { masterSchoolType } from "types/masterDataTypes";
import { CommonDialogProps } from "types/commonDialogProps";
import CommonDialog from "components/molecules/CommonDialog";
import { useMasterSchoolsApi } from "hooks/useMasterSchoolsApi";
import theme from "themes/theme";

interface SelectSchoolModalProps extends CommonDialogProps {
  handleSelect: (value: masterSchoolType) => void;
  schoolDivision: "high_school" | "university";
}

const SelectSchoolModal: React.FC<SelectSchoolModalProps> = (props) => {
  const { isOpen, onClose, handleSelect, schoolDivision } = props;
  const [searchWord, setSearchWord] = useState("");

  const getModalInfo = () => {
    switch (schoolDivision) {
      case "high_school":
        return {
          modalTitle: "出身高校検索",
          schoolDivisionIds: [3, 5]
        };
      case "university":
        return {
          modalTitle: "出身大学検索",
          schoolDivisionIds: [4, 6]
        };
      default:
        return {
          modalTitle: "学校検索",
          schoolDivisionIds: [3, 4, 5, 6]
        };
    }
  };

  const { masterSchools, pagination, setPagination, isLoading, search } =
    useMasterSchoolsApi();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
  };

  const handleSearch = () => {
    search({
      search_school_name: searchWord,
      school_division_ids: getModalInfo().schoolDivisionIds,
      pages: 1
    });
  };

  const handleChangePage = (n: number) => {
    search({
      pages: n
    });
  };

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%"
        }}
      >
        <Typography align="center" fontSize="1.2rem" fontWeight="bold">
          {getModalInfo().modalTitle}
        </Typography>
        <Box
          component="form"
          sx={{
            display: "flex",
            gap: "1rem",
            flex: 1
          }}
        >
          <TextField
            id="school-search-word"
            variant="outlined"
            value={searchWord}
            onChange={handleChange}
            placeholder="学校名を入力してください"
            fullWidth
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            disableElevation
            disabled={isLoading || !searchWord}
            startIcon={<SearchIcon />}
            type="submit"
            sx={{
              width: "8rem",
              padding: { xs: "4px 8px", sm: "12px, 24px" }
            }}
          >
            検索
          </Button>
        </Box>
        {masterSchools && masterSchools.length > 0 && (
          <List>
            {masterSchools?.map((masterSchool) => (
              <ListItem
                key={masterSchool.school_code}
                onClick={() => handleSelect(masterSchool)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: ".5rem",
                  borderBottom: theme.palette.divider
                }}
              >
                <Typography>{masterSchool.name}</Typography>
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={() => handleSelect(masterSchool)}
                  sx={{
                    fontWeight: "bold",
                    padding: { xs: "4px 8px", sm: "12px, 24px" }
                  }}
                >
                  選択
                </Button>
              </ListItem>
            ))}
          </List>
        )}
        <CommonOrganismPagination
          pagination={pagination}
          setPagination={setPagination}
          setPage={handleChangePage}
        />
      </Box>
    </CommonDialog>
  );
};

export default SelectSchoolModal;
