import type React from "react";
import FormTextField from "components/molecules/FormTextField";
import FormDatePicker from "components/molecules/FormDatePicker";
import dayjs from "libs/dayjs";
import FieldsFrame from "./FieldsFrame";

const AboutFields: React.FC = () => (
  <FieldsFrame title="募集について" info="応募締切日は1週間先から設定できます">
    <FormDatePicker
      name="recruitment_dead_at"
      label="応募締切日"
      isRequired
      minDate={dayjs().add(1, "week").startOf("day")}
    />
    <FormTextField
      name="title"
      label="募集のタイトル（40文字以内）"
      placeholder="例：〇〇〇について、〇〇〇〇〇〇な授業ができる方募集！"
      isRequired
    />
  </FieldsFrame>
);

export default AboutFields;
