import { useAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";

import {
  editAuthAtom,
  lessonContentCategoriesAtom,
  requestObjectAtom,
  updateDateDisplayAtom
} from "features/requests/meetingSheet/store";
// MEMO: 認証情報のStore
import useAspidaSWR from "@aspida/swr";
import aspida from "libs/aspida";
import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";
import { useFieldArray, useForm } from "react-hook-form";
import type { Methods as PutAgendaSheetsMethods } from "api/api/v1/lessons/_lessonId/agenda_sheets/_agendaSheetId@string";
import type { Methods as PutTimelineMethods } from "api/api/v1/lessons/_lessonId@string/timelines/multi_update";
import dayjs from "libs/dayjs";

// 授業準備シートとタイムラインのPUTはエンドポイントが分かれているが、フォームは単一なので、これらをまとめて扱う
export type ReqBodyType = PutAgendaSheetsMethods["put"]["reqBody"] &
  PutTimelineMethods["patch"]["reqBody"];

const useMeetingSheet = (id: string) => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const form = useForm<ReqBodyType>({
    mode: "all"
  });
  const { control } = form;
  const {
    append,
    remove,
    move: moveTimeline,
    fields: timelines
  } = useFieldArray({
    control,
    name: "timelines",
    // https://stackoverflow.com/questions/77289582/react-hook-form-usefieldarray-overwriting-my-own-id
    keyName: "_id"
  });
  const [agendaSheetId, setAgendaSheetId] = useState<number>(0);
  const [generatingTeachingPlan, setGeneratingTeachingPlan] = useState(false);
  const [, setLessonContentCategories] = useAtom(lessonContentCategoriesAtom);
  const [, setUpdateDate] = useAtom(updateDateDisplayAtom);
  const [requestObject, setRequestObject] = useAtom(requestObjectAtom);
  const [editAuth, setEditAuth] = useAtom(editAuthAtom);

  const postDefaultTimeline = async () => {
    try {
      const response = await aspida(headers)
        .api.v1.lessons._lessonId_string(id)
        .timelines.create_default_data.post();
      return response.body;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const putMeetingSheet = useCallback(async () => {
    const formValue = form.getValues();
    const agendaSheetFormValue =
      formValue as PutAgendaSheetsMethods["put"]["reqBody"];
    const timelineFormValue =
      formValue as PutTimelineMethods["patch"]["reqBody"];

    try {
      const response = await aspida(headers)
        .api.v1.lessons._lessonId(id)
        .agenda_sheets._agendaSheetId(agendaSheetId.toString())
        .put({
          body: agendaSheetFormValue
        });
      if (response.status === 200) {
        setUpdateDate(dayjs().format("HH:mm"));
        await aspida(headers)
          .api.v1.lessons._lessonId_string(id)
          .timelines.multi_update.patch({
            body: timelineFormValue
          });
      }
    } catch (error) {
      addErrorMessage("データの保存に失敗しました");
    }
  }, [form, headers, id, agendaSheetId]);

  const addTimeline = useCallback(async () => {
    try {
      const response = await aspida(headers)
        .api.v1.lessons._lessonId_string(id)
        .timelines.post();
      if (response.status === 200) {
        append(response.body);
      }
    } catch (error) {
      addErrorMessage("データの追加に失敗しました");
    }
  }, [headers, id]);

  const removeTimeline = useCallback(
    async (timelineId: number) => {
      try {
        const response = await aspida(headers)
          .api.v1.lessons._lessonId_string(id)
          .timelines._timelineId(timelineId.toString())
          .delete();
        if (response.status === 200) {
          const index = timelines.findIndex(
            (timeline) => timeline.id === timelineId
          );
          remove(index);
        }
      } catch (error) {
        addErrorMessage("データの削除に失敗しました");
      }
    },
    [headers, id]
  );

  const generateTeachingPlan = useCallback(async () => {
    try {
      setGeneratingTeachingPlan(true);
      const response = await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId_string(id)
        .generate_teaching_plan.post();
      if (response.status === 200 && requestObject) {
        setRequestObject({
          ...requestObject,
          teaching_plan: response.body.details.teaching_plan
        });
      }
    } catch (error) {
      addErrorMessage("指導案の生成に失敗しました");
    } finally {
      setGeneratingTeachingPlan(false);
    }
  }, [headers, id, requestObject]);

  const { mutate: getMeetingSheet, isLoading: getIsLoading } = useAspidaSWR(
    aspida(headers).api.v1.lessons._lessonId(id).agenda_sheets.new,
    {
      onSuccess: async (response) => {
        if (!editAuth?.has_auth) {
          const now = dayjs().format("HH:mm");
          setUpdateDate(now);
        }
        setRequestObject(response.lesson_request.details);
        setLessonContentCategories(
          response.timelines.lesson_content_categories.data || []
        );
        if (response.edit_auth) {
          setEditAuth(response.edit_auth);
        }

        if (
          response.timelines.details.data &&
          response.timelines.details.data.length > 0
        ) {
          form.reset({
            ...response.details,
            timelines: response.timelines.details.data
          });
          await Promise.resolve(); // form.resetの非同期完了を保証
        } else {
          const postResponse = await postDefaultTimeline();
          if (postResponse) {
            form.reset({
              ...response.details,
              timelines: postResponse.data
            });
            await Promise.resolve(); // form.resetの非同期完了を保証
          }
        }
        setAgendaSheetId(response.details.id);
      },
      onError: () => {
        addErrorMessage("データの取得に失敗しました");
      },
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false
    }
  );

  // 明示的に1回だけ実行したいので、useEffectを使う (revalidateOnMountの挙動が怪しいため)
  useEffect(() => {
    getMeetingSheet();
  }, [getMeetingSheet]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!editAuth?.is_after_lesson) {
        if (editAuth?.has_auth) {
          putMeetingSheet();
        } else {
          getMeetingSheet();
        }
      }
    }, 60000); // 60000ミリ秒ごとに実行

    return () => {
      clearInterval(intervalId);
    }; // コンポーネントのアンマウント時にインターバルをクリア
  }, [editAuth?.has_auth, agendaSheetId]);

  return {
    getMeetingSheet,
    putMeetingSheet,
    getIsLoading,
    form,
    addTimeline,
    removeTimeline,
    moveTimeline,
    timelines,
    generateTeachingPlan,
    generatingTeachingPlan
  };
};

export default useMeetingSheet;
