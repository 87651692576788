import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import useTeacherRankBadge from "hooks/useTeacherRankBadge";

import type React from "react";

type teacherImageType = {
  teacherName?: string;
  teacherImageUrl?: string;
  teacherRank?: string;
};

// MEMO: indexでも利用の可能性があるため、propsで渡す形に
const TeacherImageBox: React.FC<teacherImageType> = (props) => {
  const { teacherName, teacherImageUrl, teacherRank } = props;
  const theme = useTheme();
  const teacherRankBadge = useTeacherRankBadge(teacherRank || "");

  return (
    <Box
      sx={{
        position: "relative",
        paddingBottom: "1rem",
        width: "132px",
        height: "132px",
        textAlign: "center"
      }}
    >
      <Box
        component="img"
        alt={teacherName}
        src={teacherImageUrl}
        sx={{
          width: "120px",
          height: "120px",
          padding: "5px",
          borderRadius: "50%",
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor:
            teacherRank === "ジュニア"
              ? "#B15C25"
              : teacherRank === "ミドル"
                ? theme.palette.sub.main
                : teacherRank === "プロ"
                  ? "#B69201"
                  : ""
        }}
      />
      <Box
        component="img"
        alt={teacherRankBadge.alt}
        src={teacherRankBadge.src}
        sx={{
          position: "absolute",
          bottom: ".25rem",
          left: "calc(50% - 42.5px)",
          width: "85px"
        }}
      />
    </Box>
  );
};

export default TeacherImageBox;
