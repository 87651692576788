import { atom } from "jotai";

import { Methods } from "api/api/v1/teachers/index";

type TeacherListType = Methods["get"]["resBody"]["data"];
type keywordsType = Methods["get"]["resBody"]["keywords"];
type suggestKeywordsType = Methods["get"]["resBody"]["suggest_keywords"];

export type SortType = "recent" | "total_rating" | "lesson_counts";

export const teachersDataAtom = atom<TeacherListType>([]);
export const keywordsAtom = atom<keywordsType>(undefined);
export const suggestKeywordsAtom = atom<suggestKeywordsType>([]);
