import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";
import React from "react";
import dayjs from "libs/dayjs";

import CommonLoading from "components/atoms/CommonLoading";
import LessonItem from "../molecules/LessonListItem";
import { schoolLessonsAtom } from "../store";

interface LessonListsProps {
  isLoading: boolean;
}

const LessonLists: React.FC<LessonListsProps> = ({ isLoading }) => {
  const theme = useTheme();
  const schoolLessons = useAtomValue(schoolLessonsAtom);

  return (
    <Box
      component="section"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
          backgroundColor: theme.palette.common.white,
          width: "100%"
        }}
      >
        <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Typography fontWeight="bold" sx={{ flex: 1 }}>
              授業
            </Typography>
            <Typography fontWeight="bold">ステータス</Typography>
          </Box>
          <Divider sx={{ width: "100%" }} />
        </Box>
        {!isLoading ? (
          schoolLessons ? (
            schoolLessons.data.map((lesson, index) => (
              <React.Fragment key={index}>
                <LessonItem
                  id={lesson.details.id}
                  teacherName={lesson.teacher.name}
                  teacherImage={lesson.teacher.image.url}
                  teacherRank={lesson.teacher.teacher_rank}
                  exactStartAt={dayjs(lesson.details.exact_start_at)}
                  schoolMinutes={lesson.details.school_minutes}
                  step={lesson.details.step}
                />
                <Divider sx={{ width: "100%" }} />
              </React.Fragment>
            ))
          ) : (
            <Typography color={theme.palette.sub.main}>
              授業がありません
            </Typography>
          )
        ) : (
          <CommonLoading />
        )}
      </Box>
    </Box>
  );
};

export default LessonLists;
