import RecruitmentCancelLabel from "components/atoms/recruitmentLabels/RecruitmentCancelLabel";
import RecruitmentCandidateSelectLabel from "components/atoms/recruitmentLabels/RecruitmentCandidateSelectLabel";
import RecruitmentFinishLabel from "components/atoms/recruitmentLabels/RecruitmentFinishLabel";
import RecruitmentRecruitingLabel from "components/atoms/recruitmentLabels/RecruitmentRecruitingLabel";

const getStatusLabel = (status: string) => {
  switch (status) {
    case "candidate_select":
      return <RecruitmentCandidateSelectLabel />;
    case "now_recruting":
      return <RecruitmentRecruitingLabel />;
    case "canceled":
    case "no_candidate":
    case "early_closed":
    case "closed":
      return <RecruitmentCancelLabel />;
    case "early_accepted":
    case "accepted":
      return <RecruitmentFinishLabel />;
    default:
      return null;
  }
};

export default getStatusLabel;
