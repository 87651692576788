import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import { useDialog } from "hooks/useDialog";
import useHeaders from "hooks/useHeaders";
import { useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import { AnnouncementType } from "types/announcementTypes";

const useAnnouncement = () => {
  const headers = useHeaders();
  const currentSchool = useAtomValue(currentSchoolAtom);
  const id = currentSchool.id.toString();

  const { addErrorMessage } = useAlertMessages();

  const announcementDialog = useDialog();

  const { data, mutate } = useAspidaSWR(
    aspida(headers).api.v1.school_teachers._schoolTeacherId_string(id)
      .school_announcements,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      onSuccess: (response) => {
        if (response.data.length > 0) {
          announcementDialog.onOpen();
        } else {
          announcementDialog.onClose();
        }
      }
      // お知らせの受信エラーはユーザーに通知してもアクションを取れないため、エラーハンドリングは行わない
    }
  );

  const confirmAnnouncement = async (announcementId: number) => {
    try {
      await aspida(headers)
        .api.v1.school_teachers._schoolTeacherId_number(Number(id))
        .school_announcements._schoolTeacherAnnouncementId(announcementId)
        .announcement_check.post({});
    } catch (error) {
      addErrorMessage("お知らせの確認に失敗しました");
    } finally {
      mutate();
    }
  };

  const announcements: AnnouncementType[] = data?.data || [];

  return {
    announcements,
    announcementDialogOpen: announcementDialog.isOpen,
    confirmAnnouncement
  };
};

export default useAnnouncement;
