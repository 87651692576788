import React, { useEffect, useRef } from "react";
import { useAtomValue } from "jotai";
import { useHistory } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import ForumIcon from "@mui/icons-material/Forum";

import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";
import { teacherCommonDataAtom } from "store/TeacherCommonDataStore";
import { schoolTeacherCommonDataAtom } from "store/SchoolTeacherCommonDataStore";

const HeaderNotices: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const teacherCommonData = useAtomValue(teacherCommonDataAtom);
  const schoolTeacherCommonData = useAtomValue(schoolTeacherCommonDataAtom);

  const chatNotices =
    teacherCommonData?.chat_notices || schoolTeacherCommonData?.chat_notices;

  const [open, setOpen] = React.useState(false);
  const prevOpen = useRef(open);
  const anchorRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToUnreadMessage = (lessonId: number) => {
    if (isLoggedIn)
      history.push(`/teachers/mypage/lessons/${lessonId}?tab_name=chat`);
    if (schoolIsLoggedIn)
      history.push(`/schools/mypage/lessons/${lessonId}?tab_name=chat`);
  };

  return (
    <>
      <Box
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
      >
        <IconButton onClick={handleToggle} size={isMobile ? "small" : "medium"}>
          <Badge
            badgeContent={chatNotices?.length}
            color="primary"
            sx={
              isMobile
                ? {
                    "& .MuiBadge-badge": {
                      fontSize: 9,
                      height: 15,
                      minWidth: 15
                    }
                  }
                : undefined
            }
          >
            <ForumIcon fontSize={isMobile ? "small" : "medium"} />
          </Badge>
        </IconButton>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper
              sx={{
                width: 270
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {!chatNotices?.length ? (
                    <Box>
                      <MenuItem>
                        <ListItemText>
                          新着メッセージはありません。
                        </ListItemText>
                      </MenuItem>
                    </Box>
                  ) : (
                    chatNotices?.map((chatNotice, index) => {
                      // MEMO: 送信者が複数いる場合は、最初の2人の名前、画像をかぶりなく表示する
                      const sendUserNotice = `${
                        chatNotice.send_users &&
                        chatNotice.send_users.length > 0
                          ? chatNotice.send_users
                              .map((sendUser) => sendUser.name)
                              .slice(0, 2)
                              .join("さん、") +
                            (chatNotice.send_users.length > 2 ? " 他" : "")
                          : ""
                      }さんから${chatNotice.unread_messages_count}件のメッセージが届いています。`;

                      return (
                        <MenuItem
                          key={index}
                          onClick={() =>
                            handleToUnreadMessage(chatNotice.lesson_id)
                          }
                        >
                          <ListItemIcon>
                            <AvatarGroup
                              max={2}
                              spacing={24}
                              sx={{
                                minWidth: 48,
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              {chatNotice.send_users?.map(
                                (send_user, index) => (
                                  <Avatar
                                    key={index}
                                    alt={send_user.name}
                                    src={send_user.image.url}
                                    sx={{
                                      width: 30,
                                      height: 30
                                    }}
                                  />
                                )
                              )}
                            </AvatarGroup>
                          </ListItemIcon>
                          <ListItemText
                            sx={{
                              textWrap: "wrap"
                            }}
                          >
                            {sendUserNotice}
                          </ListItemText>
                        </MenuItem>
                      );
                    })
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default HeaderNotices;
