import React from "react";
import { Link } from "react-router-dom";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import RecruitmentIcon from "assets/schools/mypage/RecruitmentIcon.png";

const RecruitmentLeading: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "center",
        padding: "1.5rem",
        backgroundColor: theme.palette.primary.lighter,
        borderRadius: "8px",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          width: "100%"
        }}
      >
        <img src={RecruitmentIcon} alt="募集を出す" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
        >
          <Typography variant="h3">複業先生を募集してみませんか？</Typography>
          <Typography>
            授業したい複業先生が見つけられないときは、授業募集を出してみましょう！
            <br />
            作成した募集内容は、複業先生に公開され、応募があるとあなたに通知が届きます。
          </Typography>
        </Box>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to="/recruitments/new"
        sx={{
          border: `2px solid ${theme.palette.primary.dark}`,
          color: theme.palette.primary.dark,
          fontWeight: "bold",
          backgroundColor: theme.palette.common.white,
          width: "100%",
          borderRadius: "8px",
          height: "3rem",
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white
          }
        }}
      >
        募集内容を作成
      </Button>
    </Box>
  );
};

export default RecruitmentLeading;
