import { Box, Typography, useTheme } from "@mui/material";

import RequestInfoBlock from "components/molecules/RequestInfoBlock";

import { useDialog } from "hooks/useDialog";

import type React from "react";
import RequestCancelAlert from "../molecules/RequestCancelAlert";
import RequestGrayButton from "../atoms/RequestGrayButton";

import { requestCancelDataType } from "../useApi";

type WatingForRequestJudgementProps = {
  requestCancel: (data: requestCancelDataType) => void;
};

const WatingForRequestJudgement: React.FC<WatingForRequestJudgementProps> = (
  props
) => {
  const { requestCancel } = props;
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDialog();

  return (
    <div>
      <Typography align="center" sx={{ margin: "0 auto 1rem" }}>
        <Box component="span" sx={{ display: "inline-block" }}>
          複業先生に授業を依頼しました。
        </Box>
        <Box component="span" sx={{ display: "inline-block" }}>
          複業先生の回答をお待ちください。
        </Box>
      </Typography>
      <Box
        sx={{
          borderRadius: 4,
          border: `solid 1px ${theme.palette.border?.main}`,
          overflow: "hidden",
          margin: { xs: "1rem auto", sm: "1.5rem auto" },
          padding: { xs: "1rem", sm: "1.5rem" }
        }}
      >
        <RequestInfoBlock />
      </Box>
      <RequestGrayButton name="依頼をキャンセルしたい" handleClick={onOpen} />
      <RequestCancelAlert
        isOpen={isOpen}
        onClose={onClose}
        requestCancel={requestCancel}
      />
    </div>
  );
};

export default WatingForRequestJudgement;
