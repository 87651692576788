import * as React from "react";
import { TextField } from "@mui/material";
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import dayjs from "libs/dayjs";

export interface DatePickerProps {
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  onChange: (date: Dayjs | null) => void;
  value: Dayjs | null;
  width?: string | number;
  minDate?: Dayjs;
  allowedDates?: Dayjs[];
}

const DatePicker: React.FC<DatePickerProps> = ({
  error,
  helperText,
  disabled,
  onChange,
  value,
  width = "350px",
  minDate,
  allowedDates
}) => {
  const shouldDisableDate = (date: Dayjs) => {
    if (!allowedDates) return false;
    // dateがallowedDatesに含まれているかを確認
    return !allowedDates?.some(
      (allowedDate) =>
        date.year() === allowedDate.year() &&
        date.month() === allowedDate.month() &&
        date.date() === allowedDate.date()
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <MuiDatePicker
        value={dayjs(value)}
        onChange={onChange}
        disabled={disabled}
        minDate={minDate}
        shouldDisableDate={allowedDates ? shouldDisableDate : undefined}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            disabled={disabled}
            inputProps={{
              ...params.inputProps,
              placeholder: "年/月/日"
            }}
            sx={{ width }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
