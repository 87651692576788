import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";

import type React from "react";
import { teacherInfoAtom } from "../store";

const BasicProfileBox: React.FC = () => {
  const teacherInfo = useAtomValue(teacherInfoAtom);

  return (
    <div>
      <Typography variant="h4" sx={{ margin: ".5rem auto 1rem" }}>
        {teacherInfo?.enthusiasm}
      </Typography>
      <Typography sx={{ whiteSpace: "pre-wrap" }}>
        {teacherInfo?.introduction}
      </Typography>
    </div>
  );
};

export default BasicProfileBox;
