import React, { useState } from "react";
import { IconButton, Popover, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Help, InfoOutlined } from "@mui/icons-material";

type IconPopoverProps = {
  variant: "info" | "question";
  text: string;
};

const IconPopover: React.FC<IconPopoverProps> = ({ variant, text }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    // スマホの場合、2秒後にポップオーバーを閉じる
    setTimeout(() => {
      if (!isMobile) return;
      setAnchorEl(null);
    }, 2000);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const iconProps = {
    sx: {
      width: "1rem",
      height: "1rem"
    }
  };

  return (
    <>
      <IconButton
        onMouseEnter={!isMobile ? handlePopoverOpen : undefined}
        onMouseLeave={!isMobile ? handlePopoverClose : undefined}
        onClick={isMobile ? handlePopoverOpen : undefined}
        size="small"
        sx={{
          padding: 0,
          width: "fit-content",
          height: "fit-content"
        }}
      >
        {variant === "info" ? (
          <InfoOutlined {...iconProps} />
        ) : (
          <Help {...iconProps} />
        )}
      </IconButton>
      <Popover
        sx={{
          pointerEvents: "none"
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{text}</Typography>
      </Popover>
    </>
  );
};

export default IconPopover;
