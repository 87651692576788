import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Meta from "components/organisms/Meta";
import Sidebar from "components/organisms/SchoolSidebar";
import TitleBlock from "features/schools/mypage/index/organisms/TitleBlock";
import CommonLayout from "components/layouts/CommonLayout";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import ChipGroup from "components/molecules/ChipGroup";
import CommonPagePagination from "components/organisms/CommonPagePagination";
import useMyRequestsPageApi from "./useApi";
import { kindOfSchoolRequestsType } from "./store";
import RequestList from "./organisms/RequestList";

const MyRecruitments: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { isLoading, kindOfSchoolRequests, setKindOfSchoolRequests } =
    useMyRequestsPageApi();

  const RequestSelectLabelOptions: {
    label: string;
    value: kindOfSchoolRequestsType;
  }[] = [
    { label: "すべて", value: "only_request" },
    { label: "承諾", value: "accepted" },
    { label: "依頼中", value: "requesting" },
    { label: "辞退・キャンセル", value: "denied_or_canceled" }
  ];

  return (
    <CommonLayout>
      <Meta title="複業先生｜募集状況" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { sx: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem"
            }}
          >
            <TitleBlock
              title="直接依頼状況"
              description="複業先生に、直接授業依頼を送ることができます。"
            />
            <ChipGroup
              options={RequestSelectLabelOptions}
              selectedValue={kindOfSchoolRequests}
              onSelect={setKindOfSchoolRequests}
            />
            <RequestList isLoading={isLoading} />
            <CommonPagePagination />
          </Box>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default MyRecruitments;
