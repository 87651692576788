import { useEffect, useMemo } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";
import { useFormContext } from "react-hook-form";
import dayjs from "libs/dayjs";

import MustSupportBox from "features/requests/new/molecules/MustSupportBox";

import {
  teacherProfileAtom,
  schoolGradesAtom,
  lessonOptionsAtom
} from "features/requests/new/store";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import type React from "react";
import FormTextArea from "components/molecules/FormTextArea";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormDatePicker from "components/molecules/FormDatePicker";
import FormChipGroup from "components/molecules/FormChipGroup";
import FormSelect from "components/molecules/FormSelect";
import FormCheckboxGroup from "components/molecules/FormCheckboxGroup";
import FormTextField from "components/molecules/FormTextField";

import { NewFormSchemaType } from "features/requests/new/constants/newFormSchema";
import theme from "themes/theme";

import { isFalse } from "utils/checkBool";
import { isOnlineOptions, schoolMinutesOptions } from "constants/selectOptions";
import { getTotalAmount } from "utils/calcPrice";

const RequestForm: React.FC = () => {
  const { watch, setValue } = useFormContext<NewFormSchemaType>();
  const isOnline = watch("is_online");
  // MEMO: amountのためだけにこれを利用するのは微妙な気がする
  const amount = watch("amount");
  const selectedOptionIds = watch("lesson_option_ids");
  const schoolMinutes = watch("school_minutes");
  const currentSchool = useAtomValue(currentSchoolAtom);
  const teacherProfile = useAtomValue(teacherProfileAtom);
  const schoolGrades = useAtomValue(schoolGradesAtom);
  const lessonOptions = useAtomValue(lessonOptionsAtom);
  const disabledValues = useMemo(
    () => (selectedOptionIds?.includes(4) ? [] : [5]),
    [selectedOptionIds]
  );

  const themeOptions = teacherProfile?.tags.map((tag) => ({
    value: tag.id.toString(),
    label: tag.name
  }));

  const lessonOptionsAdjusted = lessonOptions.map((lessonOption) => ({
    label: (
      <>
        <Box fontWeight="bold">
          {lessonOption.name}: {lessonOption.amount.toString()}円
        </Box>
        <Box>{lessonOption.description}</Box>
        {lessonOption.id === 5 && (
          <Box sx={{ fontSize: ".7rem", color: theme.palette.sub.main }}>
            ※こちらを選択するには「授業づくりの伴走」の選択が必要です
          </Box>
        )}
      </>
    ),
    value: lessonOption.id
  }));

  const schoolGradeOptions = schoolGrades.map((schoolGrade) => ({
    value: schoolGrade.id.toString(),
    label: schoolGrade.name
  }));

  const caricuratedAmount = getTotalAmount(
    schoolMinutes,
    selectedOptionIds || [],
    lessonOptions
  );

  if (amount !== caricuratedAmount) {
    setValue("amount", caricuratedAmount);
  }

  const oneWeekLater = dayjs().add(7, "day");

  // MEMO: 伴走の選択が外れたら、当日サポートの選択も外れる
  useEffect(() => {
    if (!selectedOptionIds?.includes(4) && selectedOptionIds?.includes(5)) {
      setValue(
        "lesson_option_ids",
        selectedOptionIds.filter((id: number) => id !== 5)
      );
    }
  }, [selectedOptionIds]);

  return (
    <section>
      <Card elevation={0}>
        <CardContent
          sx={{
            padding: "18px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            "&:last-child": {
              paddingBottom: "18px"
            }
          }}
        >
          {!currentSchool.is_match_accepted && <MustSupportBox />}
          <Typography fontWeight="bold">必要事項をご入力ください。</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}
          >
            <FormSelect
              label="授業を受ける学年"
              name="school_grade_id"
              isRequired
              options={schoolGradeOptions}
              fullWidth
            />
            <FormTextField
              label="生徒数（半角数字）"
              name="student_number"
              isRequired
              placeholder="例：30"
            />
            <FormRadioGroup
              name="school_minutes"
              label="授業時間"
              options={schoolMinutesOptions}
              isRequired
            />
            <FormRadioGroup
              name="is_online"
              label="実施方法"
              options={isOnlineOptions}
              isRequired
            />
            {isFalse(isOnline) && (
              <FormTextField
                label="交通費"
                name="travel_cost"
                isRequired
                placeholder="例：3000円"
              />
            )}
            <FormChipGroup
              label="授業テーマ"
              name="tag_id"
              options={themeOptions}
              isRequired
            />
            <FormTextArea
              label="話してほしいこと"
              placeholder="例：現在の会社での業務について小学生でもわかるように話してほしいです"
              name="lesson_content"
              isRequired
            />
            <FormDatePicker
              label="承諾期限"
              name="accept_dead_at"
              isRequired
              minDate={oneWeekLater}
              monitoredTargets={["possible_dates"]}
            />
            <FormTextArea
              label="生徒の今の様子"
              placeholder=""
              name="student_condition"
            />
            <FormTextArea
              label="今感じている課題"
              placeholder=""
              name="issue"
            />
            {currentSchool.is_match_accepted && (
              <FormCheckboxGroup
                name="lesson_option_ids"
                label="授業運営が不安な場合は、事務局の有料サポートを利用できます。"
                options={lessonOptionsAdjusted}
                disabledValues={disabledValues}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </section>
  );
};

export default RequestForm;
