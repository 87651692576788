import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";

import useTeacherRankBadge from "hooks/useTeacherRankBadge";
import { teacherProfileAtom } from "features/requests/new/store";

import type React from "react";

const TeacherCard: React.FC = () => {
  const teacherProfile = useAtomValue(teacherProfileAtom);
  const teacherRankBadge = useTeacherRankBadge(teacherProfile.teacher_rank);
  const theme = useTheme();

  return (
    <section>
      <Typography variant="h3" sx={{ margin: "0 auto 1rem" }}>
        選択中の先生
      </Typography>
      <Card elevation={0}>
        <CardContent
          sx={{
            "&:last-child": {
              paddingBottom: "16px"
            }
          }}
        >
          <Grid container alignItems="center">
            <Grid
              item
              sx={{ height: { xs: "96px", sm: "120px" } }}
              xs={4}
              sm="auto"
            >
              <Box
                component="img"
                alt={`${teacherProfile.name} avatar`}
                src={teacherProfile.image.url}
                sx={{
                  width: { xs: "96px", sm: "120px" },
                  height: { xs: "96px", sm: "120px" },
                  objectFit: "cover",
                  marginRight: "1rem",
                  borderRadius: "6px"
                }}
              />
            </Grid>
            <Grid item xs={8} sm="auto">
              <Typography color={theme.palette.sub.main} fontSize=".9rem">
                {teacherProfile.organization_name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Typography fontWeight="bold">{teacherProfile.name}</Typography>
                <Box
                  component="img"
                  alt={teacherRankBadge.alt}
                  src={teacherRankBadge.src}
                  sx={{
                    // MEMO: 解像度を合わせるための調整
                    width: "85px",
                    marginLeft: "1rem"
                  }}
                />
              </Box>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <div>
                  {teacherProfile.tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.name}
                      variant="outlined"
                      color="default"
                      sx={{
                        margin: theme.spacing(1),
                        height: "24px",
                        borderRadius: "12px",
                        backgroundColor: theme.palette.backgroundColor?.main,
                        borderColor: theme.palette.muted?.main,
                        color: theme.palette.sub.main
                      }}
                    />
                  ))}
                </div>
              </Box>
            </Grid>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Grid item xs={12} sx={{ margin: ".5rem auto 0" }}>
                {teacherProfile.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag.name}
                    variant="outlined"
                    color="default"
                    sx={{
                      margin: theme.spacing(1),
                      height: "24px",
                      borderRadius: "12px",
                      backgroundColor: theme.palette.backgroundColor?.main,
                      borderColor: theme.palette.muted?.main,
                      color: theme.palette.sub.main
                    }}
                  />
                ))}
              </Grid>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </section>
  );
};

export default TeacherCard;
