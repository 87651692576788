import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import React from "react";

import CommonLoading from "components/atoms/CommonLoading";
import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import RecruitmentItem from "features/schools/mypage/index/molecules/RecruitmentItem";
import dayjs from "libs/dayjs";

import RecruitmentIcon from "assets/common/sidebar/unselected/schools/recruitment.svg";
import { Paper, Stack } from "@mui/material";
import { schoolRecruitmentsAtom } from "../store";

interface HomeRecruitmentsProps {
  isLoading: boolean;
}

const HomeRecruitments: React.FC<HomeRecruitmentsProps> = ({ isLoading }) => {
  const theme = useTheme();
  const [schoolRecruitments] = useAtom(schoolRecruitmentsAtom);

  return (
    <Stack component="section" gap={2} width="100%">
      <CommonMypageHomeSectionTitle
        Icon={<img src={RecruitmentIcon} alt="募集状況" />}
        title="募集状況"
        count={schoolRecruitments?.data_count || 0}
        linkName="すべての募集を見る"
        link="/schools/mypage/recruitments"
      />
      <Stack component={Paper} p={2} gap={2} width="100%" sx={{ boxShadow: 0 }}>
        {!isLoading ? (
          schoolRecruitments ? (
            schoolRecruitments.data.map((schoolRecruitment, index) => (
              <React.Fragment key={index}>
                <RecruitmentItem
                  id={schoolRecruitment.details.id}
                  title={schoolRecruitment.details.title}
                  deadLine={dayjs(
                    schoolRecruitment.details.recruitment_dead_at
                  )}
                  status={schoolRecruitment.details.step}
                />
                <Divider sx={{ width: "100%" }} />
              </React.Fragment>
            ))
          ) : (
            <Typography color={theme.palette.sub.main}>
              募集がありません
            </Typography>
          )
        ) : (
          <CommonLoading />
        )}
      </Stack>
    </Stack>
  );
};

export default HomeRecruitments;
