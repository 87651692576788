import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import DragIndicator from "@mui/icons-material/DragIndicator";
import { useAtom } from "jotai";

import {
  editAuthAtom,
  lessonContentCategoriesAtom
} from "features/requests/meetingSheet/store";

import type React from "react";
import FormTextField from "components/molecules/FormTextField";
import FormSelect from "components/molecules/FormSelect";
import Chip from "components/atoms/mui/Chip";
import FormTextArea from "components/molecules/FormTextArea";

type TimelineCardProps = {
  index: number;
  id: number;
  minutes: number;
  title: string;
  memo: string;
  removeTimeline: () => void;
};

const TimelineCard: React.FC<TimelineCardProps> = (props) => {
  const theme = useTheme();
  const { index, id, removeTimeline } = props;
  const [lessonContentCategories] = useAtom(lessonContentCategoriesAtom);
  const lessonContentCategoriesOptions = lessonContentCategories?.map(
    (category) => ({
      value: category.id,
      label: <Chip key={category.id} label={category.name} size="small" />
    })
  );
  const [editAuth] = useAtom(editAuthAtom);

  // MEMO: フロントでユニークキーを持つ方法も検討・仮実装したが、ドラッグが効かない、ソートが効かない、などの症状が頻発。一旦諦めてidを利用
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled: !editAuth?.has_auth });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      sx={{
        padding: "1rem",
        border: `1px solid ${theme.palette.border?.main}`,
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        display: "flex",
        gap: "1rem",
        alignItems: "center"
      }}
    >
      {editAuth?.has_auth && <DragIndicator fontSize="small" />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap"
          }}
        >
          <Box
            sx={{
              width: { xs: "calc(50% - 1rem)" },
              flexGrow: { xs: 1 }
            }}
          >
            <FormTextField
              label="時間配分"
              placeholder="例：5"
              name={`timelines[${index}].minutes`}
              type="number"
              InputProps={{
                endAdornment: "分",
                inputProps: {
                  min: 0
                }
              }}
              sx={{
                maxWidth: "200px"
              }}
              fullWidth
              noLabel
              readOnly={!editAuth?.has_auth}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              order: { xs: 3 }
            }}
          >
            <FormTextField
              label="コンテンツ名"
              placeholder="例：プログラミングが使われる場所"
              name={`timelines[${index}].title`}
              fullWidth
              noLabel
              readOnly={!editAuth?.has_auth}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "calc(50% - 1rem)" },
              flexGrow: { xs: 1 }
            }}
          >
            <FormSelect
              label="カテゴリー"
              name={`timelines[${index}].lesson_content_category_id`}
              options={lessonContentCategoriesOptions || []}
              noLabel
              fullWidth
              readOnly={!editAuth?.has_auth}
            />
          </Box>
        </Box>
        <FormTextArea
          label="具体的な内容"
          name={`timelines[${index}].memo`}
          placeholder="例：プログラムが社会のどこで使われているかを知る"
          noLabel
          readOnly={!editAuth?.has_auth}
        />

        {editAuth?.has_auth && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Button
              onClick={removeTimeline}
              size="small"
              variant="text"
              sx={{
                color: theme.palette.error.main,
                "&:hover": {
                  backgroundColor: "rgba(238,29,0, 0.1)"
                }
              }}
            >
              削除
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TimelineCard;
