import useAspidaSWR from "@aspida/swr";
import { Methods } from "api/api/v1/lesson_recruitments/_lessonRecruitmentId@string/candidates";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtom } from "jotai";
import aspida from "libs/aspida";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  recruitmentAtom,
  schoolTeacherAtom,
  candidatesCountAtom,
  candidateAtom,
  createCandidateStepAtom
} from "./store";
import createCandidateFormSchema from "./constants/createCandidateFormSchema";

const useShowPageApi = (id: string) => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const [, setRecruitment] = useAtom(recruitmentAtom);
  const [, setSchoolTeacher] = useAtom(schoolTeacherAtom);
  const [, setCandidatesCount] = useAtom(candidatesCountAtom);
  const [, setCandidate] = useAtom(candidateAtom);
  const [, setStep] = useAtom(createCandidateStepAtom);
  const [cancelIsLoading, setCancelIsLoading] = useState(false);
  const form = useForm<Methods["post"]["reqBody"]>({
    resolver: yupResolver(createCandidateFormSchema),
    mode: "all"
  });

  const { isLoading, mutate } = useAspidaSWR(
    aspida(headers).api.v1.lesson_recruitments._lessonRecruitmentId(id),
    {
      onSuccess: (response) => {
        setRecruitment(response.details);
        setSchoolTeacher(response.school_teacher);
        setCandidatesCount(response.candidates_count);
        setCandidate(response.candidate);
      },
      onError: () => {
        addErrorMessage("募集情報の取得に失敗しました。");
      }
    }
  );

  const cancelCandidate = async () => {
    try {
      setCancelIsLoading(true);
      const response = await aspida(headers)
        .api.v1.lesson_recruitments._lessonRecruitmentId(id)
        .candidates.cancel_candidate.patch({});
      if (response.status === 200) {
        addSuccessMessage("応募をキャンセルしました。");
        await mutate();
      }
    } catch (error) {
      addErrorMessage("応募のキャンセルに失敗しました。");
    } finally {
      setCancelIsLoading(false);
    }
  };

  const createCandidate = async () => {
    try {
      const body = form.getValues();
      const response = await aspida(headers)
        .api.v1.lesson_recruitments._lessonRecruitmentId(id)
        .candidates.post({
          body
        });
      if (response.status === 200) {
        addSuccessMessage("応募が完了しました。");
        setStep(1);
        await mutate();
      }
    } catch (error) {
      addErrorMessage("応募に失敗しました。");
    }
  };
  return { isLoading, cancelCandidate, cancelIsLoading, createCandidate, form };
};

export default useShowPageApi;
