import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FirstViewBackground from "assets/schoolLp/FirstViewBackground.png";
import ServiceImage from "assets/schoolLp/ServiceImage.png";
import ServiceImageSP from "assets/schoolLp/ServiceImageSP.png";
import ExampleList from "features/root/lp/school/molecules/ExampleList";
import ServiceList from "features/root/lp/school/molecules/ServiceList";

import type React from "react";

const Service: React.FC = () => (
  <section>
    <Box
      sx={{
        padding: { xs: "1.5rem 0", md: "2rem 0" },
        backgroundImage: { xs: "none", md: `url(${FirstViewBackground})` },
        backgroundRepeat: { xs: "none", md: "no-repeat" },
        backgroundPosition: { xs: "none", md: "bottom center" },
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Box
        sx={{
          margin: "0 auto",
          width: "90%"
        }}
      >
        <Typography
          variant="h2"
          align="center"
          color="#FFE6AD"
          fontSize="2.4rem"
        >
          SERVICE
        </Typography>
        <Typography
          variant="h3"
          align="center"
          fontSize="1.5rem"
          sx={{
            margin: "1.5rem 0 1rem"
          }}
        >
          複業先生について
        </Typography>
        <Typography align="center">
          <Typography sx={{ display: "inline-block" }}>
            学校と外部人材をつなぎ、
          </Typography>
          <Typography sx={{ display: "inline-block" }}>
            授業の実施までサポートします。
          </Typography>
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          margin: { xs: "2rem auto", md: "1rem auto" }
        }}
      >
        <Box
          component="picture"
          sx={{
            display: "block",
            width: { xs: "100%", md: "90%" },
            maxWidth: "684px",
            margin: "0 auto"
          }}
        >
          <Box
            component="source"
            srcSet={ServiceImageSP}
            media="(max-width: 600px)"
            sx={{ width: "100%" }}
          />
          <Box
            component="img"
            src={ServiceImage}
            alt="first view"
            sx={{ width: "100%" }}
          />
        </Box>
      </Box>
      <ServiceList />
    </Box>
    <div>
      <ExampleList />
    </div>
  </section>
);

export default Service;
