import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "store/AuthStore";

import type React from "react";

const WithdrawalContents: React.FC = () => {
  const theme = useTheme();
  const currentUser = useAtomValue(currentUserAtom);

  if (currentUser.is_deletion_request_in_progress) {
    return (
      <div>
        <Typography>
          退会申請を受け付けました。 退会手続きが完了するまでお待ちください。
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography>
        退会を希望の方は、
        <Box
          component={Link}
          to="account-deletion-request"
          sx={{
            color: theme.palette.link?.main,
            textDecoration: "none",
            fontWeight: "bold"
          }}
        >
          こちらのリンク
        </Box>
        からお願いいたします
      </Typography>
    </div>
  );
};

export default WithdrawalContents;
