import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type LessonRequestConfirmBlockProps = {
  title: string;
  value: string | React.ReactNode;
  oneLine?: boolean;
};

const LessonRequestConfirmBlock: React.FC<LessonRequestConfirmBlockProps> = (
  props
) => {
  const { title, value, oneLine } = props;

  return (
    <Box
      sx={{
        display: oneLine ? "flex" : "block",
        alignItems: "center",
        gap: "1rem"
      }}
    >
      <Typography fontWeight="bold" fontSize="1.1rem" marginBottom=".2rem">
        {title}
      </Typography>
      {typeof value === "string" ? <Typography>{value}</Typography> : value}
    </Box>
  );
};

export default LessonRequestConfirmBlock;
