import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useQueryParams from "hooks/useQueryParams";

import type React from "react";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { useFormContext } from "react-hook-form";

type ProfileEditContentsAccordionProps = {
  title: string;
  children: React.ReactNode;
  label: string;
  fields?: string[];
};

type QueryParamsType = {
  accordion?: string;
};

const ProfileEditContentsAccordion: React.FC<
  ProfileEditContentsAccordionProps
> = (props) => {
  const { title, children, label, fields } = props;
  const { getQueryParams, setQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();
  const { accordion } = getQueryParams(["accordion"]);

  const {
    formState: { dirtyFields, errors }
  } = useFormContext();

  // 該当するフィールドのいずれかが変更されており、エラーがない
  const submitActive =
    fields?.some((field) => dirtyFields[field]) &&
    Object.keys(errors).length === 0;

  return (
    <Accordion
      elevation={0}
      expanded={accordion === label}
      onChange={(event, expanded) => {
        if (expanded) {
          setQueryParams({ accordion: label });
        } else {
          deleteQueryParams("accordion");
        }
      }}
      sx={{
        "&:before": {
          display: "none"
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${label}-profile-edit-content`}
        id={`${label}-profile-edit-header`}
        sx={{
          fontSize: "1.1rem",
          fontWeight: 600
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
            gap: "1.5rem"
          }}
        >
          {children}
          {fields && (
            <FormSubmitButton
              label="保存する"
              size="large"
              disabled={!submitActive}
            />
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProfileEditContentsAccordion;
