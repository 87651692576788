import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useAtom } from "jotai";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  updateDateDisplayAtom,
  editAuthAtom
} from "features/requests/meetingSheet/store";
import Button from "components/atoms/mui/Button";

const Header: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();

  const [updateDateDisplay] = useAtom(updateDateDisplayAtom);
  const [editAuth] = useAtom(editAuthAtom);
  const updateDateMsg = () => {
    if (editAuth?.is_after_lesson) return null;
    if (editAuth?.has_auth) {
      return updateDateDisplay ? `自動保存（${updateDateDisplay}）` : "未保存";
    }
    return updateDateDisplay ? `更新（${updateDateDisplay}）` : "未更新";
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{ backgroundColor: theme.palette.link?.dark }}
        position="fixed"
        elevation={0}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0 auto",
              width: "100%",
              maxWidth: "960px"
            }}
          >
            <Button
              variant="text"
              startIcon={<KeyboardArrowLeft />}
              disableElevation
              onClick={() => history.goBack()}
              sx={{ color: theme.palette.common.white }}
            >
              戻る
            </Button>
            <Typography
              fontWeight="bold"
              fontSize="1.1rem"
              color={theme.palette.common.white}
              align="center"
            >
              授業準備シート
            </Typography>

            <Typography
              fontSize=".8rem"
              color={theme.palette.common.white}
              align="right"
            >
              {updateDateMsg()}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
