import { useEffect } from "react";
import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import useQueryParams from "hooks/useQueryParams";
import { useAtom, useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import { paginationData } from "store/MasterData";
import { kindOfSchoolLessonsAtom, schoolLessonsAtom } from "./store";

type QueryParamsType = {
  pages?: number;
};

const useMyLessonsPageApi = () => {
  const currentSchool = useAtomValue(currentSchoolAtom);
  const [, setSchoolLessons] = useAtom(schoolLessonsAtom);
  const [kindOfSchoolLessons, setKindOfSchoolLessons] = useAtom(
    kindOfSchoolLessonsAtom
  );
  const [, setPagination] = useAtom(paginationData);

  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const { getQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();

  const query = getQueryParams(["pages"]);

  useEffect(() => {
    deleteQueryParams("pages");
  }, [kindOfSchoolLessons]);

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.school_teachers._schoolTeacherId_string(
      currentSchool.id.toString()
    ).lessons,
    {
      query: { status: kindOfSchoolLessons, pages: query.pages?.toString() },
      onSuccess: (data) => {
        setSchoolLessons(data);
        setPagination(data.pagination || null);
      },
      onError: () => {
        addErrorMessage("授業一覧の取得に失敗しました。");
      }
    }
  );

  return { isLoading, kindOfSchoolLessons, setKindOfSchoolLessons };
};

export default useMyLessonsPageApi;
