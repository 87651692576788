import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import Button from "components/atoms/mui/Button";
import FormDatetime from "components/molecules/FormDatetime";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import FormRadioGroup from "components/molecules/FormRadioGroup";

import { Methods } from "api/api/v1/lessons/_lessonId@string";

import { patchMeetingDatimesFormSchema } from "features/schools/mypage/lessons/id/constants/patchMeetingDatimesFormSchema";

import { patchMeetingDatimesDefaultValues } from "features/schools/mypage/lessons/id/constants/patchMeetingDatimesDefaultValues";
import type { MeetingScheduleStepType } from "../organisms/MeetingScheduleBlock";

type patchMeetingDatimesFormSchemaType = Methods["patch"]["reqBody"];

type MeetingDateFormProps = {
  patchLessonMeetingPossibleDatimes: (
    data: patchMeetingDatimesFormSchemaType
  ) => void;
  setStep: React.Dispatch<React.SetStateAction<MeetingScheduleStepType>>;
};

const MeetingDateForm: React.FC<MeetingDateFormProps> = (props) => {
  const { patchLessonMeetingPossibleDatimes, setStep } = props;
  const theme = useTheme();

  const methods = useForm<patchMeetingDatimesFormSchemaType>({
    mode: "onChange",
    resolver: yupResolver(patchMeetingDatimesFormSchema),
    defaultValues: patchMeetingDatimesDefaultValues
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
          onSubmit={methods.handleSubmit(patchLessonMeetingPossibleDatimes)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem"
            }}
          >
            <Typography variant="h2">
              すでに打ち合わせ日程が決まっている
            </Typography>
            <Typography>
              決まっている打ち合わせ日程を入力してください
            </Typography>
          </Box>
          <FormDatetime
            name="meeting_start_at"
            label="打ち合わせ日時"
            isRequired
          />
          <FormRadioGroup
            name="is_meeting_online"
            label="実施方法"
            supplementalText="基本的に打ち合わせはオンラインを想定しています。対面の場合は交通費の取り扱いなどを調整してください。"
            options={[
              { value: true, label: "オンライン" },
              { value: false, label: "対面" }
            ]}
            isRequired
            raidoBackgroundColor={theme.palette.common.white}
          />
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              justifyContent: "flex-end"
            }}
          >
            {/* // MEMO: 背景がprimayBackgroudの場合の指定の検討が必要 */}
            <Button
              variant="outlined"
              onClick={() => setStep("selectWay")}
              sx={{
                backgroundColor: "#fff",
                "&:hover": {
                  backgroundColor: "#f5f5f5"
                }
              }}
            >
              戻る
            </Button>
            <FormSubmitButton
              label="日程を送信"
              disabled={!methods.formState.isValid}
            />
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default MeetingDateForm;
