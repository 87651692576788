import { Box, Typography } from "@mui/material";

import ConfirmStep01 from "./ConfirmStep01";
import ConfirmStep02 from "./ConfirmStep02";
import ConfirmStep03 from "./ConfirmStep03";
import ConfirmStep04 from "./ConfirmStep04";

const NewProfileFormConfirm: React.FC = () => (
  <Box
    component="section"
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
      padding: "1rem 1rem .5rem",
      borderRadius: "4px"
    }}
  >
    <Typography variant="h1" align="center">
      以下の内容で送信してもよろしいでしょうか？
    </Typography>
    <ConfirmStep01 />
    <ConfirmStep02 />
    <ConfirmStep03 />
    <ConfirmStep04 />
  </Box>
);
export default NewProfileFormConfirm;
