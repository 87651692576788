import { atom } from "jotai";

import { Methods as RequestsMethods } from "api/api/v1/teachers/_teacherId@string/lesson_requests";
import { Methods as RecruitmentsMethods } from "api/api/v1/teachers/_teacherId@string/lesson_recruitments";
import { Methods as LessonsMethods } from "api/api/v1/teachers/_teacherId@string/lessons";

export const teacherRequestsAtom = atom<
  RequestsMethods["get"]["resBody"] | null
>(null);

export const teacherLessonsAtom = atom<LessonsMethods["get"]["resBody"] | null>(
  null
);

export const teacherRecruitmentsAtom = atom<
  RecruitmentsMethods["get"]["resBody"] | null
>(null);
