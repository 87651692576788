import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type React from "react";
import useTeacherRankBadge from "hooks/useTeacherRankBadge";

type CandidateFieldMenuItemProps = {
  candidateName: string;
  candidateImage?: string;
  candidateRank: string;
};

const CandidateFieldMenuItem: React.FC<CandidateFieldMenuItemProps> = (
  props
) => {
  const { candidateName, candidateImage, candidateRank } = props;
  const rankBadge = useTeacherRankBadge(candidateRank);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: ".5rem"
      }}
    >
      <Avatar
        src={candidateImage}
        alt={`${candidateName}のプロフィール画像`}
        sx={{
          width: "1.5rem",
          height: "1.5rem"
        }}
      />
      <Typography>{candidateName}</Typography>
      <Box
        component="img"
        src={rankBadge.src}
        alt={rankBadge.alt}
        sx={{ height: "1rem" }}
      />
    </Box>
  );
};

export default CandidateFieldMenuItem;
