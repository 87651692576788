import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import FormLabel from "components/atoms/FormLabel";
import TextArea from "components/atoms/fields/TextArea";
import React from "react";

import { useFormContext, Controller } from "react-hook-form";

type FormTextAreaProps = {
  name: string;
  label: string;
  supplementalText?: string | React.ReactNode;
  isRequired?: boolean;
  placeholder: string;
  maxCharacters?: number;
  maxRows?: number;
  minRows?: number;
  disabled?: boolean;
  readOnly?: boolean;
  noLabel?: boolean;
  customColor?: string;
};

const FormTextArea: React.FC<FormTextAreaProps> = (props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const {
    name,
    label,
    supplementalText,
    isRequired,
    placeholder,
    maxCharacters,
    maxRows,
    minRows,
    noLabel = false,
    disabled = false,
    readOnly = false,
    customColor
  } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: ".5rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "0.25rem"
        }}
      >
        {noLabel ? (
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {label}
          </Typography>
        ) : (
          <FormLabel
            labelName={label}
            isRequired={isRequired}
            customColor={customColor}
          />
        )}
        {supplementalText && (
          <Typography variant="caption" sx={{ color: theme.palette.sub.main }}>
            {supplementalText}
          </Typography>
        )}
      </Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextArea
            {...field}
            error={!!errors[name]}
            helperText={errors[name]?.message?.toString()}
            placeholder={placeholder}
            maxCharacters={maxCharacters}
            disabled={disabled}
            maxRows={maxRows}
            minRows={minRows || 3}
            InputProps={{
              readOnly
            }}
          />
        )}
      />
    </Box>
  );
};

export default FormTextArea;
