import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";
import { FormProvider } from "react-hook-form";

import type React from "react";
import useSchoolAuthApi from "hooks/useSchoolAuthApi";
import FormPasswordField from "components/molecules/FormPasswordField";
import FormSubmitButton from "components/molecules/FormSubmitButton";

// MEMO: 後々廃止予定のため、実装は雑に
const ChangePassword: React.FC = () => {
  const { updatePassword, updatePasswordForm } = useSchoolAuthApi();
  const theme = useTheme();

  return (
    <CommonLayout>
      <Meta title="パスワード変更" />
      <FormProvider {...updatePasswordForm}>
        <form onSubmit={updatePasswordForm.handleSubmit(updatePassword)}>
          <Container
            maxWidth="sm"
            sx={{
              padding: "1.5rem 0",
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              width: { xs: "90%", sm: "100%" },
              margin: "0 auto"
            }}
          >
            <Typography variant="h2">メールアドレスを変更</Typography>
            <Typography color={theme.palette.sub.main} fontSize=".9rem">
              パスワードは8文字以上で、半角英数字をそれぞれ1文字以上含めてください。
            </Typography>
            <FormPasswordField
              name="current_password"
              label="現在のパスワード"
            />
            <FormPasswordField name="password" label="新しいパスワード" />
            <FormPasswordField
              name="password_confirmation"
              label="新しいパスワード（確認）"
            />
            <FormSubmitButton
              label="パスワードを変更する"
              size="large"
              fullWidth
            />
          </Container>
        </form>
      </FormProvider>
    </CommonLayout>
  );
};

export default ChangePassword;
