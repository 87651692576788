import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { ConfigType, Dayjs } from "dayjs";
import dayjs from "libs/dayjs";

import FormLabel from "components/atoms/FormLabel";
import DatePicker from "components/atoms/fields/DatePicker";

import { useFormContext, Controller } from "react-hook-form";

type FormDatePickerProps = {
  name: string;
  label: string;
  supplementalText?: string;
  isRequired?: boolean;
  width?: string;
  minDate?: Dayjs;
  monitoredTargets?: string[];
};

const FormDatePicker: React.FC<FormDatePickerProps> = (props) => {
  const {
    control,
    trigger,
    formState: { errors }
  } = useFormContext();
  const {
    name,
    label,
    supplementalText,
    isRequired,
    width,
    minDate,
    monitoredTargets
  } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: width || "100%",
        gap: ".5rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "0.25rem"
        }}
      >
        <FormLabel labelName={label} isRequired={isRequired} />
        {supplementalText && (
          <Typography variant="caption" sx={{ color: theme.palette.sub.main }}>
            {supplementalText}
          </Typography>
        )}
      </Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            onChange={async (e) => {
              field.onChange(e);
              // MEMO: 各FormFieldコンポーネントに仕込んでもいいかも
              if (monitoredTargets) {
                await monitoredTargets.forEach((target) => {
                  trigger(target);
                });
              }
            }}
            error={!!errors[name]}
            helperText={errors[name]?.message?.toString()}
            width="100%"
            minDate={dayjs(minDate)}
          />
        )}
      />
    </Box>
  );
};

export default FormDatePicker;
