import { atom } from "jotai";

import { Methods } from "api/api/v1/teachers/_teacherId@string/lesson_requests/_lessonRequestId@string";

export const detailsAtom = atom<
  Methods["get"]["resBody"]["details"] | undefined
>(undefined);

export const schoolTeacherAtom = atom<
  Methods["get"]["resBody"]["send_user"] | undefined
>(undefined);

export const supporterAtom = atom<
  Methods["get"]["resBody"]["supporter"] | undefined
>(undefined);

export const possibleDatesAtom = atom<
  Methods["get"]["resBody"]["possible_dates"] | undefined
>(undefined);
