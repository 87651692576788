import { useEffect } from "react";
import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import useQueryParams from "hooks/useQueryParams";
import { useAtom, useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import { paginationData } from "store/MasterData";
import { kindOfSchoolRequestsAtom, schoolRequestsAtom } from "./store";

type QueryParamsType = {
  pages?: number;
};

const useMyRequestsPageApi = () => {
  const currentSchool = useAtomValue(currentSchoolAtom);
  const [kindOfSchoolRequests, setKindOfSchoolRequests] = useAtom(
    kindOfSchoolRequestsAtom
  );
  const [, setSchoolRequests] = useAtom(schoolRequestsAtom);
  const [, setPagination] = useAtom(paginationData);

  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const { getQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();

  const query = getQueryParams(["pages"]);

  useEffect(() => {
    deleteQueryParams("pages");
  }, [kindOfSchoolRequests]);

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.school_teachers._schoolTeacherId_string(
      currentSchool.id.toString()
    ).lesson_requests,
    {
      query: { status: kindOfSchoolRequests, pages: query.pages?.toString() },
      onSuccess: (data) => {
        setSchoolRequests(data);
        setPagination(data.pagination || null);
      },
      onError: () => {
        addErrorMessage("依頼一覧の取得に失敗しました。");
      }
    }
  );

  return { isLoading, kindOfSchoolRequests, setKindOfSchoolRequests };
};

export default useMyRequestsPageApi;
