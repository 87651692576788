// MEMO: ハイライトと短縮表示を同時に行うコンポーネント

import React from "react";
import Highlighter from "react-highlight-words";

import { Methods } from "api/api/v1/teachers/index";

import {
  shortSentences,
  findKeywordsIndex,
  mergeIndex,
  highlightResult
} from "utils/text";

type HighlighterWithShortSentencesProps = {
  highlightText: string;
  keywords: Methods["get"]["resBody"]["keywords"];
};

const HighlighterWithShortSentences: React.FC<
  HighlighterWithShortSentencesProps
> = (props) => {
  const { highlightText = "", keywords } = props;

  if (!keywords) {
    return (
      <Highlighter
        searchWords={[]}
        autoEscape
        textToHighlight={shortSentences(highlightText)}
      />
    );
  }

  const keyWordsIndex = findKeywordsIndex(highlightText, keywords);

  if (!keyWordsIndex.length) {
    return (
      <Highlighter
        searchWords={[]}
        autoEscape
        textToHighlight={shortSentences(highlightText)}
      />
    );
  }

  const mergedIndex = mergeIndex(keyWordsIndex, highlightText);
  const result = highlightResult(mergedIndex, highlightText);

  return (
    <Highlighter searchWords={keywords} autoEscape textToHighlight={result} />
  );
};

export default HighlighterWithShortSentences;
