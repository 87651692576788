import { useEffect, useState } from "react";
import type { Swiper as SwiperCore } from "swiper";

import useTheme from "@mui/material/styles/useTheme";
import { useMediaQuery } from "@mui/material";

import useQueryParams from "./useQueryParams";

type PageTabSlidesProps = {
  tabList: Array<string>;
  resetTabAt: "all" | "sp" | "pc";
};

type QueryParamsType = {
  tab_name?: string;
};

// MEMO: page単位でtabを切り替える際に使用するhooks
export const usePageTabSlides = (props: PageTabSlidesProps) => {
  // MEMO: 複数ファイルで使おうとするとAtomに押し込まなくては行けなくなるため注意
  const { tabList, resetTabAt } = props;
  const { getQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();
  const { tab_name } = getQueryParams(["tab_name"]);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [tabIndex, setTabIndex] = useState(
    tab_name ? tabList.indexOf(tab_name) : 0
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onTabSlidesChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    swiper?.slideTo(newValue);
  };

  const onSwiper = (currentSwiper: SwiperCore) => {
    const swiperInstance = currentSwiper;
    setSwiper(swiperInstance);

    swiperInstance.slideTo(tabIndex);
  };

  const onSlideChange = (currentSwiper: SwiperCore) => {
    setTabIndex(currentSwiper.activeIndex);
  };

  const resetTabSlides = () => {
    setTabIndex(0);
    swiper?.slideTo(0);
    deleteQueryParams("tab_name");
  };

  useEffect(() => {
    switch (resetTabAt) {
      case "all":
        break;
      case "sp":
        if (!isMobile) {
          resetTabSlides();
        }
        break;
      case "pc":
        if (isMobile) {
          resetTabSlides();
        }
        break;
      default:
        break;
    }
  }, [isMobile]);

  return {
    tabIndex,
    onTabSlidesChange,
    onSwiper,
    onSlideChange,
    resetTabSlides
  };
};
