import { atom } from "jotai";
import { Methods } from "api/api/v1/school_teacher_profiles/new";

export const schoolDivisionsData = atom<
  Methods["get"]["resBody"]["school_divisions"]
>([]);
export const schoolTypesData = atom<Methods["get"]["resBody"]["school_types"]>(
  []
);
export const prefecturesData = atom<Methods["get"]["resBody"]["prefectures"]>(
  []
);
export const knowReasonsData = atom<Methods["get"]["resBody"]["know_reasons"]>(
  []
);
