import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";

import {
  editAuthAtom,
  editUserNameWithPrefixAndSuffixAtom
} from "features/requests/meetingSheet/store";

import type React from "react";

const MeetingSheetLeading: React.FC = () => {
  const editAuth = useAtomValue(editAuthAtom);
  const editUserName = useAtomValue(editUserNameWithPrefixAndSuffixAtom);

  if (editAuth?.is_after_lesson) {
    return (
      <Typography
        align="center"
        sx={{
          margin: "0 auto 1.5rem",
          padding: "1.5rem 0 0",
          width: { sx: "90%", md: "inferit" }
        }}
      >
        <Typography component="span" sx={{ display: "inline-block" }}>
          授業お疲れ様でした。
        </Typography>
        <br />
        <Typography component="span" sx={{ display: "inline-block" }}>
          今回の授業の経験を活かし、
        </Typography>
        <Typography component="span" sx={{ display: "inline-block" }}>
          より良い授業を作りましょう！
        </Typography>
      </Typography>
    );
  }

  if (editAuth?.has_auth) {
    return (
      <Typography
        align="center"
        sx={{
          margin: "0 auto 1.5rem",
          padding: "1.5rem 0 0",
          width: { sx: "90%", md: "inferit" }
        }}
      >
        <Typography component="span" sx={{ display: "inline-block" }}>
          授業準備シートに沿って記入し、
        </Typography>
        <Typography component="span" sx={{ display: "inline-block" }}>
          記入を終えたら
        </Typography>
        <Typography component="span" sx={{ display: "inline-block" }}>
          保存ボタンを押してください。
        </Typography>
        <br />
        <Typography component="span" sx={{ display: "inline-block" }}>
          打ち合わせでは、画面共有をしながら
        </Typography>
        <Typography component="span" sx={{ display: "inline-block" }}>
          相談していくのがおすすめです。
        </Typography>
      </Typography>
    );
  }

  return (
    <Typography
      align="center"
      sx={{
        margin: "0 auto 1.5rem",
        padding: "1.5rem 0 0",
        width: { sx: "90%", md: "inferit" }
      }}
    >
      <Typography component="span" sx={{ display: "inline-block" }}>
        シートは{editUserName}が
      </Typography>
      <Typography component="span" sx={{ display: "inline-block" }}>
        編集しています
      </Typography>
      <br />
      <Typography component="span" sx={{ display: "inline-block" }}>
        打ち合わせでは、画面共有をしながら
      </Typography>
      <Typography component="span" sx={{ display: "inline-block" }}>
        相談していくのがおすすめです。
      </Typography>
    </Typography>
  );
};

export default MeetingSheetLeading;
