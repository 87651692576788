import { useAtomValue } from "jotai";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

import CompleteIcon from "components/atoms/CompleteIcon";
import CommonLayout from "components/layouts/CommonLayout";

import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

import type React from "react";

const Complete: React.FC = () => {
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);

  const mypageLink = () => {
    if (isLoggedIn) {
      return "/teachers/mypage";
    }
    if (schoolIsLoggedIn) {
      return "/schools/mypage";
    }
    return "";
  };

  return (
    <CommonLayout>
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12} sx={{ padding: "1.5rem 0" }}>
            <CompleteIcon />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ margin: ".5rem auto" }}>
              申請が完了しました。
              <br />
              アカウントの削除まで、しばらくお待ちください。
              <br />
              <RouterLink to={mypageLink()}>マイページへ</RouterLink>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </CommonLayout>
  );
};

export default Complete;
