import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import useQueryParams from "hooks/useQueryParams";

import type React from "react";
import SectionLoading from "components/atoms/SectionLoading";

type SettingsContentsAccordionProps = {
  title: string;
  children: React.ReactNode;
  label: string;
  isLoading?: boolean;
};

type QueryParamsType = {
  accordion?: string;
};

const SettingsContentsAccordion: React.FC<SettingsContentsAccordionProps> = (
  props
) => {
  const { title, children, label, isLoading } = props;
  const { getQueryParams, setQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();

  const { accordion } = getQueryParams(["accordion"]);

  return (
    <div>
      <Accordion
        elevation={0}
        expanded={accordion === label}
        onChange={(event, expanded) => {
          if (expanded) {
            setQueryParams({ accordion: label });
          } else {
            deleteQueryParams("accordion");
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${label}-settings-content`}
          id={`${label}-settings-header`}
          sx={{
            fontSize: "1.1rem",
            fontWeight: "bold"
          }}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? <SectionLoading /> : children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SettingsContentsAccordion;
