import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import type React from "react";

type HeaderProps = {
  title: string;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { title } = props;
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0 auto",
              width: "100%",
              maxWidth: "960px",
              position: "relative"
            }}
          >
            <Typography
              fontWeight="bold"
              fontSize="1.1rem"
              align="center"
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                color: theme.palette.common.white
              }}
            >
              {title}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
