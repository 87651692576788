import React from "react";

import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";

import Button from "components/atoms/mui/Button";

type CollapseControlButtonProps = {
  seeAll: boolean;
  setSeeAll: React.Dispatch<React.SetStateAction<boolean>>;
};

const CollapseControlButton: React.FC<CollapseControlButtonProps> = (props) => {
  const { seeAll, setSeeAll } = props;
  const theme = useTheme();

  return (
    <Button
      variant="text"
      color="secondary"
      size="small"
      startIcon={
        seeAll ? (
          <Remove
            sx={{
              color: theme.palette.link?.main,
              height: "1rem",
              width: "1rem"
            }}
          />
        ) : (
          <Add
            sx={{
              color: theme.palette.link?.main,
              height: "1rem",
              width: "1rem"
            }}
          />
        )
      }
      onClick={() => setSeeAll(!seeAll)}
    >
      <Typography
        color={theme.palette.link?.main}
        fontSize=".8rem"
        sx={{
          textDecoration: "underline"
        }}
      >
        {seeAll ? "閉じる" : "元の依頼内容を詳しく見る"}
      </Typography>
    </Button>
  );
};

export default CollapseControlButton;
