import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import RemoveIcon from "@mui/icons-material/Remove";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useAtomValue } from "jotai";
import { useState } from "react";
import dayjs from "libs/dayjs";

import LessonThemeChip from "components/atoms/dataDisplay/LessonThemeChip";
import getStatusLabel from "services/recruitments/GetStatusLabel";

import ImportantInfoCard from "components/molecules/ImportantInfoCard";
import CommonInfoContent from "components/molecules/CommonInfoContent";
import {
  formatDate,
  formatDateTimeStringWithDay,
  checkDeadline
} from "utils/date";
import { recruitmentAtom } from "../store";

const RecruitmentInfo: React.FC = () => {
  const [seeAll, setSeeAll] = useState(false);
  const recruitment = useAtomValue(recruitmentAtom);

  const deadline = dayjs(recruitment?.recruitment_dead_at);
  const { limit } = checkDeadline(deadline.toString());

  return (
    <section>
      <Collapse in={seeAll} collapsedSize={240} sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginBottom: "3rem"
          }}
        >
          <Typography>
            掲載日：{formatDate(recruitment?.created_at || "")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem"
            }}
          >
            {getStatusLabel(recruitment?.step || "")}
            <Typography variant="h1">{recruitment?.title}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem"
              }}
            >
              {recruitment?.proposed_dates?.data.map((proposedDate, index) => (
                <Typography key={index}>
                  <Typography component="span" fontWeight="bold">
                    第{index + 1}希望：
                  </Typography>
                  <span>
                    {formatDateTimeStringWithDay(
                      proposedDate.start_at_datetime
                    )}{" "}
                    ({recruitment?.school_minutes}分間)
                  </span>
                </Typography>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                flexDirection: { xs: "column", sm: "row" }
              }}
            >
              <ImportantInfoCard
                Icon={DescriptionOutlinedIcon}
                title="実施方法"
                mainInfo={recruitment?.is_online ? "オンライン" : "対面"}
              />
              <ImportantInfoCard
                Icon={PermIdentityIcon}
                title="対象生徒"
                mainInfo={recruitment?.school_grade_name || ""}
                subInfo={`${recruitment?.student_number}人`}
              />
              <ImportantInfoCard
                Icon={AccessTimeIcon}
                title="募集期限"
                mainInfo={
                  recruitment?.step === "now_recruting"
                    ? `あと ${limit}日`
                    : "募集終了"
                }
                subInfo={
                  recruitment?.step === "now_recruting"
                    ? formatDate(recruitment?.recruitment_dead_at || "")
                    : "------"
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem"
            }}
          >
            <Typography fontWeight="bold">授業テーマ</Typography>
            <div>
              <LessonThemeChip name={recruitment?.tag?.name} />
            </div>
          </Box>
          {recruitment?.lesson_contents && (
            <CommonInfoContent
              title="授業内容"
              content={recruitment?.lesson_contents}
              multiline
            />
          )}
          {recruitment?.want_to && (
            <CommonInfoContent
              title="こんな先生に来てほしい"
              content={recruitment?.want_to}
              multiline
            />
          )}
          {recruitment?.student_condition && (
            <CommonInfoContent
              title="生徒の今の様子"
              content={recruitment?.student_condition}
              multiline
            />
          )}
          {recruitment?.issue && (
            <CommonInfoContent
              title="生徒の課題"
              content={recruitment?.issue}
              multiline
            />
          )}
          {recruitment?.travel_cost && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem"
              }}
            >
              <Typography fontWeight="bold">交通費の取り扱い</Typography>
              <Typography>{recruitment?.travel_cost}</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            left: 0,
            textAlign: { xs: "center", sm: "left" },
            background: {
              xs: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)",
              sm: "transparent"
            }
          }}
        >
          <Button
            variant="text"
            color="secondary"
            startIcon={seeAll ? <RemoveIcon /> : <AddIcon />}
            onClick={() => setSeeAll(!seeAll)}
          >
            {seeAll ? "募集内容を閉じる" : "募集内容を詳しく見る"}
          </Button>
        </Box>
      </Collapse>
    </section>
  );
};

export default RecruitmentInfo;
