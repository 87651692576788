// 候補者選択時に授業時間とオプションから価格を計算するカスタムフック
import prices from "constants/prices";
import { useAtomValue } from "jotai";
import { useFormContext } from "react-hook-form";
import { lessonOptionsAtom, recruitmentAtom } from "../store";

const usePrice = () => {
  const { watch } = useFormContext();
  const lessonOptions = useAtomValue(lessonOptionsAtom);
  const recruitment = useAtomValue(recruitmentAtom);
  const schoolMinutes = recruitment?.school_minutes || 0;
  const selectedOptionIds: number[] = watch("lesson_option_ids", []);

  let basicPrice;
  if (schoolMinutes > 120) {
    basicPrice = prices.lesson3time;
  } else if (schoolMinutes > 60) {
    basicPrice = prices.lesson2time;
  } else {
    basicPrice = prices.lesson1time;
  }

  const optionPrices = [
    { name: "基本料金", price: basicPrice },
    ...selectedOptionIds.map((id) => {
      const option = lessonOptions?.find((option) => option.id === id);
      if (!option) return undefined;
      return { name: option.name, price: option.amount };
    })
  ];

  const totalPrice = optionPrices.reduce(
    (acc, option) => acc + (option?.price || 0),
    0
  );

  const priceBreakdownString = optionPrices
    .map((option) => `${option?.name}：${option?.price}円`)
    .join(" + ");

  return { basicPrice, optionPrices, priceBreakdownString, totalPrice };
};

export default usePrice;
