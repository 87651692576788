import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { useState } from "react";

import type React from "react";

type JudgeNgAlertProps = {
  handlePostRequest: () => void;
  reason?: string;
};

const JudgeNgAlert: React.FC<JudgeNgAlertProps> = (props) => {
  const { handlePostRequest, reason } = props;
  const [isOpen, setIsOpen] = useState(true);

  const handleChange = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box sx={{ margin: { xs: "1rem auto", sm: 0 } }}>
      <Accordion elevation={0} expanded={isOpen} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: "flex" }}>
            <div>
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                variant="dot"
                color="error"
                sx={{ marginRight: "1rem" }}
              >
                <NotificationsOutlinedIcon />
              </Badge>
            </div>
            <div>
              <Typography>プロフィール修正依頼</Typography>
              <Typography>
                公開に向け、以下の通りプロフィールの修正をお願いします。
              </Typography>
            </div>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                width: "100%",
                margin: ".25rem auto",
                whiteSpace: "pre-wrap"
              }}
            >
              {reason}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handlePostRequest}
              sx={{
                margin: ".5rem auto",
                padding: "0 2rem",
                height: "48px",
                fontWeight: 600
              }}
            >
              再審査の依頼をする
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default JudgeNgAlert;
