import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";
import React from "react";

import CommonLoading from "components/atoms/CommonLoading";
import { checkDeadline } from "utils/date";
import RequestListItem from "../molecules/RequestListItem";
import { teacherRequestsAtom } from "../store";

interface RequestListProps {
  isLoading: boolean;
}

const RequestList: React.FC<RequestListProps> = ({ isLoading }) => {
  const theme = useTheme();
  const teacherRequests = useAtomValue(teacherRequestsAtom);

  return (
    <Box
      component="section"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
          backgroundColor: theme.palette.common.white,
          width: "100%"
        }}
      >
        <Box sx={{ display: { xs: "none", sm: "block" }, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Typography fontWeight="bold" sx={{ flex: 16 }}>
              依頼元の学校と先生
            </Typography>
            <Typography
              fontWeight="bold"
              align="center"
              sx={{
                flex: 4,
                width: "100%"
              }}
            >
              ステータス
            </Typography>
            <Typography
              fontWeight="bold"
              align="center"
              sx={{
                flex: 3,
                width: "100%"
              }}
            >
              期限
            </Typography>
          </Box>
          <Divider sx={{ width: "100%" }} />
        </Box>
        {!isLoading ? (
          teacherRequests && teacherRequests.data.length ? (
            teacherRequests.data.map((request, index) => {
              const { limit } = checkDeadline(request.details.accept_dead_at);

              return (
                <React.Fragment key={index}>
                  <RequestListItem
                    id={request.details.id}
                    schoolName={request.send_user.school_name}
                    schoolTeacherName={request.send_user.name}
                    schoolTeacherImage={request.send_user.image.url}
                    isCertification={request.send_user.is_match_accepted}
                    limit={limit}
                    step={request.details.step}
                  />
                  <Divider sx={{ width: "100%" }} />
                </React.Fragment>
              );
            })
          ) : (
            <Typography color={theme.palette.sub.main}>
              依頼がありません。
            </Typography>
          )
        ) : (
          <CommonLoading />
        )}
      </Box>
    </Box>
  );
};

export default RequestList;
