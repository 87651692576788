import { atom } from "jotai";

import { Methods } from "api/api/v1/teachers/_teacherId@string/lesson_requests";

export type kindOfTeacherRequestsType =
  | "requesting"
  | "only_request"
  | "denied_or_canceled"
  | "accepted";

export const teacherRequestsAtom = atom<Methods["get"]["resBody"] | null>(null);

export const kindOfTeacherRequestsAtom =
  atom<kindOfTeacherRequestsType>("only_request");
