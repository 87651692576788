import * as yup from "yup";

import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId@string";

const replyRequestFormSchema: yup.ObjectSchema<Methods["put"]["reqBody"]> = yup
  .object()
  .shape({
    reply_comment: yup
      .string()
      .required("必須の項目です")
      .test(
        "is-not-empty-or-whitespace",
        "必須の項目です",
        (value) => value.trim().length > 0
      ),
    is_accepted: yup.boolean().required("必須の項目です"),
    // FIXME: ここもis_acceptedにstring型が入る事故が常態化しているため変換
    possible_date_id: yup
      .number()
      .when("is_accepted", (isAccept, schema) =>
        String(isAccept) === "true"
          ? schema.required("必須の項目です")
          : schema.notRequired()
      ),
    canceled_at: yup.string().optional()
  });

export default replyRequestFormSchema;
