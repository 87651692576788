import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import StepperProgressWithLabel from "components/atoms/StepperProgressWithLabel";

interface StepDisplayProps {
  step: number;
  steps: { title: string }[];
}

const StepDisplay: React.FC<StepDisplayProps> = ({ step, steps }) => {
  const currentStepTitle = steps[step - 1].title;
  const nextStepTitle = steps[step] ? steps[step].title : "";
  const totalSteps = Object.keys(steps).length;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        alignItems: "center"
      }}
    >
      <StepperProgressWithLabel step={step} totalSteps={totalSteps} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        <Typography fontWeight="bold">{currentStepTitle}</Typography>
        {nextStepTitle && (
          <Typography color={theme.palette.sub.main} fontSize=".9rem">
            次のステップ：{nextStepTitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default StepDisplay;
