import {
  DescriptionOutlined,
  PersonOutlineOutlined
} from "@mui/icons-material";
import { Box, Typography, Rating } from "@mui/material";

interface RatingShowProps {
  rating: number;
  title: string;
  reason?: string;
  icon?: "teacher" | "lesson";
}

const RatingShow: React.FC<RatingShowProps> = ({
  rating,
  title,
  reason,
  icon
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "8px"
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center"
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {icon === "teacher" ? (
          <PersonOutlineOutlined
            sx={{
              height: "16px",
              width: "16px",
              mr: "4px"
            }}
          />
        ) : (
          <DescriptionOutlined
            sx={{
              height: "16px",
              width: "16px",
              mr: "4px"
            }}
          />
        )}
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: "bold", lineHeight: "1" }}
        >
          {title}
        </Typography>
      </Box>
      <Rating
        value={rating}
        readOnly
        size="small"
        sx={{
          m: " 0 8px",
          "& .MuiRating-icon": {
            m: 0
          }
        }}
      />
    </Box>
    {reason && <Typography>{reason}</Typography>}
  </Box>
);

export default RatingShow;
