import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";

import type React from "react";
import { CommonDialogProps } from "types/commonDialogProps";
import CommonDialog from "components/molecules/CommonDialog";
import Button from "components/atoms/mui/Button";

type EarlyCloseModalProps = {
  earlyClose: () => void;
} & CommonDialogProps;

const EarlyCloseModal: React.FC<EarlyCloseModalProps> = (props) => {
  const { earlyClose, onClose, isOpen } = props;
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleEarlyClose = async () => {
    earlyClose();
    onClose();
  };

  return (
    <CommonDialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem"
        }}
      >
        <Typography align="center" fontWeight="bold">
          期限前ですが、募集を終了しますか？
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: { xs: "column-reverse", sm: "row" }
          }}
        >
          <Button
            variant={isMobile ? "text" : "outlined"}
            onClick={onClose}
            fullWidth
          >
            戻る
          </Button>
          <Button variant="contained" onClick={handleEarlyClose} fullWidth>
            募集を終了する
          </Button>
        </Box>
      </Box>
    </CommonDialog>
  );
};

export default EarlyCloseModal;
