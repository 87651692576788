import { atom } from "jotai";

import { Methods } from "api/api/v1/teachers/_teacherId@string/lesson_recruitments";

export type kindOfTeacherRecruitmentsType =
  | ""
  | "waiting_for_result"
  | "adopted"
  | "denied_or_canceled";

export const teacherRecruitmentsAtom = atom<Methods["get"]["resBody"] | null>(
  null
);
export const kindOfTeacherRecruitmentsAtom =
  atom<kindOfTeacherRecruitmentsType>("");
