import dayjs from "libs/dayjs";

const newFormDefaultValues = {
  // 一週間後の正午
  accept_dead_at: dayjs()
    .add(7, "day")
    .set("hour", 12)
    .set("minute", 0)
    .format(),
  // 二週間後の正午
  possible_dates: [
    dayjs().add(14, "day").set("hour", 12).set("minute", 0).format()
  ]
};

export default newFormDefaultValues;
