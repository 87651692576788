import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import Button from "components/atoms/mui/Button";
import { useHistory } from "react-router-dom";
import { useAtomValue } from "jotai";
import { createdRecruitmentIdAtom } from "../store";

const Step03: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const createdRecruitmentId = useAtomValue(createdRecruitmentIdAtom);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Box sx={{ padding: { xs: "0 1.5rem", sm: 0 } }}>
        <Typography variant="h2">募集を開始しました</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          backgroundColor: theme.palette.common.white,
          padding: "1.5rem"
        }}
      >
        <Typography>
          募集内容の作成を完了し、複業先生へ向けて募集を開始しました。
          <br />
          この依頼に応募があると、登録されているメールにお知らせします。
          <br />
          締め切り期限になりましたら、募集詳細から採用する複業先生を選択してください。
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            alignItems: "center"
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{
              width: "12rem"
            }}
            onClick={() =>
              history.push(
                `/schools/mypage/recruitments/${createdRecruitmentId}`
              )
            }
          >
            募集詳細ページへ
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "12rem"
            }}
            onClick={() => history.push("/schools/mypage")}
          >
            マイページへ
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Step03;
