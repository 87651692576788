import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useAtom } from "jotai";

import type React from "react";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { useFormContext } from "react-hook-form";
import { createCandidateStepAtom, recruitmentAtom } from "../store";

const BottomButtonGroup: React.FC = () => {
  const {
    watch,
    formState: { isValid }
  } = useFormContext();
  const [step, setStep] = useAtom(createCandidateStepAtom);
  const [recruitment] = useAtom(recruitmentAtom);

  const getIsClickable = () => {
    if (
      [
        "canceled",
        "closed",
        "no_candidate",
        "early_closed",
        "candidate_select",
        "accepted",
        "early_accepted"
      ].includes(recruitment?.step || "")
    )
      return false;

    const proposedDateIds = watch("proposed_date_ids");
    switch (step) {
      case 1:
        return proposedDateIds && proposedDateIds.length > 0;
      case 2:
        return isValid;
      default:
        return false;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", sm: "flex-end" },
        gap: "1rem",
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      {step !== 1 && (
        <Box
          sx={{
            width: { xs: "100%", sm: "210px" }
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setStep(step - 1)}
            fullWidth
          >
            戻る
          </Button>
        </Box>
      )}
      <Box
        sx={{
          width: { xs: "100%", sm: "210px" }
        }}
      >
        {step === 3 ? (
          <FormSubmitButton label="応募する" fullWidth disabled={false} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!getIsClickable()}
            onClick={() => setStep(step + 1)}
          >
            次へ
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BottomButtonGroup;
