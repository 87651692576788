import { useAtomValue } from "jotai";

import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// MEMO: moleculesだが依頼情報の表示を機能として持つため、例外的にatomと紐付けて再利用

import SchoolCertificationBadge from "assets/SchoolCertificationBadge.png";

import type React from "react";
import { schoolTeacherAtom } from "../store";

const SchoolInfoBlock01: React.FC = () => {
  const theme = useTheme();
  const schoolTeacher = useAtomValue(schoolTeacherAtom);

  return (
    <Grid container alignItems="center">
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        {schoolTeacher?.image.url !== "no_image" ? (
          <Box
            component="img"
            src={schoolTeacher?.image.url}
            alt={`${schoolTeacher?.name} avatar`}
            sx={{
              width: { xs: "90px", sm: "120px" },
              height: { xs: "90px", sm: "120px" },
              objectFit: "cover",
              borderRadius: 4,
              display: "block"
            }}
          />
        ) : (
          <Avatar
            alt={schoolTeacher?.name}
            src={schoolTeacher?.image.url}
            sx={{
              width: { xs: "90px", sm: "120px" },
              height: { xs: "90px", sm: "120px" },
              fontSize: "36px",
              margin: ".5rem auto"
            }}
          />
        )}
      </Grid>
      <Grid item sx={{ margin: "0 1rem" }}>
        <Typography fontSize=".8rem" color={theme.palette.sub.main}>
          {schoolTeacher?.school_name}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography fontWeight="bold">{schoolTeacher?.name}</Typography>
          {schoolTeacher?.is_match_accepted && (
            <Box
              component="img"
              src={SchoolCertificationBadge}
              alt="認定校"
              sx={{
                display: "block",
                margin: "0 .5rem",
                // MEMO: 解像度を合わせるための調整
                width: "80px"
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SchoolInfoBlock01;
