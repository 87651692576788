import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useAtom } from "jotai";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";

import Loading from "components/atoms/Loading";
import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";
import type { routerIdType } from "types/routerParamsType";
import WatingForRequestJudgement from "./organisms/WatingForRequestJudgement";
import TeacherInfoSection from "./organisms/TeacherInfoSection";
import TeacherInfoGuid from "./molecules/TeacherInfoGuid";
import RequestFellThrough from "./organisms/RequestFellThrough";
import LessonHold from "./organisms/LessonHold";

import useShowPageApi from "./useApi";

import { detailsAtom } from "./store";

const Show: React.FC = () => {
  const theme = useTheme();
  const [details] = useAtom(detailsAtom);
  const { id } = useParams<routerIdType>();

  const { getRequestIsLoading, requestCancel } = useShowPageApi(id);

  const teacherGuildInfoRef = useRef<HTMLDivElement>(null);

  const getStepContents = (step: string) => {
    switch (step) {
      case "canceled":
      case "denied":
      case "dead":
        return <RequestFellThrough />;
      case "lesson_request":
        return <WatingForRequestJudgement requestCancel={requestCancel} />;
      case "lesson_hold":
        return <LessonHold />;
      default:
        return <div />;
    }
  };

  return (
    <CommonLayout>
      <Meta title="複業先生｜授業依頼" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
          padding: { sm: 0, lg: "1.5rem 0" }
        }}
      >
        {!getRequestIsLoading ? (
          <Container maxWidth="lg" sx={{ padding: { xs: 0 } }}>
            <Box
              sx={{
                display: "flex",
                margin: "0 auto",
                width: { sm: "100%", md: "90%" },
                gap: "1.5rem",
                flexDirection: { xs: "column", md: "row" }
              }}
            >
              <Box
                sx={{ display: { xs: "none", md: "block" }, width: "270px" }}
              >
                <TeacherInfoSection />
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <TeacherInfoGuid TeacherInfoSpRef={teacherGuildInfoRef} />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    overflow: "hidden",
                    padding: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem"
                  }}
                >
                  {getStepContents(details?.step || "")}
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    marginBottom: { xs: "1.5rem", lg: 0 }
                  }}
                  ref={teacherGuildInfoRef}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      margin: "1rem auto",
                      width: "100%",
                      padding: "0 1.5rem"
                    }}
                  >
                    案件情報
                  </Typography>
                  <TeacherInfoSection />
                </Box>
              </Box>
            </Box>
          </Container>
        ) : (
          <Loading headerOnly />
        )}
      </Box>
    </CommonLayout>
  );
};

export default Show;
