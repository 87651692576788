import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import { paginationData } from "store/MasterData";

import type React from "react";
import { commonRecruitmentsAtom } from "../store";

const calculateDisplayText = (pagination: {
  count: number;
  current: number;
  limit_value: number;
}) => {
  const startItem = (pagination.current - 1) * pagination.limit_value + 1;
  const endItem = Math.min(
    pagination.count,
    pagination.current * pagination.limit_value
  );
  return `全${pagination.count}件中 ${startItem} - ${endItem}件を表示中`;
};

const RecruitmentsCount: React.FC = () => {
  const pagination = useAtomValue(paginationData);
  const commonRecruitments = useAtomValue(commonRecruitmentsAtom);
  const theme = useTheme();

  if (!pagination) {
    return (
      <Box sx={{ width: "100%" }}>
        <Typography color={theme.palette.sub.main}>
          全{commonRecruitments?.data_count}件
        </Typography>
      </Box>
    );
  }

  const displayText = calculateDisplayText(pagination);

  return (
    <Box sx={{ width: "100%" }}>
      {pagination?.count !== 0 && (
        <Typography color={theme.palette.sub.main}>{displayText}</Typography>
      )}
    </Box>
  );
};

export default RecruitmentsCount;
