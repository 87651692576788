import Box from "@mui/material/Box";

import FirstViewBackground from "assets/schoolLp/FirstViewBackground.png";
import FirstViewContents from "features/root/lp/school/molecules/FirstViewContents";
import FirstViewImage from "features/root/lp/school/molecules/FirstViewImage";

import type React from "react";

const FirstView: React.FC = () => (
  <Box
    component="section"
    sx={{
      width: "100%",
      height: { xs: "auto", md: "calc(60vw - 120px)" },
      maxHeight: { xs: "none", md: "80vh" },
      overflow: "hidden",
      backgroundImage: `url(${FirstViewBackground})`,
      backgroundSize: "cover",
      position: { xs: "static", md: "relative" },
      padding: { xs: "2rem 0 0", md: "0" }
    }}
  >
    <Box
      sx={{
        position: { xs: "static", md: "absolute" },
        top: { xs: "", md: "calc(18vw - 120px)" },
        left: { xs: "", md: "calc(20vw - 100px)" }
      }}
    >
      <FirstViewContents />
    </Box>
    <Box
      sx={{
        position: { xs: "static", md: "absolute" },
        top: { xs: "", md: "calc(8vw - 60px)" },
        right: { xs: "", md: "-8%" },
        width: { xs: "100%", md: "60vw" },
        maxWidth: "960px"
      }}
    >
      <FirstViewImage />
    </Box>
  </Box>
);

export default FirstView;
