import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type React from "react";
import AnswerResult from "../molecules/AnswerResult";

import AnswerField from "../molecules/AnswerField";

type PartProps = {
  isResult?: boolean;
};

const Part1: React.FC<PartProps> = (props) => {
  const { isResult } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography fontSize="1.2rem" fontWeight="bold">
        ①授業の打ち合わせでは、
      </Typography>
      <Typography sx={{ lineHeight: "2rem !important" }}>
        <span>
          学校の先生方とは、授業を一緒に創るパートナーとしてお互いの信頼関係を築くことが重要です。
        </span>
        <br />
        <span>
          自己紹介の際には、複業先生のこれまでのキャリア、今やってること、
        </span>
        {isResult ? (
          <AnswerResult index={0} />
        ) : (
          <AnswerField index={0} wordCount={3} />
        )}
        <span>を伝えましょう。</span>
        <br />
        <span>
          学校側からの授業の内容についての説明を通じて、授業を受けるのはどんな児童・生徒なのか、先生自身がどんな
        </span>
        {isResult ? (
          <AnswerResult index={1} />
        ) : (
          <AnswerField index={1} wordCount={2} />
        )}
        <span>を感じているのかについて理解を深めましょう。</span>
        <br />
        <span>複業先生側からの質疑では、学校側からの依頼内容に対しての</span>
        {isResult ? (
          <AnswerResult index={2} />
        ) : (
          <AnswerField index={2} wordCount={3} />
        )}
        <span>
          を解消し、アイデアを率直に伝えることで、より具体的な授業の流れを共に組み立てましょう。
        </span>
        <br />
        <span>授業の流れが決まったら、当日までに準備することや、当日の</span>
        {isResult ? (
          <AnswerResult index={3} />
        ) : (
          <AnswerField index={3} wordCount={2} />
        )}
        <span>時間、緊急時の連絡方法を確認しましょう。</span>
      </Typography>
    </Box>
  );
};

export default Part1;
