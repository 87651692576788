import { atom } from "jotai";

import { Methods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lesson_requests/_lessonRequestId@string";

export const detailsAtom = atom<
  Methods["get"]["resBody"]["details"] | undefined
>(undefined);

export const teacherAtom = atom<
  Methods["get"]["resBody"]["teacher"] | undefined
>(undefined);

export const supporterAtom = atom<
  Methods["get"]["resBody"]["supporter"] | undefined
>(undefined);
