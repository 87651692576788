import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import Meta from "components/organisms/Meta";
import Step01 from "features/recruitments/new/organisms/Step01";
import Step02 from "features/recruitments/new/organisms/Step02";
import Step03 from "features/recruitments/new/organisms/Step03";
import CommonLayout from "components/layouts/CommonLayout";

import type React from "react";
import useNewRecruitmentPageApi from "features/recruitments/new/useApi";
import { FormProvider } from "react-hook-form";
import { stepAtom } from "features/recruitments/new/store";
import { useAtom } from "jotai";
import StepDisplay from "components/molecules/StepDisplay";
import { NewRecruitmentSteps } from "features/recruitments/new/constants/newRecuruitementSteps";

const New: React.FC = () => {
  const { newRecruitmentForm, createRecruitment } = useNewRecruitmentPageApi();
  const theme = useTheme();
  const [step] = useAtom(stepAtom);

  const getStepContent = () => {
    switch (step) {
      case 1:
        return <Step01 />;
      case 2:
        return <Step02 />;
      case 3:
        return <Step03 />;
      default:
        return null;
    }
  };

  return (
    <>
      <Meta
        title="複業先生|募集新規作成"
        description="実現したい授業について詳細に記述し、複業先生を募集しましょう。"
      />
      <CommonLayout>
        <div>
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              padding: "1.5rem"
            }}
          >
            <Typography variant="h1">募集内容を作成</Typography>
            <StepDisplay step={step} steps={NewRecruitmentSteps} />
          </Container>
        </div>
        <FormProvider {...newRecruitmentForm}>
          <Box
            sx={{
              backgroundColor: theme.palette.backgroundColor?.main,
              flexGrow: 1
            }}
            component="form"
            onSubmit={newRecruitmentForm.handleSubmit(createRecruitment)}
          >
            <Container
              maxWidth="lg"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                padding: { xs: "1.5rem 0", sm: "1.5rem" }
              }}
            >
              {getStepContent()}
            </Container>
          </Box>
        </FormProvider>
      </CommonLayout>
    </>
  );
};

export default New;
