import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useAtomValue } from "jotai";

import RatingDisplay from "features/teachers/id/atoms/RatingDisplay";
import type React from "react";
import { ratingTargetAtom } from "../store";

type RatingBoxType = {
  totalRating: number;
  ratingCount: number;
};

const RatingBox: React.FC<RatingBoxType> = (props) => {
  const { totalRating, ratingCount } = props;
  const theme = useTheme();
  const ratingTarget = useAtomValue(ratingTargetAtom);

  const handleClick = () => {
    if (ratingTarget) {
      ratingTarget.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0 .75rem",
        padding: ".25rem 1.2rem",
        backgroundColor: theme.palette.backgroundColor?.main,
        borderRadius: "4px",
        cursor: "pointer",
        margin: { xs: "1rem auto", sm: "inherit" }
      }}
      onClick={handleClick}
    >
      <RatingDisplay rating={totalRating} />
      <Typography fontSize="1.1rem" fontWeight="bold">
        {totalRating.toFixed(1) || "-"}
      </Typography>
      <Typography color={theme.palette.sub.main}>
        評価：{ratingCount || "-"}件
      </Typography>
      <ArrowForwardIosIcon
        sx={{
          display: "block",
          color: theme.palette.sub.main,
          fontSize: ".9rem"
        }}
      />
    </Box>
  );
};

export default RatingBox;
