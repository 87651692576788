import React from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useLocation } from "react-router-dom";

import CommonSidebarItem from "components/molecules/CommonSidebarItem";
import SidebarAccountInfo from "components/molecules/SidebarAccountInfo";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import HomeIcon from "assets/common/sidebar/selected/home.svg";
import RequestIcon from "assets/common/sidebar/selected/teachers/request.svg";
import RecruitmentIcon from "assets/common/sidebar/selected/teachers/recruitment.svg";
import LessonIcon from "assets/common/sidebar/selected/lesson.svg";
import ProfileEditIcon from "assets/common/sidebar/selected/edit.svg";
import SettingIcon from "assets/common/sidebar/selected/settings.svg";
import HomeEmptyIcon from "assets/common/sidebar/unselected/home.svg";
import RequestEmptyIcon from "assets/common/sidebar/unselected/teachers/request.svg";
import RecruitmentEmptyIcon from "assets/common/sidebar/unselected/teachers/recruitment.svg";
import LessonEmptyIcon from "assets/common/sidebar/unselected/lesson.svg";
import ProfileEditEmptyIcon from "assets/common/sidebar/unselected/edit.svg";
import SettingEmptyIcon from "assets/common/sidebar/unselected/settings.svg";
import useTeacherAuthApi from "hooks/useTeacherAuthApi";

const Sidebar: React.FC = () => {
  const theme = useTheme();
  const { logout } = useTeacherAuthApi();
  const location = useLocation();

  const isSelected = (link: string) => {
    const currentLink = location.pathname + location.search;
    return currentLink === link;
  };

  return (
    <Box
      component="section"
      sx={{
        minWidth: "248px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          padding: "1rem"
        }}
      >
        <SidebarAccountInfo type="teacher" />
        <List>
          <CommonSidebarItem
            text="マイページTOP"
            Icon={
              <img
                src={isSelected("/teachers/mypage") ? HomeIcon : HomeEmptyIcon}
                alt="マイページTOP"
              />
            }
            link="/teachers/mypage"
            selected={isSelected("/teachers/mypage")}
          />
          <CommonSidebarItem
            text="授業依頼"
            Icon={
              <img
                src={
                  isSelected("/teachers/mypage/requests")
                    ? RequestIcon
                    : RequestEmptyIcon
                }
                alt="授業依頼"
              />
            }
            link="/teachers/mypage/requests"
            selected={isSelected("/teachers/mypage/requests")}
          />
          <CommonSidebarItem
            text="応募済み"
            Icon={
              <img
                src={
                  isSelected("/teachers/mypage/recruitments")
                    ? RecruitmentIcon
                    : RecruitmentEmptyIcon
                }
                alt="応募済み"
              />
            }
            link="/teachers/mypage/recruitments"
            selected={isSelected("/teachers/mypage/recruitments")}
          />
          <CommonSidebarItem
            text="授業進行管理"
            Icon={
              <img
                src={
                  isSelected("/teachers/mypage/lessons")
                    ? LessonIcon
                    : LessonEmptyIcon
                }
                alt="授業進行管理"
              />
            }
            link="/teachers/mypage/lessons"
            selected={isSelected("/teachers/mypage/lessons")}
          />
          <Divider />
          <CommonSidebarItem
            text="プロフィール編集"
            Icon={
              <img
                src={
                  isSelected("/teachers/profile/edit")
                    ? ProfileEditIcon
                    : ProfileEditEmptyIcon
                }
                alt="プロフィール編集"
              />
            }
            link="/teachers/profile/edit"
            selected={isSelected("/teachers/profile/edit")}
          />
          <CommonSidebarItem
            text="アカウント設定"
            Icon={
              <img
                src={
                  isSelected("/teachers/mypage/settings")
                    ? SettingIcon
                    : SettingEmptyIcon
                }
                alt="アカウント設定"
              />
            }
            link="/teachers/mypage/settings"
            selected={isSelected("/teachers/mypage/settings")}
          />
          <CommonSidebarItem
            text="ログアウト"
            Icon={
              <ExitToAppIcon sx={{ color: theme.palette.textColor?.main }} />
            }
            onClick={logout}
          />
        </List>
      </Box>
    </Box>
  );
};

export default Sidebar;
