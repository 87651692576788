import { atom } from "jotai";

import { Methods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lessons";

export type kindOfSchoolLessonsType =
  | ""
  | "lesson_end"
  | "preparation"
  | "canceled";

export const schoolLessonsAtom = atom<Methods["get"]["resBody"] | null>(null);

export const kindOfSchoolLessonsAtom = atom<kindOfSchoolLessonsType>("");
