import React from "react";
import { useAtom } from "jotai";
import { useFormContext, useWatch } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import AddIcon from "@mui/icons-material/Add";

import FormLabel from "components/atoms/FormLabel";

import { useDialog } from "hooks/useDialog";

import {
  selectedSchoolAtom,
  isExceptionSchoolAtom
} from "store/MasterSchoolStore";

import { masterSchoolType } from "types/masterDataTypes";
import AddSchoolModal from "../organisms/AddSchoolModal";

const MasterSchoolField: React.FC = () => {
  const theme = useTheme();
  const addSchoolModal = useDialog();
  const { setValue } = useFormContext();

  // FIXME: この辺りの処理はもっとスマートに書けるはず。ExpectionSchoolFieldsとも部分的に被っている。
  const schoolName = useWatch({
    name: "school_name"
  });
  const schoolDivisionId = useWatch({
    name: "school_division_id"
  });
  const schoolTypeId = useWatch({
    name: "school_type_id"
  });
  const prefectureId = useWatch({
    name: "school_prefecture_id"
  });
  const schoolAddress = useWatch({
    name: "school_address"
  });
  const schoolCode = useWatch({
    name: "school_code"
  });

  const hasFullInfo =
    !!schoolName &&
    !!schoolDivisionId &&
    !!schoolTypeId &&
    !!prefectureId &&
    !!schoolAddress;

  const [isExceptionSchool, setIsExceptionSchool] = useAtom(
    isExceptionSchoolAtom
  );
  const [selectedSchool, setSelectedSchool] = useAtom(selectedSchoolAtom);

  const handleSelect = (value: masterSchoolType) => {
    setValue("school_code", value.school_code, {
      shouldValidate: true,
      shouldDirty: true
    });
    // MEMO: 表示用のStoreに学校マスター経由のデータを丸ごとセット
    setSelectedSchool(value);

    addSchoolModal.onClose();
  };

  const handleExpectionChange = () => {
    const preIsExpectionSchool = isExceptionSchool;

    if (preIsExpectionSchool) {
      setValue("school_name", "");
      setValue("school_division_id", 0);
      setValue("school_type_id", 0);
      setValue("school_prefecture_id", 0);
      setValue("school_address", "");
    } else {
      setSelectedSchool(null);
      setValue("school_code", "", {
        shouldValidate: true,
        shouldDirty: true
      });
    }

    setIsExceptionSchool(!isExceptionSchool);
  };

  return (
    <>
      <Box sx={{ margin: "1.5rem auto", width: "100%" }}>
        <FormLabel labelName="学校名" isRequired />
        <FormControl
          variant="outlined"
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          {!isExceptionSchool && !selectedSchool && (
            <Typography color={theme.palette.sub.main}>
              所属している学校を選択してください
            </Typography>
          )}
          {!isExceptionSchool && selectedSchool && (
            <Typography fontSize="1.1rem" fontWeight="bold">
              {selectedSchool.name}
            </Typography>
          )}
          {isExceptionSchool && schoolName && (
            <div>
              <Typography fontSize="1.1rem" fontWeight="bold">
                {schoolName}
              </Typography>
              {!hasFullInfo && (
                <Typography color={theme.palette.sub.main}>
                  必要な学校情報が不足しています。
                </Typography>
              )}
            </div>
          )}
          {isExceptionSchool && !schoolName && (
            <Typography color={theme.palette.sub.main}>
              所属している学校の情報を入力してください
            </Typography>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={addSchoolModal.onOpen}
            disableElevation
            startIcon={<AddIcon />}
          >
            {isExceptionSchool
              ? "学校情報を編集する"
              : schoolCode
                ? "学校を変更する"
                : "学校を選択する"}
          </Button>
        </FormControl>
      </Box>
      {addSchoolModal.isOpen && (
        <AddSchoolModal
          isOpen={addSchoolModal.isOpen}
          onClose={addSchoolModal.onClose}
          isException={isExceptionSchool}
          handleSelect={handleSelect}
          handleExpectionChange={handleExpectionChange}
        />
      )}
    </>
  );
};

export default MasterSchoolField;
