import Grid from "@mui/material/Grid";
import Meta from "components/organisms/Meta";

import type React from "react";
import PasswordResetRequestFormBlock from "components/organisms/auth/PasswordResetRequestFormBlock";
import { Box } from "@mui/material";
import { FormProvider } from "react-hook-form";
import useSchoolAuthApi from "hooks/useSchoolAuthApi";
import AuthLayout from "../../../../components/layouts/AuthLayout";

const ForgotPassword: React.FC = () => {
  const { forgotPassword, forgotPasswordForm } = useSchoolAuthApi();

  return (
    <AuthLayout>
      <Meta />
      <Grid
        container
        sx={{
          height: { xs: "auto", lg: "100vh" }
        }}
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sx={{
            height: { xs: "auto", lg: "100%" }
          }}
        >
          <FormProvider {...forgotPasswordForm}>
            <Box
              component="form"
              onSubmit={forgotPasswordForm.handleSubmit(forgotPassword)}
              sx={{
                height: { xs: "auto", lg: "100%" }
              }}
            >
              <PasswordResetRequestFormBlock variant="school" />
            </Box>
          </FormProvider>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default ForgotPassword;
