import React from "react";
import { useAtomValue } from "jotai";
import { useHistory } from "react-router-dom";

import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import useTheme from "@mui/material/styles/useTheme";
import Button from "components/atoms/mui/Button";

import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

const GoBackButton: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);

  const goBack = () => {
    if (window.history.length > 1) {
      history.goBack();
    } else {
      if (isLoggedIn) {
        history.push("/teachers/mypage");
        return;
      }

      if (schoolIsLoggedIn) {
        history.push("/schools/mypage");
        return;
      }

      history.push("/");
    }
  };

  return (
    <Button
      variant="text"
      color="secondary"
      size="small"
      startIcon={
        <ArrowBack
          sx={{
            color: theme.palette.link?.main,
            height: "1rem",
            width: "1rem"
          }}
        />
      }
      onClick={goBack}
    >
      <Typography
        color={theme.palette.link?.main}
        fontSize=".8rem"
        sx={{
          textDecoration: "underline"
        }}
      >
        前の画面へ戻る
      </Typography>
    </Button>
  );
};

export default GoBackButton;
