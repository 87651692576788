import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import React, { useRef } from "react";

import CommonLoading from "components/atoms/CommonLoading";
import { useFormContext } from "react-hook-form";
import BottomButtonGroup from "../molecules/BottomButtonGroup";
import Step01 from "./Step01";
import Step02 from "./Step02";
import Step03 from "./Step03";
import { createCandidateStepAtom } from "../store";

import { CreateRecruitmentSteps } from "../constants/createRecruitmentSteps";
import StepDisplay from "../molecules/StepDisplay";
import CandidateCreateFormOverlay from "../atoms/CandidateCreateFormOverlay";

const CandidateCreateForm: React.FC = () => {
  const {
    formState: { isSubmitting }
  } = useFormContext();
  const theme = useTheme();
  const formSectionRef = useRef<HTMLFormElement>(null);
  const [createCandidateStep] = useAtom(createCandidateStepAtom);

  const getStepComponent = (step: number) => {
    switch (step) {
      case 1:
        return <Step01 />;
      case 2:
        return <Step02 />;
      case 3:
        return <Step03 />;
      default:
        return null;
    }
  };

  return (
    <Box
      component="section"
      ref={formSectionRef}
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: `2px solid ${theme.palette.primary.darker}`,
        borderRadius: "8px",
        backgroundColor: theme.palette.common.white,
        padding: "1rem"
      }}
    >
      <Typography variant="h3">この授業に応募する</Typography>
      <StepDisplay step={createCandidateStep} steps={CreateRecruitmentSteps} />
      {getStepComponent(createCandidateStep)}
      <BottomButtonGroup />
      <CandidateCreateFormOverlay />
      {isSubmitting && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.5)",
            borderRadius: "8px"
          }}
        >
          <CommonLoading />
        </Box>
      )}
    </Box>
  );
};

export default CandidateCreateForm;
