import Typography from "@mui/material/Typography";
import React from "react";
import useTheme from "@mui/material/styles/useTheme";

type SectionLabelProps = {
  isRequired?: boolean;
};

const SectionLabel: React.FC<SectionLabelProps> = (props) => {
  const { isRequired } = props;
  const theme = useTheme();

  return (
    <Typography
      sx={{
        backgroundColor: isRequired
          ? theme.palette.primary.main
          : theme.palette.muted?.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
        borderRadius: "4px",
        padding: "2px 4px",
        width: { xs: "30px", sm: "36px" },
        fontSize: { xs: ".6rem", sm: ".75rem" },
        lineHeight: "1.5 !important",
        flexShrink: 0
      }}
      align="center"
      variant="body1"
      component="label"
    >
      {isRequired ? "必須" : "任意"}
    </Typography>
  );
};

export default SectionLabel;
