import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import type React from "react";
import { useDialog } from "hooks/useDialog";
import { postTeacherVideo } from "../types/teacherVideoApiTypes";
import ProfileVideoRecorderDialog from "../organisms/ProfileVideoRecorderDialog";

type SelfVideoFieldProps = {
  postTeacherVideo: postTeacherVideo;
};

const SelfVideoField: React.FC<SelfVideoFieldProps> = (props) => {
  const { postTeacherVideo } = props;
  const theme = useTheme();
  const videoDialog = useDialog();

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.backgroundColor?.main,
          padding: ".5rem",
          borderRadius: "4px",
          border: `1px solid ${theme.palette.border?.main}`
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "180px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: ".5rem",
            backgroundColor: theme.palette.common.white,
            borderRadius: "4px"
          }}
        >
          <IconButton onClick={videoDialog.onOpen}>
            <AddCircleOutlineIcon color="primary" fontSize="large" />
          </IconButton>
          <Button color="primary" onClick={videoDialog.onOpen}>
            動画を撮影
          </Button>
        </Box>
      </Box>
      <ProfileVideoRecorderDialog
        isOpen={videoDialog.isOpen}
        onClose={videoDialog.onClose}
        onSubmit={postTeacherVideo}
      />
    </>
  );
};

export default SelfVideoField;
