import React from "react";
import { useAtomValue } from "jotai";
import Container from "@mui/material/Container";
import CommonLayout from "components/layouts/CommonLayout";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import useTheme from "@mui/material/styles/useTheme";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";

import Meta from "components/organisms/Meta";

import Loading from "components/atoms/Loading";
import { useParams } from "react-router-dom";

import Chat from "components/organisms/lessons/Chat";
import SetMeetingPlaceBlock from "components/organisms/lessons/SetMeetingPlaceBlock";
import BeforeMeetingBlock from "components/organisms/lessons/BeforeMeetingBlock";
import LessonPlaceBlock from "components/organisms/lessons/LessonPlaceBlock";
import CustomTabSection from "components/organisms/CustomTabSection";
import useShowPageApi from "./useApi";

import LessonFlowSection from "./organisms/LessonFlowSection";
import LessonInfoSection from "./organisms/LessonInfoSection";
import SelectCandidateBlock from "./organisms/SelectCandidateBlock";
import WaitingForMeetingSuggetionBlock from "./organisms/WaitingForMeetingSuggetionBlock";
import PreparationList from "./organisms/PreparationList";
import LessonCompleteReportBlock from "./organisms/LessonCompleteReportBlock";
import LessonCompletedBlock from "./organisms/LessonCompletedBlock";

import { detailsAtom, lessonRequestCommentsAtom } from "./store";

type paramsTypes = {
  id: string;
};

const Show: React.FC = () => {
  const details = useAtomValue(detailsAtom);
  const lessonRequestComments = useAtomValue(lessonRequestCommentsAtom);
  const { id } = useParams<paramsTypes>();
  const theme = useTheme();

  const {
    getLessonIsLoading,
    postRequestComments,
    deleteRequestComments,
    deleteUnreadMessages,
    postSelectMeetingPossibleDatetimes,
    updateLesson
  } = useShowPageApi(id);

  const getTaskBlock = () => {
    switch (details?.step) {
      case "suggest_meeting_date":
        return <WaitingForMeetingSuggetionBlock />;
      case "select_meeting_date":
        return (
          <SelectCandidateBlock
            postSelectMeetingPossibleDatetimes={
              postSelectMeetingPossibleDatetimes
            }
          />
        );
      case "adjust_meeting_place":
        return <SetMeetingPlaceBlock updateLesson={updateLesson} />;
      case "before_meeting":
        return <BeforeMeetingBlock />;
      case "preparation":
        if (
          (details?.is_online && !details?.lesson_url) ||
          (!details?.is_online && !details?.place)
        ) {
          return <LessonPlaceBlock updateLesson={updateLesson} />;
        }
        return <PreparationList />;
      case "lesson_end":
        return <LessonCompleteReportBlock updateLesson={updateLesson} />;
      case "completed":
        return <LessonCompletedBlock />;
      default:
        return null;
    }
  };

  return (
    <>
      <Meta
        title="複業先生 | 授業詳細・進行管理"
        description="成立した授業の詳細情報と進行状況を確認するページです。"
      />
      <CommonLayout>
        {getLessonIsLoading ? (
          <Loading />
        ) : (
          <>
            <LessonInfoSection />
            <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
              <Container
                maxWidth="lg"
                sx={{
                  width: "100%",
                  padding: { xs: 0, sm: "1.5rem" }
                }}
              >
                <CustomTabSection
                  displayIn="pc"
                  tabs={[
                    {
                      Icon: <EditNoteOutlinedIcon />,
                      labelName: "授業の進行管理",
                      tabPanelContents: (
                        <>
                          {getTaskBlock()}
                          <LessonFlowSection updateLesson={updateLesson} />
                        </>
                      )
                    },
                    {
                      Icon: (
                        <Badge
                          badgeContent={
                            lessonRequestComments?.unread_messages_count
                          }
                          color="primary"
                        >
                          <ChatOutlinedIcon />
                        </Badge>
                      ),
                      labelName: "学校とのメッセージ",
                      tabPanelContents: (
                        <Chat
                          postRequestComments={postRequestComments}
                          deleteRequestComments={deleteRequestComments}
                          deleteUnreadMessages={deleteUnreadMessages}
                          variant="teacher"
                        />
                      )
                    }
                  ]}
                />
                <Box
                  sx={{
                    display: { xs: "block", md: "none" }
                  }}
                >
                  <Chat
                    postRequestComments={postRequestComments}
                    deleteRequestComments={deleteRequestComments}
                    deleteUnreadMessages={deleteUnreadMessages}
                    variant="teacher"
                  />
                </Box>
              </Container>
            </Box>
          </>
        )}
      </CommonLayout>
    </>
  );
};

export default Show;
