import React from "react";

import Typography from "@mui/material/Typography";

import QuestionnairesLayout from "components/layouts/QuestionnairesLayout";
import Meta from "components/organisms/Meta";
import ThanksAnswerBlock from "../../studentBeforeQuestionnaires/complete/organisms/ThanksAnswerBlock";

const SendedBeforeQuestionnaires: React.FC = () => (
  <QuestionnairesLayout title="授業後アンケート">
    <Meta
      title="複業先生 | 授業後アンケートへの回答完了"
      description="お疲れ様でした。授業についてのアンケートは正常に送信されました。"
    />
    <ThanksAnswerBlock
      title="授業後アンケート、お疲れ様でした！"
      descriptions={
        <Typography textAlign="center">
          <Typography textAlign="center">
            アンケートを先生に提出しました。
          </Typography>
          <Typography textAlign="center">
            今回の授業を今後の生活に活かしてください！
          </Typography>
        </Typography>
      }
    />
  </QuestionnairesLayout>
);

export default SendedBeforeQuestionnaires;
