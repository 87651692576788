import Container from "@mui/material/Container";

import Meta from "components/organisms/Meta";
import Conclusion from "features/root/index/organisms/Conclusion";
import FirstView from "features/root/index/organisms/FirstView";
import Intro from "features/root/index/organisms/Intro";
import Procedures from "features/root/index/organisms/Procedures";
import TeachersBox from "features/root/index/organisms/TeachersBox";
import CommonLayout from "components/layouts/CommonLayout";

import type React from "react";
import useAlertToNew from "hooks/useAlertToNew";

const Home: React.VFC = () => {
  useAlertToNew();
  return (
    <CommonLayout>
      <Meta />
      <FirstView />
      <Container>
        <Intro />
      </Container>
      <TeachersBox />
      <Procedures />
      <Conclusion />
    </CommonLayout>
  );
};

export default Home;
