import React from "react";
import { useHistory } from "react-router-dom";
import { useAtomValue } from "jotai";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

import RequestInfoBlock from "components/molecules/RequestInfoBlock";
import SchoolInfoBlock from "features/teachers/mypage/requests/id/molecules/SchoolInfoBlock01";

import Button from "components/atoms/mui/Button";
import { detailsAtom } from "../store";

const LessonHold: React.FC = () => {
  const details = useAtomValue(detailsAtom);

  const theme = useTheme();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/teachers/mypage/lessons/${details?.lesson_id}`);
  };

  return (
    <Box>
      <Box sx={{ margin: "0 auto 1rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Box>
            <Typography fontWeight="bold" fontSize="1rem">
              授業が成立しました！
            </Typography>
            <Typography>
              学校の先生とやり取りして、授業を進めましょう
            </Typography>
          </Box>
          <Button onClick={handleClick}>授業進行管理画面へ</Button>
        </Box>
        <Box
          sx={{
            borderRadius: 4,
            border: `solid 1px ${theme.palette.border?.main}`,
            overflow: "hidden",
            margin: "1.5rem auto",
            padding: "1.5rem",
            [theme.breakpoints.down("sm")]: {
              padding: "1rem",
              margin: "1rem auto"
            }
          }}
        >
          <SchoolInfoBlock />
        </Box>
        <Box
          sx={{
            borderRadius: 4,
            border: `solid 1px ${theme.palette.border?.main}`,
            overflow: "hidden",
            margin: "1.5rem auto",
            padding: "1.5rem",
            [theme.breakpoints.down("sm")]: {
              padding: "1rem",
              margin: "1rem auto"
            }
          }}
        >
          <RequestInfoBlock />
        </Box>
        {details?.step === "denied" && (
          <Box sx={{ margin: "1.5rem auto" }}>
            <Typography variant="h3">あなたが送信したメッセージ</Typography>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {details.reply_comment}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LessonHold;
