import { EditOutlined } from "@mui/icons-material";
import { Box, Typography, IconButton, useTheme } from "@mui/material";
import Link from "../mui/Link";

export interface EditableTextProps {
  value?: string;
  onChange: () => void;
  url?: string;
  strong?: boolean;
  disabled?: boolean;
}
const EditableText: React.FC<EditableTextProps> = ({
  value,
  onChange,
  url,
  strong,
  disabled
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography
          sx={{
            fontWeight: strong ? "bold" : "normal",
            color: value
              ? theme.palette.text.primary
              : theme.palette.text.disabled
          }}
        >
          {value || "（未定）"}
        </Typography>
        {!disabled && (
          <IconButton onClick={onChange} size="small">
            <EditOutlined
              color="disabled"
              sx={{ height: "16px", width: "16px" }}
            />
          </IconButton>
        )}
      </Box>
      {url && (
        <Link href={url} newTab>
          {url}
        </Link>
      )}
    </Box>
  );
};

export default EditableText;
