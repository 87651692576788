import { ChevronRight, DescriptionOutlined } from "@mui/icons-material";
import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";

export interface DocumentProps {
  title: string;
  caption?: string;
  url?: string;
  path?: string;
}

const Document: React.FC<DocumentProps> = ({ title, caption, url, path }) => {
  const history = useHistory();
  const theme = useTheme();
  return (
    <Box
      component={Paper}
      onClick={() => {
        if (url) {
          window.open(url, "_blank");
        } else if (path) {
          history.push(path);
        }
      }}
      sx={{
        p: "12px 16px",
        display: "flex",
        gap: "16px",
        alignItems: "center",
        cursor: "pointer"
      }}
    >
      <DescriptionOutlined
        sx={{
          height: "30px",
          width: "30px",
          color: theme.palette.text.secondary
        }}
      />
      <Stack sx={{ flex: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
        {caption && <Typography variant="caption">{caption}</Typography>}
      </Stack>
      <ChevronRight sx={{ color: theme.palette.text.secondary }} />
    </Box>
  );
};

export default Document;
