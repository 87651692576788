import { useTheme, useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { useAtom } from "jotai";
import { useRef, useEffect } from "react";

import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";
import type React from "react";
import RatingListItem from "../molecules/RatingListItem";
import ToSignupBlock from "../molecules/ToSignupBlock";
import TotalRatingsBlockSp from "../molecules/TotalRatingBlockSp";
import TotalRatingsBlock from "../molecules/TotalRatingsBlock";
import { ratingAtom, ratingTargetAtom, listRatingAtom } from "../store";

const RatingLists: React.FC = () => {
  const theme = useTheme();
  const underSm = useMediaQuery(theme.breakpoints.down("sm"));
  const targetRef = useRef<HTMLDivElement>(null);
  const [rating] = useAtom(ratingAtom);
  const [listRating] = useAtom(listRatingAtom);
  const [, setRatingTargetRef] = useAtom(ratingTargetAtom);
  const [schoolIsLoggedIn] = useAtom(schoolIsLoggedInAtom);

  useEffect(() => {
    setRatingTargetRef(targetRef.current);
  }, [setRatingTargetRef]);

  return (
    <section ref={targetRef}>
      <Typography
        variant="h3"
        sx={{
          margin: "0 auto .5rem",
          padding: { xs: "0 1rem", sm: "inherit" }
        }}
      >
        評価（{rating?.rating_count || "-"}件）
      </Typography>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          padding: { xs: "1rem", sm: "1.5rem 2.5rem" },
          margin: { xs: "0 auto", sm: "0" }
        }}
      >
        {underSm ? <TotalRatingsBlockSp /> : <TotalRatingsBlock />}
        {!schoolIsLoggedIn ? (
          <ToSignupBlock />
        ) : (
          <div>
            {listRating.map((rating) => {
              const school_type =
                rating.send_school_teacher_school_type === "その他"
                  ? ""
                  : rating.send_school_teacher_school_type;
              const school_division_name =
                rating.send_school_division_name === "その他"
                  ? ""
                  : rating.send_school_division_name;

              const reviewer = `${
                rating.send_school_prefecture_name +
                school_type +
                school_division_name
              }教員`;

              return (
                <>
                  {rating.details.lesson_rating_reason && (
                    <>
                      <Divider sx={{ margin: "1rem auto" }} />
                      <RatingListItem
                        Icon={DescriptionOutlinedIcon}
                        category="授業への評価"
                        rating={Number(rating.details.lesson_rating) / 10}
                        reviewer={reviewer}
                        contents={rating.details.lesson_rating_reason}
                        createAt={rating.details.created_at}
                      />
                    </>
                  )}
                  {rating.details.teacher_rating_reason && (
                    <>
                      <Divider sx={{ margin: "1rem auto" }} />
                      <RatingListItem
                        Icon={PersonIcon}
                        category="講師への評価"
                        rating={Number(rating.details.teacher_rating) / 10}
                        reviewer={reviewer}
                        contents={rating.details.teacher_rating_reason}
                        createAt={rating.details.created_at}
                      />
                    </>
                  )}
                </>
              );
            })}
          </div>
        )}
      </Box>
    </section>
  );
};

export default RatingLists;
