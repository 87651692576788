import { useEffect } from "react";
import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import useQueryParams from "hooks/useQueryParams";
import { useAtom, useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentUserAtom } from "store/AuthStore";
import { paginationData } from "store/MasterData";
import {
  kindOfTeacherRecruitmentsAtom,
  teacherRecruitmentsAtom
} from "./store";

type QueryParamsType = {
  pages?: number;
};

const useMyRecruitmentsPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const { getQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();
  const currentUser = useAtomValue(currentUserAtom);
  const [kindOfRecruitments, setKindOfRecruitments] = useAtom(
    kindOfTeacherRecruitmentsAtom
  );
  const [, setTeacherRecruitments] = useAtom(teacherRecruitmentsAtom);
  const [, setPagination] = useAtom(paginationData);
  const teacherId = currentUser.id.toString();

  const query = getQueryParams(["pages"]);

  useEffect(() => {
    deleteQueryParams("pages");
  }, [kindOfRecruitments]);

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(teacherId)
      .lesson_recruitments,
    {
      query: { status: kindOfRecruitments, pages: query.pages?.toString() },
      onSuccess: (data) => {
        setTeacherRecruitments(data);
        setPagination(data.pagination || null);
      },
      onError: () => {
        addErrorMessage("募集一覧の取得に失敗しました。");
      }
    }
  );

  return { isLoading, kindOfRecruitments, setKindOfRecruitments };
};

export default useMyRecruitmentsPageApi;
