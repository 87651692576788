// React Hook Formで管理しないチップの選択状態を管理するコンポーネント
import Box from "@mui/material/Box";
import Chip from "components/atoms/mui/Chip";

type ChipGroupProps<T> = {
  options: { label: string; value: T }[];
  selectedValue: T;
  onSelect: (value: T) => void;
};

const ChipGroup = <T,>({
  options,
  selectedValue,
  onSelect
}: ChipGroupProps<T>) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      gap: ".5rem",
      flexWrap: "wrap"
    }}
  >
    {options.map((option) => (
      <Chip
        key={option.value as unknown as string} // `T` が `string` でない場合に備えた型キャスト
        label={option.label}
        variant="outlined"
        clickable
        onClick={() => onSelect(option.value)}
        color={selectedValue === option.value ? "primary" : "default"}
      />
    ))}
  </Box>
);

export default ChipGroup;
