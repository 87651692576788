import { useFormContext } from "react-hook-form";

import Box from "@mui/material/Box";
import Button from "components/atoms/mui/Button";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";

import { useAtomValue } from "jotai";

import { suggestKeywordsAtom } from "../store";

const SuggestKeywordList: React.FC = () => {
  const { setValue } = useFormContext();
  const theme = useTheme();
  const suggestKeywords = useAtomValue(suggestKeywordsAtom);

  return (
    <Box display="flex" flexWrap="wrap" gap={1} alignItems="center" marginY={1}>
      <Typography color={theme.palette.sub.main} fontWeight="bold">
        おすすめキーワード：
      </Typography>
      {suggestKeywords?.map((keyword) => (
        <Button
          key={keyword.keyword}
          variant="text"
          onClick={() => setValue("text", keyword.keyword)}
          sx={{
            color: theme.palette.sub.main,
            padding: "0 .5rem",
            fontWeight: "bold",
            textDecoration: "underline",
            borderRadius: "2px"
          }}
        >
          #{keyword.keyword}
        </Button>
      ))}
    </Box>
  );
};

export default SuggestKeywordList;
