function convertToCircleNumber(index: number) {
  // 丸数字の基本Unicode値
  const baseCircleNumber = 0x2460;

  // indexを加えることで、そこから始まる丸数字に対応
  const circleNumber = baseCircleNumber + index;

  // Unicode文字列に変換
  return String.fromCodePoint(circleNumber);
}

export default convertToCircleNumber;
