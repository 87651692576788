import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { Link as RouterLink } from "react-router-dom";

import getPrice from "utils/calcPrice";

import type React from "react";
import useTeacherRankBadge from "hooks/useTeacherRankBadge";
import RatingDisplay from "../atoms/RatingDisplay";

type CandidatesListItemProps = {
  candidateTeacherId: number;
  candidateTeacherRank: string;
  candidateAvatar: string;
  candidateName: string;
  candidateOrganizationName: string;
  candidateTotalRating: number;
  candidateRatingCount: number;
  schoolMinutes: number;
  lessonContents: string;
  appeal: string;
};

const CandidatesListItem: React.FC<CandidatesListItemProps> = (props) => {
  const {
    candidateTeacherId,
    candidateAvatar,
    candidateName,
    candidateTeacherRank,
    candidateOrganizationName,
    candidateTotalRating,
    candidateRatingCount,
    schoolMinutes,
    lessonContents,
    appeal
  } = props;
  const theme = useTheme();
  const rankBadge = useTeacherRankBadge(candidateTeacherRank);
  const price = getPrice(schoolMinutes || 0);

  return (
    <Box
      component={RouterLink}
      to={`/teachers/${candidateTeacherId}?role=candidate_display`}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        textDecoration: "none"
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexDirection: { xs: "column", sm: "row" }
        }}
      >
        <Box
          sx={{
            flex: 1,
            minWidth: "240px",
            width: { xs: "100%", sm: "auto" },
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center"
            }}
          >
            <Avatar
              src={candidateAvatar}
              alt={`${candidateName}のプロフィール画像`}
              sizes="large"
            />
            <div>
              <Typography color={theme.palette.sub.main}>
                {candidateOrganizationName}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem"
                }}
              >
                <Typography fontWeight="bold" fontSize="1rem">
                  {candidateName}
                </Typography>
                <Box
                  component="img"
                  src={rankBadge.src}
                  alt={rankBadge.alt}
                  sx={{ height: "1rem" }}
                />
              </Box>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              backgroundColor: theme.palette.backgroundColor?.main,
              borderRadius: "4px",
              padding: ".5rem 1rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: ".5rem"
              }}
            >
              <RatingDisplay rating={candidateTotalRating / 10} />
              <Typography fontWeight="bold" fontSize="1.2rem">
                {candidateTotalRating / 10}
              </Typography>
            </Box>
            <Typography color={theme.palette.sub.main}>
              {candidateRatingCount}件
            </Typography>
          </Box>
          <Typography fontWeight="bold">
            ¥{price}（{schoolMinutes}分）
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 4,
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Typography fontWeight="bold">こんな授業ができます</Typography>
          <Typography>{lessonContents}</Typography>
          <Typography fontWeight="bold">意気込み</Typography>
          <Typography>{appeal}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CandidatesListItem;
