import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";

import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom, currentSchoolAtom } from "store/SchoolAuthStore";
import type React from "react";
import { teacherInfoAtom, requestModalOpenAtom } from "../store";

type RequestButtonProps = {
  bottom?: boolean;
};

const RequestButton: React.FC<RequestButtonProps> = (props) => {
  const [, setRequestModalIsOpen] = useAtom(requestModalOpenAtom);
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  const [schoolIsLoggedIn] = useAtom(schoolIsLoggedInAtom);
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [teacherInfo] = useAtom(teacherInfoAtom);
  const history = useHistory();
  const theme = useTheme();

  const isGuest = () => {
    if (!isLoggedIn && !schoolIsLoggedIn) {
      return true;
    }
    return false;
  };

  const handleClick = () => {
    if (isGuest()) {
      setRequestModalIsOpen(true);
    } else {
      history.push(`/requests/new/?id=${teacherInfo?.teacher_id}`);
    }
  };

  return (
    <div>
      {!isGuest() && isLoggedIn && props.bottom && (
        <div>
          <Typography
            sx={{ margin: ".5rem auto" }}
            fontSize=".9rem"
            align="center"
          >
            教員アカウントでログインしている時のみ授業依頼できます
          </Typography>
          <Typography
            fontSize=".9rem"
            color={theme.palette.sub.main}
            align="center"
            sx={{ margin: ".5rem auto" }}
          >
            ※現在複業先生としてログインしているため授業依頼をすることはできません
          </Typography>
        </div>
      )}
      {schoolIsLoggedIn && !currentSchool.is_public && props.bottom && (
        <div>
          <Typography align="center" fontSize=".9rem" margin=".5rem auto">
            現在審査中のため授業依頼をすることができません
          </Typography>
        </div>
      )}
      <Button
        color="primary"
        variant="contained"
        disableElevation
        fullWidth
        onClick={handleClick}
        disabled={
          (!isGuest() && isLoggedIn) ||
          (schoolIsLoggedIn && !currentSchool.is_public)
        }
      >
        授業を頼みたい
      </Button>
      {!isGuest() && isLoggedIn && !props.bottom && (
        <div>
          <Typography
            sx={{ margin: ".5rem auto" }}
            fontSize=".9rem"
            align="center"
          >
            教員アカウントでログインしている時のみ授業依頼できます
          </Typography>
          <Typography
            fontSize=".9rem"
            color={theme.palette.sub.main}
            align="center"
            sx={{ margin: ".5rem auto" }}
          >
            ※現在複業先生としてログインしているため授業依頼をすることはできません
          </Typography>
        </div>
      )}
      {schoolIsLoggedIn && !currentSchool.is_public && !props.bottom && (
        <div>
          <Typography align="center" fontSize=".9rem" margin=".5rem auto">
            現在審査中のため授業依頼をすることができません
          </Typography>
        </div>
      )}
    </div>
  );
};

export default RequestButton;
