import React from "react";
import dayjs from "libs/dayjs";

import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import FlowStepContents from "components/molecules/lessons/FlowStepContents";
import { Methods as UpdateLessonMethods } from "api/api/v1/lessons/_lessonId@string";
import Document from "components/atoms/Document";
import { useAtomValue } from "jotai";
import { Box, useTheme } from "@mui/material";
import Link from "components/atoms/mui/Link";
import { FormProvider, useForm } from "react-hook-form";
import FormRating from "components/molecules/FormRating";
import FormTextArea from "components/molecules/FormTextArea";
import { yupResolver } from "@hookform/resolvers/yup";
import LessonPlaceField from "components/molecules/lessons/LessonPlaceField";
import LessonDateField from "components/molecules/lessons/LessonDateField";
import MeetingDateField from "components/molecules/lessons/MeetingDateField";
import MeetingPlaceField from "components/molecules/lessons/MeetingPlaceField";
import RatingShow from "components/molecules/lessons/RatingShow";
import postRatingsSchema, {
  postRatingsSchemaType
} from "../constants/postRatingsSchema";
import { detailsAtom, ratingsAtom, activeStepAtom } from "../store";

// MEMO: activeStepNumはpropsで受け取り、値取得はuseEditPageApiで行う
type LessonFlowSectionProps = {
  updateLesson: (data: UpdateLessonMethods["patch"]["reqBody"]) => void;
  postRating: (data: postRatingsSchemaType) => void;
};

const LessonFlowSection: React.FC<LessonFlowSectionProps> = (props) => {
  const { updateLesson, postRating } = props;
  const details = useAtomValue(detailsAtom);
  const rating = useAtomValue(ratingsAtom);
  const activeStep = useAtomValue(activeStepAtom);
  const theme = useTheme();

  const isAfterLesson = !!(
    details?.lesson_completed_at || dayjs().isAfter(details?.exact_start_at)
  );
  const beforeQuestionnaireUrl = `/lessons/${details?.id}/student_before_questionnaires?unique_id=${details?.unique_id}`;
  const afterQuestionnaireUrl = `/lessons/${details?.id}/student_after_questionnaires?unique_id=${details?.unique_id}`;

  const lessonText = `以下の観点で評価をお願いします。
①依頼目的に沿った内容や授業構成・形態であり、児童・生徒にとってわかりやすかった。
②教材（スライド・事前課題等）や場作り・発問等は児童・生徒たちの学びを助長させた。
③児童・生徒に対して新たな気づき・発見や学びを与えた。`;

  const teacherText = `以下の観点で評価をお願いします。
①複業先生は児童・生徒の実態に合った適切な声量、表情、話し方、接し方をしていた。
②全体（授業計画〜授業実施）を通して複業先生の熱意が感じられた。
③事前連絡や準備（チャットの送受信、資料の授受等）、当日の授業（遅刻等がない）を滞りなく行っていた。 `;

  const ratingForm = useForm({
    mode: "onChange",
    resolver: yupResolver(postRatingsSchema),
    defaultValues: {
      lesson_rating: 30,
      teacher_rating: 30
    }
  });

  const onSubmit = ratingForm.handleSubmit((data) => {
    postRating(data);
  });

  return (
    <section>
      <Typography
        variant="h2"
        sx={{
          padding: { xs: "0 1rem", sm: 0 }
        }}
      >
        授業実施までの流れ
      </Typography>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{
          padding: "1rem 0",
          "& .MuiStepConnector-root .MuiStepConnector-line": {
            borderWidth: { xs: 0 }
          },
          "& .MuiStepConnector-root:nth-child(1)": {
            display: "none"
          }
        }}
      >
        <FlowStepContents
          index={0}
          title="打ち合わせを行う"
          stepContents={[
            {
              name: "打ち合わせ日時",
              content: (
                <MeetingDateField
                  meeting_start_at={details?.meeting_start_at}
                  onSubmit={updateLesson}
                />
              )
            },
            {
              name: "打ち合わせ場所",
              content: (
                <MeetingPlaceField
                  is_meeting_online={details?.is_meeting_online}
                  meeting_url={details?.meeting_url}
                  meeting_place={details?.meeting_place}
                  onSubmit={updateLesson}
                />
              )
            },
            {
              name: "打ち合わせ資料",
              content: (
                <>
                  <Document
                    title="授業準備シート"
                    caption="いつでも編集できます"
                    path={`/schools/mypage/lessons/${details?.id}/agenda`}
                  />
                  <Typography mt={1}>
                    授業の時間配分の記入率{" "}
                    <strong>{details?.timeline_input_rate}%</strong>
                  </Typography>
                  <Box
                    sx={{
                      width: 240,
                      height: 6,
                      backgroundColor: theme.palette.sub.lighter,
                      position: "relative"
                    }}
                  >
                    <Box
                      sx={{
                        width: `${details?.timeline_input_rate}%`,
                        height: "100%",
                        backgroundColor: theme.palette.primary.main,
                        position: "absolute",
                        top: 0,
                        left: 0
                      }}
                    />
                  </Box>
                </>
              )
            }
          ]}
        />
        <FlowStepContents
          index={1}
          title="生徒向けに授業前アンケートを実施"
          stepContents={[
            {
              name: "アンケートURL",
              content: (
                <>
                  <Typography>
                    生徒向けの授業前アンケートを実施しましょう。アンケートを実施することで授業後にレポートが生成されます。
                  </Typography>
                  <Link href={beforeQuestionnaireUrl} newTab>
                    授業前アンケート
                  </Link>
                </>
              )
            }
          ]}
          hasLabel
        />
        <FlowStepContents
          index={2}
          title="授業を実施する"
          stepContents={[
            {
              name: "授業日程",
              content: (
                <LessonDateField
                  exact_start_at={details?.exact_start_at}
                  onSubmit={updateLesson}
                  disabled={isAfterLesson}
                />
              )
            },
            {
              name: "授業場所",
              content: (
                <LessonPlaceField
                  is_online={details?.is_online}
                  place={details?.place}
                  lesson_url={details?.lesson_url}
                  disabled={isAfterLesson}
                  onSubmit={updateLesson}
                />
              )
            }
          ]}
        />
        <FlowStepContents
          index={3}
          title="生徒向けに授業後アンケートを実施"
          stepContents={[
            {
              name: "アンケートURL",
              content: (
                <>
                  <Typography>
                    生徒向けの授業後アンケートを実施しましょう。アンケートを実施することで授業後にレポートが生成されます。
                  </Typography>
                  <Link href={afterQuestionnaireUrl} newTab>
                    授業後アンケート
                  </Link>
                  <Typography component="p" fontSize="0.8rem">
                    ※授業アンケートは、1件目の回答が授業実施日を除いた3営業日以内（土日祝日を除く平日）にあれば、その日からさらに3営業日以内は回答が可能です。それ以降の回答は集計対象外となりますのでご注意ください。また、授業実施日からその日を除いて3営業日以内に1件も回答がない場合、授業後アンケートは見送られたものと判断され、結果は出力されません。運営上の都合で問題がある場合は、担当者までご連絡ください。
                  </Typography>
                </>
              )
            },
            {
              name: "学びレポート",
              content:
                details?.lesson_reports && details.lesson_reports.length > 0 ? (
                  details?.lesson_reports.map((report) => (
                    <Box>
                      <Link key={report.id} href={report.url} newTab>
                        {report.file_name}
                      </Link>
                    </Box>
                  ))
                ) : (
                  <Typography>学びレポートがありません。</Typography>
                )
            }
          ]}
          hasLabel
        />
        <div id="rating-section-wrapper">
          <FormProvider {...ratingForm}>
            <Box component="form" mt={3} onSubmit={onSubmit}>
              <FlowStepContents
                index={4}
                title="複業先生の評価をする"
                disabledText={
                  details?.lesson_completed_at
                    ? undefined
                    : "授業終了後に評価をお願いします。"
                }
                stepContents={[
                  {
                    name: "授業の満足度",
                    content: rating ? (
                      <RatingShow
                        rating={rating.lesson_rating / 10}
                        title="授業の満足度"
                        reason={rating.lesson_rating_reason}
                        icon="lesson"
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem"
                        }}
                      >
                        <FormRating
                          name="lesson_rating"
                          label="授業の満足度"
                          supplementalText={lessonText}
                          isRequired
                          defaultValue={3}
                        />
                        <FormTextArea
                          name="lesson_rating_reason"
                          label="その理由を教えてください"
                          placeholder="例：子供たちが楽しみながら学べる授業内容でした。"
                          supplementalText="記入いただいた評価は複業先生のページのコメント欄に掲載されます。複業先生を選ぶ際に参考となるような理由をお願いします。"
                          isRequired
                        />
                      </Box>
                    )
                  },
                  {
                    name: "講師への評価",
                    content: rating ? (
                      <RatingShow
                        rating={rating.teacher_rating / 10}
                        title="講師への評価"
                        reason={rating.teacher_rating_reason}
                        icon="teacher"
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem"
                        }}
                      >
                        <FormRating
                          name="teacher_rating"
                          label="講師への評価"
                          supplementalText={teacherText}
                          isRequired
                          defaultValue={3}
                        />
                        <FormTextArea
                          name="teacher_rating_reason"
                          label="その理由を教えてください"
                          placeholder="例：細やかな事前準備にご協力いただきました。"
                          supplementalText="記入いただいた評価は複業先生のページのコメント欄に掲載されます。複業先生を選ぶ際に参考となるような理由をお願いします。"
                          isRequired
                        />
                      </Box>
                    )
                  }
                ]}
                isLast
                submitText={rating ? undefined : "評価を確定する"}
              />
            </Box>
          </FormProvider>
        </div>
      </Stepper>
    </section>
  );
};

export default LessonFlowSection;
