import { useAtomValue } from "jotai";
import dayjs from "libs/dayjs";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "components/atoms/mui/Button";

import { Methods } from "api/api/v1/teacher_profiles";
import { temporarilySavedProfileAtom, temporarilySavedAtAtom } from "../store";

type TemporarilySaveBlockProps = {
  saveProfileTemporarily: () => void;
  isDirty: boolean;
  values: Methods["post"]["reqBody"];
};

const TemporarilySaveBlock: React.FC<TemporarilySaveBlockProps> = (props) => {
  const { saveProfileTemporarily, isDirty, values } = props;
  const temporarilySavedProfile = useAtomValue(temporarilySavedProfileAtom);
  const temporarySavedAt = useAtomValue(temporarilySavedAtAtom);

  const isSame =
    JSON.stringify(values) === JSON.stringify(temporarilySavedProfile);

  return (
    <Stack sx={{ padding: "8px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography fontSize=".8rem" color="text.secondary">
          {temporarySavedAt
            ? `${dayjs(temporarySavedAt).format("M月D日 HH時mm分")} 保存`
            : "未保存"}
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => saveProfileTemporarily()}
          disabled={!isDirty || isSame}
          sx={{
            fontSize: "0.8rem",
            padding: "2px 4px"
          }}
        >
          {isDirty && !isSame
            ? "一時保存する"
            : temporarilySavedProfile
              ? "一時保存済"
              : "未保存"}
        </Button>
      </Box>
      <Typography fontSize=".8rem" color="text.secondary">
        データはお使いのブラウザのみ保存されます。
      </Typography>
    </Stack>
  );
};

export default TemporarilySaveBlock;
