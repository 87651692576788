import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ConfigType } from "dayjs";

import type React from "react";

import getStatusLabel from "services/recruitments/GetStatusLabelForTeacher";
import { formatDateString } from "utils/date";
import { shortSentences } from "utils/text";

type RecruitmentItemProps = {
  id: number;
  title: string;
  deadLine: ConfigType;
  status: string;
  candidateStatus: string;
};

const RecruitmentItem: React.FC<RecruitmentItemProps> = (props) => {
  const { id, title, deadLine, status, candidateStatus } = props;
  const shortTitle = shortSentences(title, 20);

  return (
    <Link
      href={`/recruitments/${id}`}
      sx={{
        width: "100%",
        textDecoration: "none"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "3.5rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".25rem"
          }}
        >
          <Typography fontWeight="bold">{shortTitle}</Typography>
          <Typography fontSize=".8rem">
            締め切り：
            {status === "now_recruting" ? formatDateString(deadLine) : "-----"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: ".5rem"
          }}
        >
          {getStatusLabel(status, candidateStatus)}
        </Box>
      </Box>
    </Link>
  );
};

export default RecruitmentItem;
