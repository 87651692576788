import React, { useRef } from "react";
import { useAtomValue } from "jotai";
import { useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import CommonLayout from "components/layouts/CommonLayout";
import Meta from "components/organisms/Meta";
import Loading from "components/atoms/Loading";
import type { routerIdType } from "types/routerParamsType";
import SchoolInfoBlock from "./organisms/SchoolInfoBlock";
import SchoolInfoGuid from "./organisms/SchoolInfoGuid";
import RequestFellThrough from "./organisms/RequestFellThrough";
import RequestJudgment from "./organisms/RequestJudgment";
import LessonHold from "./organisms/LessonHold";

import { detailsAtom } from "./store";

import useShowPageApi from "./useApi";

const Show: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams<routerIdType>();

  const details = useAtomValue(detailsAtom);

  const { getRequestIsLoading, replyRequest } = useShowPageApi(id);

  const schoolGuildInfoRef = useRef<HTMLDivElement>(null);

  const getStepContents = (step: string) => {
    switch (step) {
      case "canceled":
      case "denied":
      case "dead":
        return <RequestFellThrough />;
      case "lesson_request":
        return <RequestJudgment replyRequest={replyRequest} />;
      case "lesson_hold":
        return <LessonHold />;
      default:
        return <div />;
    }
  };

  return (
    <CommonLayout>
      <Meta title="複業先生｜授業依頼" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
          padding: { xs: 0, sm: "1.5rem 0" }
        }}
      >
        {!getRequestIsLoading ? (
          <Container
            maxWidth="lg"
            sx={{
              padding: { xs: 0, sm: "0 24px" }
            }}
          >
            <Box
              sx={{
                display: "flex",
                margin: "0 auto",
                width: { xs: "100%", md: "90%" },
                gap: "1.5rem",
                flexDirection: { xs: "column", md: "row" }
              }}
            >
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Box sx={{ width: "270px" }}>
                  <SchoolInfoBlock />
                </Box>
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <Box>
                  <SchoolInfoGuid SchoolInfoSpRef={schoolGuildInfoRef} />
                </Box>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem"
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#ffffff",
                      overflow: "hidden",
                      padding: "1.5rem"
                    }}
                  >
                    {getStepContents(details?.step || "")}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                  marginBottom: { xs: "1.5rem", sm: 0 }
                }}
                ref={schoolGuildInfoRef}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="h2"
                    sx={{
                      margin: "1rem auto",
                      width: "100%",
                      padding: "0 1.5rem"
                    }}
                  >
                    案件情報
                  </Typography>
                  <SchoolInfoBlock />
                </Box>
              </Box>
            </Box>
          </Container>
        ) : (
          <Loading headerOnly />
        )}
      </Box>
    </CommonLayout>
  );
};

export default Show;
