import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import type React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import dayjs from "libs/dayjs";
import FormDatetime from "components/molecules/FormDatetime";
import FieldsFrame from "./FieldsFrame";

const PossibleDatesFields: React.FC = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "proposed_dates"
  });

  const twoweeksLater = dayjs().add(14, "day").set("hour", 12).set("minute", 0);

  return (
    <FieldsFrame title="授業日程" info="授業日程は2週間先から設定できます">
      {fields.map((field, index) => (
        <FormDatetime
          key={field.id}
          label={`第${index + 1}希望`}
          name={`proposed_dates[${index}]`}
          isRequired={index === 0}
          minDate={twoweeksLater}
        />
        // <PossibleDateField key={field.id} index={index} />
      ))}
      <Box
        sx={{
          display: "flex",
          gap: "1rem"
        }}
      >
        {fields.length < 3 && (
          <Button
            variant="text"
            color="secondary"
            onClick={() =>
              // MEMO: useFieldArrayとの兼ね合いでtoDate()をつける
              append(
                dayjs().add(14, "day").set("hour", 12).set("minute", 0).format()
              )
            }
            sx={{
              // MEMO: 別途theme.tsで定義した色を指定したほうがいいかも
              color: "#0093C4",
              fontWeight: 600,
              padding: "0.25rem 1rem",
              "&:hover": {
                backgroundColor: "rgba(0,147,196,0.1)"
              }
            }}
          >
            候補日時を追加する
          </Button>
        )}
        {fields.length > 1 && (
          <Button
            variant="text"
            color="inherit"
            onClick={() => remove(fields.length - 1)}
            sx={{
              // MEMO: 別途theme.tsで定義した色を指定したほうがいいかも
              color: "#EE1D00",
              fontWeight: 600,
              padding: "0.25rem 1rem",
              "&:hover": {
                backgroundColor: "rgba(238,29,0,0.1)"
              }
            }}
          >
            候補日時を削除する
          </Button>
        )}
      </Box>
    </FieldsFrame>
  );
};

export default PossibleDatesFields;
