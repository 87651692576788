// MEMO: layoutに入れるルートも全然ある

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type React from "react";
import IconPopover from "components/atoms/IconPopover";

type FieldsFrameProps = {
  title?: string;
  info?: string;
  children: React.ReactNode;
};

const FieldsFrame: React.FC<FieldsFrameProps> = (props) => {
  const { title, info, children } = props;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        alignItems: "flex-start",
        width: "100%",
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      <Box
        sx={{
          width: "12rem",
          display: "flex",
          gap: "1rem",
          alignItems: "center"
        }}
      >
        {title && <Typography fontWeight="bold">{title}</Typography>}
        {info && <IconPopover text={info} variant="info" />}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%"
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FieldsFrame;
