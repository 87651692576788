import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtom } from "jotai";
import aspida from "libs/aspida";
import { useHistory } from "react-router-dom";
import { teacherVideosShowAtom } from "store/TeacherVideosStore";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import {
  teacherInfoAtom,
  ratingAtom,
  listRatingAtom,
  teacherLessonRequestsAtom
} from "./store";

const useShowPageApi = (id: string) => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const history = useHistory();

  const [, setTeacherInfo] = useAtom(teacherInfoAtom);
  const [, setRating] = useAtom(ratingAtom);
  const [, setListRating] = useAtom(listRatingAtom);
  const [, setTeacherLessonRequests] = useAtom(teacherLessonRequestsAtom);
  const [, setTeacherVideos] = useAtom(teacherVideosShowAtom);
  const [, setCurrentSchool] = useAtom(currentSchoolAtom);

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(id),
    {
      onSuccess: (data) => {
        if (data) {
          setTeacherVideos(data.videos);
          setTeacherInfo(data);
          setRating(data.rating);
          setListRating(data.list_rating.data);
          setTeacherLessonRequests(data.lesson_requests);
        } else {
          history.push("/not-found");
        }
      },
      onError: () => {
        addErrorMessage("複業先生情報の取得に失敗しました");
      }
    }
  );

  // MEMO: このページでは例外的に学校のログイン状態を見る
  useAspidaSWR(aspida(headers).api.v1.school_teachers.login_school_teacher, {
    onSuccess: (data) => {
      setCurrentSchool(data);
    }
  });

  return { isLoading };
};

export default useShowPageApi;
