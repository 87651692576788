import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import BottomButtonGroup from "components/molecules/BottomButtonGroup";
import { useFormContext } from "react-hook-form";
import Button from "components/atoms/mui/Button";
import StepDisplay from "components/molecules/StepDisplay";
import { useAtom } from "jotai";
import { useDialog } from "hooks/useDialog";
import SelectCandidateAlert from "../atoms/SelectCandidateAlert";
import Step01 from "./Step01";
import Step02 from "./Step02";
import Step03 from "./Step03";
import Step04 from "./Step04";
import CancelModal from "./CancelModal";
import { SelectCandidateSteps } from "../constants/selectCandidateSteps";
import { selectCandidateFormStepAtom } from "../store";

type SelectCandidateProps = {
  cancelRecruitment: () => void;
};

const SelectCandidate: React.FC<SelectCandidateProps> = (props) => {
  const { cancelRecruitment } = props;
  const theme = useTheme();
  const {
    watch,
    formState: { isValid }
  } = useFormContext();
  const [step, setStep] = useAtom(selectCandidateFormStepAtom);
  const cancelModal = useDialog();

  const getIsNextClickable = () => {
    switch (step) {
      case 1:
        return (
          !!watch("candidate_id") && !!watch("proposed_date_id") && isValid
        );
      case 2:
        return !!watch("accepted_comment") && isValid;
      default:
        return false;
    }
  };

  const getStepContent = () => {
    switch (step) {
      case 1:
        return <Step01 />;
      case 2:
        return <Step02 />;
      case 3:
        return <Step03 />;
      case 4:
        return <Step04 />;
      default:
        return <div />;
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        <SelectCandidateAlert />
        <Box
          component="section"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            backgroundColor: theme.palette.common.white,
            padding: "1rem",
            borderRadius: "8px",
            border: `2px solid ${theme.palette.primary.darker}`
          }}
        >
          <Typography variant="h3">
            今回採用する複業先生は決まりましたか？
          </Typography>
          <StepDisplay step={step} steps={SelectCandidateSteps} />
          {getStepContent()}
          {step < 4 && (
            <BottomButtonGroup
              handleBack={() => setStep(step - 1)}
              handleNext={() => setStep(step + 1)}
              activeStep={step - 1}
              isNextClickable={getIsNextClickable()}
              lastStep={2}
              submitText="候補者を確定する"
            />
          )}
        </Box>
        <Button
          variant="text"
          color="secondary"
          disableElevation
          onClick={cancelModal.onOpen}
          sx={{ alignSelf: "flex-end" }}
        >
          募集自体をキャンセルしたい場合はこちら
        </Button>
      </Box>
      <CancelModal
        isOpen={cancelModal.isOpen}
        onClose={cancelModal.onClose}
        cancelRecruitment={cancelRecruitment}
      />
    </>
  );
};

export default SelectCandidate;
