import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useAtomValue } from "jotai";

import {
  formatDate,
  formatDateTimeStringWithDay,
  checkDeadline
} from "utils/date";
import useIsNew from "hooks/useIsNew";

import dayjs from "libs/dayjs";
import type React from "react";

import NewRecruitmentLabel from "components/atoms/recruitmentLabels/NewRecruitmentLabel";
import ImportantInfoCard from "components/molecules/ImportantInfoCard";
import { recruitmentAtom, schoolTeacherAtom } from "../store";

const RecruitmentMainInfo: React.FC = () => {
  const recruitment = useAtomValue(recruitmentAtom);
  const schoolTeacher = useAtomValue(schoolTeacherAtom);

  const { limit, dead } = checkDeadline(recruitment?.recruitment_dead_at || "");
  const isNew = useIsNew(dayjs(recruitment?.updated_at));

  return (
    <section>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Typography>
          掲載日：{formatDate(recruitment?.created_at || "")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem"
          }}
        >
          {isNew && <NewRecruitmentLabel />}
          <Typography variant="h1">{recruitment?.title}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            flexDirection: { xs: "column", sm: "row" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem"
            }}
          >
            <Typography>{schoolTeacher?.school_name}</Typography>
            {recruitment?.proposed_dates.data.map((proposedDate, index) => (
              <Typography key={index}>
                <Typography component="span" fontWeight="bold">
                  第{index + 1}希望：
                </Typography>
                <span>
                  {formatDateTimeStringWithDay(proposedDate.start_at_datetime)}{" "}
                  ({recruitment?.school_minutes}分間)
                </span>
              </Typography>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <ImportantInfoCard
              Icon={DescriptionOutlinedIcon}
              title="実施方法"
              mainInfo={recruitment?.is_online ? "オンライン" : "対面"}
              subInfo={
                recruitment?.is_online
                  ? undefined
                  : schoolTeacher?.school_prefecture_name || ""
              }
            />
            <ImportantInfoCard
              Icon={PermIdentityIcon}
              title="対象生徒"
              mainInfo={recruitment?.school_grade_name || ""}
              subInfo={`${recruitment?.student_number}人`}
            />
            <ImportantInfoCard
              Icon={AccessTimeIcon}
              title="募集期限"
              mainInfo={
                recruitment?.step === "now_recruting" && !dead
                  ? `あと${limit}日`
                  : "募集終了"
              }
              subInfo={
                recruitment?.step === "now_recruting"
                  ? formatDate(recruitment?.recruitment_dead_at || "")
                  : "------"
              }
            />
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default RecruitmentMainInfo;
