import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import { useEffect } from "react";

import {
  lessonOptionsAtom,
  selectedOptionIdsAtom
} from "features/requests/new/store";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import type React from "react";

const MustSupportBox: React.FC = () => {
  const [, setSelectedOptions] = useAtom(selectedOptionIdsAtom);
  const [lessonOptions] = useAtom(lessonOptionsAtom);
  const [currentSchool] = useAtom(currentSchoolAtom);
  const theme = useTheme();

  useEffect(() => {
    if (!currentSchool.is_match_accepted) {
      setSelectedOptions([3, 4, 5]);
    }
  }, [currentSchool.is_match_accepted, setSelectedOptions]);

  return (
    <Box sx={{ margin: "1.5rem auto" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography fontWeight="bold">
            初回の授業は事務局が無料でサポートし、基本料金のみとなります。
          </Typography>
          <Typography>
            {" "}
            2回目以降サポートの有無は選択となり、サポート有の場合基本料金とは別に費用が発生します。
          </Typography>
        </Grid>
        {lessonOptions.map((lessonOption, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sx={{
              margin: "1rem auto",
              padding: "1rem",
              border: `1px solid ${theme.palette.muted?.main}`,
              borderRadius: "4px"
            }}
          >
            <Typography fontWeight="bold">{lessonOption.name}：0円</Typography>
            <Typography fontWeight="bold">
              {lessonOption.description}
            </Typography>
            <Typography sx={{ fontSize: { xs: { xs: ".9rem", sm: "1rem" } } }}>
              ※ 2回目以降は{lessonOption.amount}円の費用が発生します。
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MustSupportBox;
