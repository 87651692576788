export const schoolClassesOptions = [
  { label: "1組", value: "1組" },
  { label: "2組", value: "2組" },
  { label: "3組", value: "3組" },
  { label: "4組", value: "4組" },
  { label: "5組", value: "5組" },
  { label: "6組", value: "6組" },
  { label: "7組", value: "7組" },
  { label: "8組", value: "8組" },
  { label: "9組", value: "9組" },
  { label: "10組", value: "10組" },
  { label: "その他①", value: "その他①" },
  { label: "その他②", value: "その他②" },
  { label: "その他③", value: "その他③" }
];

export const isSurveyOptions = [
  { label: "調べた", value: "調べた" },
  { label: "特に調べていない", value: "特に調べていない" }
];

export const howMuchfunOptions = [
  { label: "楽しみにしていた", value: "楽しみにしていた" },
  { label: "少し楽しみにしていた", value: "少し楽しみにしていた" },
  { label: "どちらでもない", value: "どちらでもない" },
  {
    label: "あまり楽しみにしていなかった",
    value: "あまり楽しみにしていなかった"
  },
  { label: "楽しみにしていなかった", value: "楽しみにしていなかった" }
];

export const studentIDNumberOptions = () => {
  const options = [];
  for (let index = 1; index < 50; index++) {
    options.push({ label: index.toString(), value: index.toString() });
  }
  options.push({ label: "51以上", value: "51以上" });

  return options;
};

export const learnWantedToKnowOptions = [
  { label: "知ることができた", value: "知ることができた" },
  { label: "少し知ることができた", value: "少し知ることができた" },
  { label: "どちらともいえない", value: "どちらともいえない" },
  {
    label: "あまり知ることができなかった",
    value: "あまり知ることができなかった"
  },
  { label: "知ることができなかった", value: "知ることができなかった" }
];

export const isUsefulOptions = [
  { label: "役に立ちそうだと思った", value: "役に立ちそうだと思った" },
  { label: "少し役に立ちそうだと思った", value: "少し役に立ちそうだと思った" },
  { label: "どちらともいえない", value: "どちらともいえない" },
  {
    label: "あまり役に立ちそうだと思わなかった",
    value: "あまり役に立ちそうだと思わなかった"
  },
  {
    label: "役に立ちそうだと思わなかった",
    value: "役に立ちそうだと思わなかった"
  }
];
