import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import EditableText from "components/atoms/lessons/EditableText";
import Button from "components/atoms/mui/Button";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import FormTextField from "components/molecules/FormTextField";
import { isOnlineOptions } from "constants/selectOptions";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { isFalse, isTrue } from "utils/checkBool";

interface LessonPlaceFieldProps {
  is_online?: boolean;
  place?: string;
  lesson_url?: string;
  disabled?: boolean;
  onSubmit: (data: {
    is_online: boolean;
    place?: string;
    lesson_url?: string;
  }) => void;
}

const LessonPlaceField: React.FC<LessonPlaceFieldProps> = ({
  is_online,
  place,
  lesson_url,
  disabled,
  onSubmit
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const form = useForm({
    resolver: yupResolver(
      yup.object().shape({
        is_online: yup.boolean().required("授業方法を選択してください"),
        lesson_url: yup.string().url("正しいURLを入力してください").optional(),
        place: yup.string().optional()
      })
    ),
    mode: "all",
    shouldUnregister: true
  });
  const { reset, watch } = form;
  const isOnline = isTrue(watch("is_online"));
  const isNotOnline = isFalse(watch("is_online"));

  const isOnlineString = `${is_online ? "オンライン" : "対面"}で実施 ${!is_online && place ? `(${place})` : ""}`;

  useEffect(() => {
    reset({
      is_online,
      place,
      lesson_url
    });
    setIsEditing(false);
  }, [is_online, place, lesson_url]);

  return isEditing ? (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
        sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
      >
        <FormRadioGroup
          name="is_online"
          label=""
          options={isOnlineOptions}
          noLabel
        />
        {isOnline && (
          <FormTextField
            name="lesson_url"
            label=""
            placeholder="URLを入力してください"
            fullWidth
            noLabelAndTitle
          />
        )}
        {isNotOnline && (
          <FormTextField
            name="place"
            label=""
            placeholder="授業場所を入力してください"
            fullWidth
            noLabelAndTitle
          />
        )}

        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button
            variant="outlined"
            onClick={() => setIsEditing(false)}
            sx={{ padding: "0 24px", height: "32px" }}
          >
            キャンセル
          </Button>
          <FormSubmitButton
            label="保存する"
            sx={{ padding: "0 24px", height: "32px" }}
          />
        </Box>
      </Box>
    </FormProvider>
  ) : (
    <EditableText
      value={is_online === undefined ? undefined : isOnlineString}
      url={is_online ? lesson_url : ""}
      onChange={() => setIsEditing(true)}
      disabled={disabled}
    />
  );
};

export default LessonPlaceField;
