import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ConfigType } from "dayjs";

import LessonCompleteLabel from "components/atoms/lessonLabels/LessonCompleteLabel";
import LessonFinishLabel from "components/atoms/lessonLabels/LessonFinishLabel";
import LessonPreparationLabel from "components/atoms/lessonLabels/LessonPreparationLabel";

import type React from "react";
import { formatDateTimeStringWithDay } from "utils/date";
import LessonSchoolData from "./LessonSchoolData";

type LessonItemProps = {
  id: number;
  exactStartAt: ConfigType;
  schoolMinutes: number;
  step: string;
  schoolName: string;
  schoolImage: string;
  isCertified: boolean;
};

const LessonItem: React.FC<LessonItemProps> = (props) => {
  const {
    id,
    exactStartAt,
    schoolMinutes,
    step,
    schoolName,
    schoolImage,
    isCertified
  } = props;
  const formatJapaneseDaytime = formatDateTimeStringWithDay(exactStartAt);

  const getStatusLabel = () => {
    switch (step) {
      case "suggest_meeting_date":
      case "select_meeting_date":
      case "adjust_meeting_place":
      case "before_meeting":
      case "preparation":
        return <LessonPreparationLabel />;
      case "lesson_end":
        return <LessonFinishLabel />;
      case "completed":
        return <LessonCompleteLabel />;
      default:
        return null;
    }
  };

  return (
    <Link
      href={`/teachers/mypage/lessons/${id}`}
      sx={{
        width: "100%",
        textDecoration: "none"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Typography fontWeight="bold">
            {formatJapaneseDaytime}（{schoolMinutes}分間）
          </Typography>
          <LessonSchoolData
            schoolName={schoolName}
            schoolImage={schoolImage}
            isCertified={isCertified}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "block" }
          }}
        >
          {getStatusLabel()}
        </Box>
      </Box>
    </Link>
  );
};

export default LessonItem;
