import React, { useState } from "react";

import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material";

import Select from "components/atoms/fields/Select";

import useQueryParams from "hooks/useQueryParams";
import { SortType } from "../store";

type QueryParamsType = {
  sort?: SortType;
};

const SortSection: React.FC = () => {
  const { getQueryParams, updateQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();
  const { sort } = getQueryParams(["sort"]);
  const [sortIndex, setSortIndex] = useState<SortType>(sort || "recent");

  const handleSort = (event: SelectChangeEvent<unknown>) => {
    setSortIndex(event.target.value as SortType);
    // MEMO: 今の所デフォルトのrecentの場合はクエリパラメータを削除する
    if (event.target.value === "recent") {
      deleteQueryParams("sort");
    } else {
      updateQueryParams("sort", event.target.value as SortType);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: { xs: "1rem 1rem 0", sm: "1rem 0 0" }
      }}
    >
      <Select
        size="small"
        width="150px"
        name="sort"
        value={sortIndex}
        onChange={handleSort}
        options={[
          { value: "recent", label: "新着順" },
          { value: "total_rating", label: "評価が高い順" },
          { value: "lesson_counts", label: "登壇が多い順" }
        ]}
      />
    </Box>
  );
};

export default SortSection;
