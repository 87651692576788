import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import useTheme from "@mui/material/styles/useTheme";

const WaitingForMeetingDateSelectionBlock: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        <Typography variant="h2">
          複業先生が打ち合わせ日程を選択中です
        </Typography>
        <Typography>
          現在複業先生が打ち合わせの日程を選択中です。
          <br />
          日程が選択されるまで、しばらくお待ちください。
        </Typography>
      </Box>
    </Box>
  );
};

export default WaitingForMeetingDateSelectionBlock;
