import Typography from "@mui/material/Typography";

import FirstViewButtonBox from "features/root/index/atoms/FirstViewButtonBox";

import type React from "react";
import { Box } from "@mui/material";

const FirstViewContents: React.FC = () => (
  <Box
    sx={{
      position: "absolute",
      top: { xs: "calc(18vh + 30vw)", sm: "36vw", md: "10vw", lg: "20%" },
      left: { xs: "5%", sm: "10%", md: "5%", lg: "10%" },
      zIndex: 1,
      right: { xs: "5%", sm: 0 },
      width: { xs: "auto", sm: "fit-content" }
    }}
  >
    <Typography
      variant="h1"
      sx={{
        fontSize: "calc(1rem + 2vw) !important",
        lineHeight: "2 !important"
      }}
    >
      <Box
        sx={{
          display: "inline-block",
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: { xs: "0 1rem", md: "0 1.5rem" },
          margin: {
            xs: "calc(.3rem + .25vw) auto",
            md: "calc(.5rem + .25vw) auto"
          }
        }}
        component="span"
      >
        複業で
        <Box component="span" color="primary.main">
          先生をしたい
        </Box>
        人と
      </Box>
      <br />
      <Box
        sx={{
          display: "inline-block",
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: { xs: "0 1rem", md: "0 1.5rem" },
          margin: {
            xs: "calc(.3rem + .25vw) auto",
            md: "calc(.5rem + .25vw) auto"
          }
        }}
        component="span"
      >
        <Box component="span" color="primary.main">
          学校
        </Box>
        をつなぐ
      </Box>
    </Typography>
    <FirstViewButtonBox />
  </Box>
);

export default FirstViewContents;
