import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";

// MEMO: ParsedQsを利用しようとしたが、拡張に難があったため、numerとnumber[]を手書きで加えた。
type QueryParams = {
  [key: string]: string | number | string[] | number[] | undefined;
};

const useQueryParams = <T extends QueryParams>() => {
  const history = useHistory();
  const { search } = useLocation();

  const queryParams = qs.parse(search, { ignoreQueryPrefix: true }) as T;

  const getQueryParams = <K extends keyof T>(keys: K[]): Pick<T, K> => {
    const result = {} as Pick<T, K>;
    keys.forEach((key) => {
      result[key] = queryParams[key];
    });
    return result;
  };

  const setQueryParams = (params: object) => {
    const queryString = qs.stringify(params, { arrayFormat: "brackets" });
    history.push({ search: queryString });
  };

  const updateQueryParams = <K extends keyof T>(
    key: K,
    value: string | number | string[] | number[]
  ) => {
    setQueryParams({ ...queryParams, [key]: value });
  };

  const deleteQueryParams = (key: string) => {
    const newQueryParams = { ...queryParams };
    delete newQueryParams[key];
    setQueryParams(newQueryParams);
  };

  return {
    getQueryParams,
    setQueryParams,
    updateQueryParams,
    deleteQueryParams
  };
};

export default useQueryParams;
