import Grid from "@mui/material/Grid";
import { Link as RouterLink } from "react-router-dom";

import Meta from "components/organisms/Meta";

import type React from "react";
import useTeacherAuthApi from "hooks/useTeacherAuthApi";
import useSchoolAuthApi from "hooks/useSchoolAuthApi";
import { Box, FormGroup, Typography, useTheme } from "@mui/material";
import FormPasswordField from "components/molecules/FormPasswordField";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import FormTextField from "components/molecules/FormTextField";
import { FormProvider } from "react-hook-form";
import AuthEyeCatch from "components/atoms/TeacherAuthEyeCatch";
import AuthLayout from "../../../../components/layouts/AuthLayout";

const Login: React.FC = () => {
  const theme = useTheme();
  const { login, loginForm } = useTeacherAuthApi();
  const { resetSchool } = useSchoolAuthApi();
  const { resetTeacher } = useTeacherAuthApi();

  const handleSubmit = async () => {
    await resetSchool();
    await resetTeacher().finally(() => {
      login();
    });
  };

  return (
    <AuthLayout>
      <Meta
        title="複業先生|ログイン"
        description="複業先生のログインページです。複業（副業）で先生をしたい人と学校をつなぐ教育特化型の複業案件プラットフォームです。"
      />
      <Grid
        container
        sx={{ height: { xs: "100%", lg: "100vh" } }}
        alignItems="center"
      >
        <Grid item xs={12} lg={6} sx={{ height: "100%" }}>
          <AuthEyeCatch />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ height: "100%" }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              marginTop: { xs: theme.spacing(3), lg: 0 },
              height: { xs: "100%" }
            }}
          >
            <Grid item xs={10} sm={9} md={7}>
              <Typography variant="h3" align="center">
                複業先生アカウントログイン
              </Typography>
              <Typography align="center">
                教員アカウントのログインは
                <Box
                  component={RouterLink}
                  to="/schools/login"
                  sx={{
                    textDecoration: "none",
                    color: theme.palette.secondary.main,
                    fontWeight: 600
                  }}
                >
                  こちらから
                </Box>
              </Typography>
              <FormGroup>
                <FormProvider {...loginForm}>
                  <form onSubmit={loginForm.handleSubmit(handleSubmit)}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        margin: "1.5rem auto"
                      }}
                    >
                      <FormTextField
                        name="email"
                        label="メールアドレス"
                        noLabel
                        placeholder="sample@fukugyo.co.jp"
                        fullWidth
                      />
                      <FormPasswordField name="password" label="パスワード" />
                      <FormSubmitButton
                        label="ログイン"
                        size="large"
                        fullWidth
                      />
                    </Box>
                  </form>
                </FormProvider>
              </FormGroup>
              <Typography align="center">
                <Box
                  component={RouterLink}
                  to="/teachers/forgot-password"
                  sx={{
                    textDecoration: "none",
                    color: theme.palette.secondary.main,
                    fontWeight: 600
                  }}
                >
                  パスワードをお忘れですか？
                </Box>
                ・
                <Box
                  component={RouterLink}
                  to="/teachers/signup"
                  sx={{
                    textDecoration: "none",
                    color: theme.palette.secondary.main,
                    fontWeight: 600
                  }}
                >
                  新規登録
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default Login;
