import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Meta from "components/organisms/Meta";
import Sidebar from "components/organisms/SchoolSidebar";
import TitleBlock from "features/schools/mypage/index/organisms/TitleBlock";
import CommonLayout from "components/layouts/CommonLayout";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import Button from "components/atoms/mui/Button";
import { useHistory } from "react-router-dom";
import ChipGroup from "components/molecules/ChipGroup";
import CommonPagePagination from "components/organisms/CommonPagePagination";
import { kindOfSchoolRecruitmentsType } from "./store";
import useMyRecruitmentsPageApi from "./useApi";
import RecruitmentList from "./organisms/RecruitmentList";

const MyRecruitments: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const RecruitmentsLabelOptions: {
    label: string;
    value: kindOfSchoolRecruitmentsType;
  }[] = [
    { label: "すべて", value: "" },
    { label: "応募者の選択", value: "select_candidate" },
    { label: "募集中", value: "recruting" },
    { label: "終了・キャンセル", value: "closed_or_canceled" }
  ];

  const { isLoading, kindOfRecruitments, setKindOfSchoolRecruitments } =
    useMyRecruitmentsPageApi();

  return (
    <CommonLayout>
      <Meta title="複業先生｜直接依頼状況" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { sx: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem"
            }}
          >
            <TitleBlock
              title="募集状況"
              description="依頼したい授業内容を作成し、複業先生からの応募を待ちましょう。"
              Button={
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    history.push("/recruitments/new");
                  }}
                >
                  募集内容を作成
                </Button>
              }
            />
            <ChipGroup
              options={RecruitmentsLabelOptions}
              selectedValue={kindOfRecruitments}
              onSelect={setKindOfSchoolRecruitments}
            />
            <RecruitmentList isLoading={isLoading} />
            <CommonPagePagination />
          </Box>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default MyRecruitments;
