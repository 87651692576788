import Typography from "@mui/material/Typography";

import type React from "react";
import { Box } from "@mui/material";
import theme from "themes/theme";
import { useFormContext } from "react-hook-form";
import { useAtomValue } from "jotai";
import Chip from "components/atoms/mui/Chip";
import { frequencyOptions } from "constants/selectOptions";

import { isTrue } from "utils/checkBool";
import {
  organizationCategories as organizationCategoriesAtom,
  tags as tagsAtom
} from "../store";
import ConfirmField from "../molecules/ConfirmField";

const ConfirmStep03: React.FC = () => {
  const { getValues } = useFormContext();
  const organizationCategories = useAtomValue(organizationCategoriesAtom);
  const organizationCategoryIds = getValues("organization_category_ids");
  const tags = useAtomValue(tagsAtom);
  const tagIds = getValues("tag_ids");
  const organizatioinCategoriesValue = (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem"
      }}
    >
      {organizationCategoryIds?.map((id: number) => {
        const name = organizationCategories.find(
          (category) => category.id === id
        )?.name;
        return <Chip key={id} label={name} />;
      })}
    </Box>
  );
  const tagsValue = (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem"
      }}
    >
      {tagIds?.map((id: number) => {
        const name = tags.find((tag) => tag.id === id)?.name;
        return <Chip key={id} label={name} />;
      })}
    </Box>
  );
  const frequency = getValues("frequency");
  const frequencyValue = frequency
    ? frequencyOptions.find(
        (option) => option.value.toString() === frequency.toString()
      )?.label
    : "未記入";

  return (
    <Box
      component="section"
      sx={{
        padding: "1rem 1rem .5rem",
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px"
      }}
    >
      <Typography variant="h2">その他必要情報</Typography>
      <ConfirmField
        title="学校機関などで授業・講演などを行ったことがありますか？"
        value={isTrue(getValues("is_experience_lesson")) ? "ある" : "ない"}
      />
      <ConfirmField
        title="現在所属している企業の業界・専門領域で該当するものはどれですか？"
        value={organizatioinCategoriesValue}
      />
      <ConfirmField
        title="授業ができるテーマがあれば教えてください。"
        value={tagsValue}
      />
      <ConfirmField
        title="こんな授業ができます"
        value={getValues("lesson_contents")}
      />
      <ConfirmField title="希望活動頻度" value={frequencyValue} />
    </Box>
  );
};

export default ConfirmStep03;
