import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useForm } from "react-hook-form";
import { Methods as SignInMethods } from "api/api/v1/teacher_auth/sign_in";
import { Methods as SignUpMethods } from "api/api/v1/teacher_auth";
import { Methods as PasswordMethods } from "api/api/v1/teacher_auth/password";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  loginSchema,
  forgotPasswordSchema,
  signupSchema,
  resetPasswordSchema
} from "constants/AuthFormSchema";
import aspida from "libs/aspida";
import { RESET } from "jotai/utils";

import {
  currentUserAtom,
  isLoggedInAtom,
  accessTokenAtom,
  clientAtom,
  uidAtom,
  tokenTypeAtom,
  prevLoginPathAtom,
  registeredEmailAtom
} from "store/AuthStore";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import useQueryParams from "hooks/useQueryParams";

type QueryParamsType = {
  reset_password_token?: string;
};

const useTeacherAuthApi = () => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const { getQueryParams } = useQueryParams<QueryParamsType>();
  const { reset_password_token } = getQueryParams(["reset_password_token"]);

  const loginForm = useForm<SignInMethods["post"]["reqBody"]>({
    resolver: yupResolver(loginSchema),
    mode: "all"
  });
  const signupForm = useForm<SignUpMethods["post"]["reqBody"]>({
    resolver: yupResolver(signupSchema),
    mode: "all"
  });
  const forgotPasswordForm = useForm<PasswordMethods["post"]["reqBody"]>({
    resolver: yupResolver(forgotPasswordSchema),
    mode: "all",
    defaultValues: {
      email: "",
      redirect_url: `${process.env.REACT_APP_HOST}/teachers/password-reset`
    }
  });
  const resetPasswordForm = useForm<PasswordMethods["put"]["reqBody"]>({
    resolver: yupResolver(resetPasswordSchema),
    mode: "all",
    defaultValues: {
      reset_password_token: reset_password_token || ""
    }
  });
  const [currentTeacher, setCurrentTeacher] = useAtom(currentUserAtom);
  const [, setTeacherIsLoggedIn] = useAtom(isLoggedInAtom);
  const [, setTeacherAccessToken] = useAtom(accessTokenAtom);
  const [, setTeacherClient] = useAtom(clientAtom);
  const [, setTeacherUid] = useAtom(uidAtom);
  const [, setTeacherTokenType] = useAtom(tokenTypeAtom);
  const [, setRegisteredEmail] = useAtom(registeredEmailAtom);
  const [prevLoginPath, setPrevLoginPath] = useAtom(prevLoginPathAtom);
  const history = useHistory();

  const login = async () => {
    try {
      const params = loginForm.getValues();
      const response = await aspida(headers).api.v1.teacher_auth.sign_in.post({
        body: params
      });
      if (response.status === 200) {
        setTeacherAccessToken(response.headers["access-token"]);
        setTeacherClient(response.headers.client);
        setTeacherUid(response.headers.uid);
        setTeacherTokenType(response.headers["token-type"]);
        setCurrentTeacher({ ...currentTeacher, ...response.body.data });
        setTeacherIsLoggedIn(true);
        if (prevLoginPath) {
          const path = prevLoginPath;
          setPrevLoginPath("");
          history.push(path);
        } else {
          history.push("/teachers/mypage");
        }
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        addErrorMessage("メールアドレス・パスワードが間違っています");
      } else {
        addErrorMessage("ログインに失敗しました");
      }
    }
  };

  const signup = async () => {
    try {
      const params = signupForm.getValues();
      const response = await aspida(headers).api.v1.teacher_auth.post({
        body: params
      });
      if (response.status === 200) {
        setRegisteredEmail(params.email);
        history.push("/teachers/thanks-signup");
      }
    } catch (error: any) {
      if (error.response?.status === 422) {
        addErrorMessage("メールアドレスはすでに登録されています");
      } else {
        addErrorMessage("登録に失敗しました");
      }
    }
  };

  const forgotPassword = async () => {
    try {
      const params = forgotPasswordForm.getValues();
      await aspida(headers).api.v1.teacher_auth.password.post({
        body: params
      });
      history.push("/teachers/thanks-reset-request");
    } catch (error: any) {
      if (error.response?.status === 404) {
        addErrorMessage("メールアドレスが見つかりません");
      } else {
        addErrorMessage("リクエストに失敗しました");
      }
    }
  };

  const resetPassword = async () => {
    try {
      const params = resetPasswordForm.getValues();
      await aspida(headers).api.v1.teacher_auth.password.put({
        body: params
      });
      history.push("/teachers/password-reset-complete");
    } catch (error: any) {
      addErrorMessage("リクエストに失敗しました");
    }
  };

  const resetTeacher = async () => {
    try {
      await aspida(headers).api.v1.teacher_auth.sign_out.delete();
      addSuccessMessage("ログアウトしました");
    } catch {
      // Intentionally empty catch block
    } finally {
      setTeacherAccessToken(RESET);
      setTeacherClient(RESET);
      setTeacherTokenType(RESET);
      setCurrentTeacher(RESET);
      setTeacherIsLoggedIn(RESET);
    }
  };

  const logout = async () => {
    resetTeacher().then(() => {
      history.push("/teachers/login");
    });
  };

  return {
    login,
    loginForm,
    signup,
    signupForm,
    forgotPassword,
    forgotPasswordForm,
    resetPassword,
    resetPasswordForm,
    logout,
    resetTeacher
  };
};

export default useTeacherAuthApi;
