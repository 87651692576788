import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import useTheme from "@mui/material/styles/useTheme";

import Button from "components/atoms/mui/Button";

type OptionsItemProps = {
  title: string | React.ReactNode;
  desciption: string | React.ReactNode;
  onClick: () => void;
};

const OptionsItem: React.FC<OptionsItemProps> = (props) => {
  const { title, desciption, onClick } = props;
  const theme = useTheme();

  return (
    <Button
      color="inherit"
      onClick={onClick}
      fullWidth
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem",
        width: "100%",
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        border: `1px solid ${theme.palette.sub?.lighter}`
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: ".2rem"
        }}
      >
        {typeof title === "string" ? (
          <Typography variant="h3">{title}</Typography>
        ) : (
          title
        )}
        {typeof desciption === "string" ? (
          <Typography sx={{ color: theme.palette.sub.main }}>
            {desciption}
          </Typography>
        ) : (
          desciption
        )}
      </Box>
      <ArrowForwardIos
        sx={{ color: theme.palette.sub?.main, fontSize: "1rem" }}
      />
    </Button>
  );
};

export default OptionsItem;
