import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import reactStringReplace from "react-string-replace";

import type React from "react";
import Button from "components/atoms/mui/Button";

type AnnouncementBoxProps = {
  title: string;
  contents: string;
  handleConfirm: () => void;
};

const AnnouncementBox: React.FC<AnnouncementBoxProps> = (props) => {
  const { title, contents, handleConfirm } = props;
  const theme = useTheme();
  const regExp = /(https?:\/\/\S+)/g;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".75rem 0"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem 0",
          border: `solid 1px ${theme.palette.border?.main}`,
          padding: "1rem"
        }}
      >
        <Typography fontWeight="bold">{title}</Typography>
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            lineHeight: 1.5
          }}
        >
          {reactStringReplace(contents, regExp, (match) => (
            <a href={match} target="_blank" rel="noreferrer">
              {match}
            </a>
          ))}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleConfirm}
      >
        確認しました
      </Button>
    </Box>
  );
};

export default AnnouncementBox;
