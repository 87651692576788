import { Box, Typography } from "@mui/material";
import theme from "themes/theme";
import { useFormContext } from "react-hook-form";

import { isTrue } from "utils/checkBool";
import { formatDateString } from "utils/date";
import ConfirmField from "../molecules/ConfirmField";

const ConfirmStep01: React.FC = () => {
  const { getValues } = useFormContext();
  const imageSrc = getValues("image") || "";
  const organizationPublicValue = isTrue(
    getValues("is_organization_name_public")
  )
    ? "公開する"
    : "公開しない";
  const birthday = formatDateString(getValues("birthday"));
  const websites = getValues("websites");
  const websitesValue =
    websites[0]?.title !== "" && websites[0]?.url !== "" ? (
      <Box>
        {websites.map(
          (website: { title?: string; url?: string }, index: number) => (
            <Box key={index} sx={{ m: ".5rem auto" }}>
              <Typography>{website.title}</Typography>
              <Typography>
                <a target="brank" rel="noreferrer" href={website.url}>
                  {website.url}
                </a>
              </Typography>
            </Box>
          )
        )}
      </Box>
    ) : (
      <Typography>未記入</Typography>
    );
  const limitedPublicValue = isTrue(getValues("is_limited_public"))
    ? "教員アカウントのみに公開"
    : "一般公開";

  return (
    <Box
      component="section"
      sx={{
        padding: "1rem 1rem .5rem",
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        width: "100%"
      }}
    >
      <Typography variant="h2">基本情報</Typography>
      <ConfirmField
        title="顔写真"
        value={<img src={imageSrc} alt="登録予定の顔写真" />}
      />
      <ConfirmField title="氏名" value={getValues("name")} />
      <ConfirmField title="氏名（カナ）" value={getValues("kana_name")} />
      <ConfirmField
        title="所属企業（学生の方は学校・学部名）"
        value={getValues("organization_name")}
      />
      <ConfirmField title="所属先の公開" value={organizationPublicValue} />
      <ConfirmField title="生年月日" value={birthday} />
      <ConfirmField
        title="活動内容がわかるWEBサイト・SNS"
        value={websitesValue}
      />
      <ConfirmField title="プロフィール公開設定" value={limitedPublicValue} />
    </Box>
  );
};
export default ConfirmStep01;
