import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import Meta from "components/organisms/Meta";
import Part1 from "features/teachers/mypage/eLearnings/new/organisms/Part1";
import Part2 from "features/teachers/mypage/eLearnings/new/organisms/Part2";
import Part3 from "features/teachers/mypage/eLearnings/new/organisms/Part3";
import Part4 from "features/teachers/mypage/eLearnings/new/organisms/Part4";
import ELearningLayout from "components/layouts/ELearningLayout";

import type React from "react";
import ResultDisplayBlock from "./organisms/ResultDisplayBlock";

const Result: React.FC = () => {
  const theme = useTheme();

  return (
    <ELearningLayout>
      <Meta
        title="複業先生｜e-learning"
        description="学校での授業に向けて、必要なこと・大切なことを動画で学びましょう。"
      />
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: { xs: "0", sm: "inherit" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem"
          }}
        >
          <Box
            sx={{
              padding: "1.5rem",
              backgroundColor: theme.palette.common.white,
              borderRadius: "4px",
              border: `1px solid ${theme.palette.border?.main}`,
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem"
            }}
          >
            <ResultDisplayBlock />
          </Box>
          <Box
            sx={{
              padding: "1.5rem",
              backgroundColor: theme.palette.common.white,
              borderRadius: "4px",
              border: `1px solid ${theme.palette.border?.main}`,
              display: "flex",
              flexDirection: "column",
              gap: "2.5rem"
            }}
          >
            <Part1 isResult />
            <Part2 isResult />
            <Part3 isResult />
            <Part4 isResult />
          </Box>
        </Box>
      </Container>
    </ELearningLayout>
  );
};

export default Result;
