import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Meta from "components/organisms/Meta";
import Sidebar from "components/organisms/SchoolSidebar";
import CommonLayout from "components/layouts/CommonLayout";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import ChipGroup from "components/molecules/ChipGroup";
import CommonPagePagination from "components/organisms/CommonPagePagination";
import useMyLessonsPageApi from "./useApi";
import LessonLists from "./organisms/LessonLists";
import { kindOfSchoolLessonsType } from "./store";
import TitleBlock from "../../index/organisms/TitleBlock";

const MyRecruitments: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { isLoading, kindOfSchoolLessons, setKindOfSchoolLessons } =
    useMyLessonsPageApi();

  const LessonSelectLabelOptions: {
    label: string;
    value: kindOfSchoolLessonsType;
  }[] = [
    { label: "すべて", value: "" },
    { label: "授業準備中", value: "preparation" },
    { label: "授業終了", value: "lesson_end" }
  ];

  return (
    <CommonLayout>
      <Meta title="複業先生｜授業進行管理" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { sx: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem"
            }}
          >
            <TitleBlock
              title="授業進行管理"
              description="授業予定が一覧で確認できます。"
            />
            <ChipGroup
              options={LessonSelectLabelOptions}
              selectedValue={kindOfSchoolLessons}
              onSelect={setKindOfSchoolLessons}
            />
            <LessonLists isLoading={isLoading} />
            <CommonPagePagination />
          </Box>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default MyRecruitments;
