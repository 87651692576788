import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import useAspidaSWR from "@aspida/swr";
import aspida from "libs/aspida";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import { useAtom } from "jotai";

import { useForm } from "react-hook-form";
import { Methods as SelectTeacherMethods } from "api/api/v1/lesson_recruitments/_lessonRecruitmentId@string/select_teacher";
import { formatDate } from "utils/date";
import {
  candidatesCountAtom,
  candidatesAtom,
  recruitmentAtom,
  lessonOptionsAtom,
  selectCandidateFormStepAtom,
  newLessonIdAtom
} from "./store";

const useShowPageApi = (id: string) => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [, setRecruitment] = useAtom(recruitmentAtom);
  const [, setCandidates] = useAtom(candidatesAtom);
  const [, setCandidatesCount] = useAtom(candidatesCountAtom);
  const [, setLessonOptions] = useAtom(lessonOptionsAtom);
  const [, setNewLessonId] = useAtom(newLessonIdAtom);
  const [, setFormStep] = useAtom(selectCandidateFormStepAtom);

  const selectCandidateForm =
    useForm<SelectTeacherMethods["patch"]["reqBody"]>();

  const { isLoading, mutate } = useAspidaSWR(
    aspida(headers)
      .api.v1.school_teachers._schoolTeacherId_string(
        currentSchool?.id.toString()
      )
      .lesson_recruitments._lessonRecrutimentId(id),
    {
      onSuccess: (response) => {
        setCandidatesCount(response.candidates_count);
        setCandidates(response.candidates.data);
        setRecruitment(response.details);
        setLessonOptions(response.lesson_options);
      },
      onError: () => {
        addErrorMessage("募集の取得に失敗しました");
      }
    }
  );

  const selectCandidate = async () => {
    try {
      const body = selectCandidateForm.getValues();
      const res = await aspida(headers)
        .api.v1.lesson_recruitments._lessonRecruitmentId(id)
        .select_teacher.patch({
          body
        });
      if (res.status === 200) {
        // mutateを使うと、stepが正常に動作しないため、返却値でlesson_idを更新
        setNewLessonId(res.body.details.lesson_id || null);
        setFormStep(4);
      }
    } catch (error) {
      addErrorMessage("候補者選択ができませんでした");
    }
  };

  const earlyClose = async () => {
    try {
      const res = await aspida(headers)
        .api.v1.lesson_recruitments._lessonRecruitmentId(id)
        .manual_close.patch({});
      if (res.status === 200) {
        mutate();
        addSuccessMessage(
          `募集を終了しました。${formatDate(res?.body.details.select_deadline || "")}までに複業先生を選んでください。`
        );
      }
    } catch (error) {
      addErrorMessage("募集の終了に失敗しました。");
    }
  };

  const cancelRecruitment = async () => {
    try {
      const res = await aspida(headers)
        .api.v1.lesson_recruitments._lessonRecruitmentId(id)
        .cancel_recruitment.patch({});
      if (res.status === 200) {
        addSuccessMessage("募集をキャンセルしました。");
        mutate();
      }
    } catch (error) {
      addErrorMessage("募集のキャンセルに失敗しました。");
    }
  };

  return {
    isLoading,
    selectCandidate,
    selectCandidateForm,
    earlyClose,
    cancelRecruitment
  };
};

export default useShowPageApi;
