import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";
import CommonPagePagination from "components/organisms/CommonPagePagination";

import type React from "react";
import RecruitmentList from "./organisms/RecruitmentList";
import useIndexPageApi from "./useApi";

const Index: React.FC = () => {
  const theme = useTheme();
  const { isLoading } = useIndexPageApi();

  return (
    <CommonLayout>
      <Meta
        title="複業先生|登壇者募集中の授業一覧"
        description="学校からの授業募集の一覧です。自分の強みやできることを活かし、募集に応募しましょう。"
      />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          <Typography variant="h1">募集一覧</Typography>
          <RecruitmentList isLoading={isLoading} />
          <CommonPagePagination />
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default Index;
