import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useAtomValue } from "jotai";

import LessonThemeChip from "components/atoms/dataDisplay/LessonThemeChip";
import {
  formatDate,
  formatDateTimeStringWithDay,
  checkDeadline
} from "utils/date";

import type React from "react";

import { useFormContext } from "react-hook-form";
import ImportantInfoCard from "components/molecules/ImportantInfoCard";
import DetailItem from "../molecules/DetaiItem";
import { recruitmentAtom, schoolTeacherAtom } from "../store";

const Step03: React.FC = () => {
  const theme = useTheme();
  const { getValues } = useFormContext();
  const recruitment = useAtomValue(recruitmentAtom);
  const schoolTeacher = useAtomValue(schoolTeacherAtom);
  const { limit, dead } = checkDeadline(recruitment?.recruitment_dead_at || "");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography fontWeight="bold">
        下記の内容で問題なければ、「応募する」ボタンを押してください。
        変更がある場合は、戻って変更してください。
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
          border: `1px solid ${theme.palette.sub.lighter}`,
          borderRadius: "8px"
        }}
      >
        <DetailItem
          title="授業可能な日程"
          content={getValues("proposed_date_ids")?.map((id: number) => {
            const proposedDate = recruitment?.proposed_dates.data.find(
              (proposedDate) => proposedDate.id === id
            );
            const dateString = formatDateTimeStringWithDay(
              proposedDate?.start_at_datetime || ""
            );

            return (
              <Typography key={id}>
                {dateString} ({recruitment?.school_minutes}分間)
              </Typography>
            );
          })}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            flexDirection: { xs: "column", sm: "row" },
            border: `1px solid ${theme.palette.sub.lighter}`,
            padding: "1rem",
            borderRadius: "8px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem"
            }}
          >
            <Typography fontWeight="bold" fontSize="1.2rem">
              {recruitment?.title}
            </Typography>
            <Typography>{schoolTeacher?.school_name}</Typography>
            <div>
              <LessonThemeChip name={recruitment?.tag?.name} />
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              flexDirection: { xs: "column", sm: "row" }
            }}
          >
            <ImportantInfoCard
              Icon={DescriptionOutlinedIcon}
              title="実施方法"
              mainInfo={recruitment?.is_online ? "オンライン" : "対面"}
              subInfo={
                recruitment?.is_online
                  ? undefined
                  : schoolTeacher?.school_prefecture_name || ""
              }
            />
            <ImportantInfoCard
              Icon={PermIdentityIcon}
              title="対象生徒"
              mainInfo={recruitment?.school_grade_name || ""}
              subInfo={`${recruitment?.student_number}人`}
            />
            <ImportantInfoCard
              Icon={AccessTimeIcon}
              title="募集期限"
              mainInfo={dead ? "--" : `あと${limit}日`}
              subInfo={
                recruitment?.step === "now_recruting"
                  ? formatDate(recruitment?.recruitment_dead_at || "")
                  : "-----"
              }
            />
          </Box>
        </Box>
        <DetailItem
          title="どんな授業ができるか"
          content={getValues("lesson_contents")}
        />
        <DetailItem title="授業への意気込み" content={getValues("appeal")} />
      </Box>
    </Box>
  );
};

export default Step03;
