import React, { useState, useEffect } from "react";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

// MEMO: atomのボタンを使うと、tooltipが表示されない
import Button from "@mui/material/Button";

type UrlBoxProps = {
  url: string;
};

const UrlBox: React.FC<UrlBoxProps> = (props) => {
  const { url } = props;
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleCopyUrl = () => {
    setOpen(true);
    navigator.clipboard.writeText(url);
  };

  // Tooltipが開いてから一定時間後に閉じる
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  }, [open]);

  return (
    <Box
      sx={{
        display: "flex",
        border: `1px solid ${theme.palette.sub.lighter}`,
        borderRadius: "8px",
        backgroundColor: theme.palette.common.white
      }}
    >
      <Typography
        sx={{
          padding: "1rem",
          borderRight: `1px solid ${theme.palette.sub.lighter}`,
          wordBreak: "break-all",
          width: "100%"
        }}
      >
        {url}
      </Typography>
      <Tooltip
        PopperProps={{
          disablePortal: true
        }}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="URLをコピーしました"
        placement="top"
      >
        <Button
          color="inherit"
          variant="text"
          onClick={handleCopyUrl}
          sx={{
            backgroundColor: theme.palette.backgroundColor?.main,
            width: "8rem",
            padding: "1rem",
            borderRadius: "0 8px 8px 0"
          }}
        >
          URLコピー
        </Button>
      </Tooltip>
    </Box>
  );
};

export default UrlBox;
