import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import getStatusLabel from "services/recruitments/GetStatusLabel";

import type React from "react";
import { checkDeadline, formatDateString } from "utils/date";

type RecruitmentListItemProps = {
  id: number;
  title: string;
  deadline: string;
  status: string;
  candidatesCount: number;
};

const RecruitmentListItem: React.FC<RecruitmentListItemProps> = ({
  id,
  title,
  deadline,
  status,
  candidatesCount
}) => {
  const { limit } = checkDeadline(deadline);

  return (
    <Link
      href={`/schools/mypage/recruitments/${id}`}
      sx={{ textDecoration: "none", width: "100%" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        {/* タイトルと締め切り */}
        <Stack spacing={0.5} flex={{ xs: "auto", sm: 13 }}>
          <Typography fontWeight="bold">{title}</Typography>
          <Typography fontSize=".9rem">
            締め切り：
            {status === "now_recruting" ? formatDateString(deadline) : "-----"}
          </Typography>
        </Stack>

        {/* ステータス */}
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flex: 4,
            justifyContent: "center"
          }}
        >
          {getStatusLabel(status)}
        </Box>

        {/* 応募人数 */}
        <Box sx={{ display: { xs: "none", sm: "block" }, flex: 3 }}>
          <Typography fontWeight="bold" fontSize="1.2rem" align="center">
            {candidatesCount || 0}名
          </Typography>
        </Box>

        {/* 期限 */}
        <Box sx={{ display: { xs: "none", sm: "block" }, flex: 3 }}>
          <Typography align="center">
            {status === "now_recruting" ? `あと${limit}日` : "--"}
          </Typography>
        </Box>
      </Stack>
    </Link>
  );
};

export default RecruitmentListItem;
