import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import type React from "react";
import FormCheckboxGroup from "components/molecules/FormCheckboxGroup";
import { formatDateTimeStringWithDay } from "utils/date";
import { recruitmentAtom } from "../store";

const Step01: React.FC = () => {
  const recruitment = useAtomValue(recruitmentAtom);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      <FormCheckboxGroup
        name="proposed_date_ids"
        options={
          recruitment?.proposed_dates.data.map((proposedDate, index) => {
            const listItemLabel = `第${index + 1}希望：${formatDateTimeStringWithDay(proposedDate.start_at_datetime)}（${recruitment?.school_minutes}分間）`;
            return {
              label: listItemLabel,
              value: proposedDate.id
            };
          }) || []
        }
      />
    </Box>
  );
};

export default Step01;
