import React from "react";
import { useHistory } from "react-router-dom";
import { useAtomValue } from "jotai";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Button from "components/atoms/mui/Button";
import { newLessonIdAtom } from "../store";

const Step04: React.FC = () => {
  const newLessonId = useAtomValue(newLessonIdAtom);
  const history = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "1rem",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "flex-start"
      }}
    >
      <div>
        <Typography>授業募集が成立しました！</Typography>
        <Typography>授業進行管理ページから、授業を進めましょう！</Typography>
      </div>
      <Button
        color="primary"
        onClick={() => history.push(`/schools/mypage/lessons/${newLessonId}`)}
      >
        成立済みの授業へ
      </Button>
    </Box>
  );
};

export default Step04;
