import { useState, useEffect } from "react";
import { ConfigType } from "dayjs";
import dayjs from "libs/dayjs";

const useIsNew = (updatedAt: ConfigType) => {
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    // 3日以内の場合は新着として扱う
    const now = dayjs();
    const diff = now.diff(updatedAt, "day");
    setIsNew(diff <= 3);
  }, [updatedAt]);

  return isNew;
};

export default useIsNew;
