import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";
import { Link } from "react-router-dom";

import type React from "react";
import { recruitmentAtom } from "../store";

const Accepted: React.FC = () => {
  const theme = useTheme();
  const recruitment = useAtomValue(recruitmentAtom);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: 8,
        padding: "1rem",
        display: "flex",
        gap: "1rem",
        alignItems: { xs: "flex-start", sm: "center" },
        justifyContent: "space-between",
        flexDirection: { xs: "column", sm: "row" }
      }}
    >
      <Typography fontWeight="bold" fontSize="1.1rem">
        授業募集は成立済みです
      </Typography>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        component={Link}
        to={`/schools/mypage/lessons/${recruitment?.lesson_id}`}
        sx={{
          width: "12rem",
          fontWeight: "bold"
        }}
      >
        成立済みの授業へ
      </Button>
    </Box>
  );
};

export default Accepted;
