import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import { Link as RouterLink } from "react-router-dom";

import { currentUserAtom } from "store/AuthStore";

import LessonThemeChip from "components/atoms/dataDisplay/LessonThemeChip";
import useReward from "hooks/useReward";
import CommonInfoContent from "../../../../components/molecules/CommonInfoContent";
import { recruitmentAtom, candidatesCountAtom } from "../store";

const RecruitmentDetails: React.FC = () => {
  const recruitment = useAtomValue(recruitmentAtom);
  const currentUser = useAtomValue(currentUserAtom);
  const candidatesCount = useAtomValue(candidatesCountAtom);
  const theme = useTheme();

  const { reward } = useReward(
    currentUser.teacher_rank,
    recruitment?.school_minutes
  );

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Typography variant="h2">募集内容</Typography>
        {candidatesCount ? (
          <Typography fontWeight="bold">
            応募人数：
            <Typography component="span" fontSize="1.4rem" fontWeight="bold">
              {candidatesCount}
            </Typography>
            名
          </Typography>
        ) : (
          <Typography color={theme.palette.sub.main}>
            まだ応募はありません
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
          backgroundColor: theme.palette.common.white,
          borderRadius: "4px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Typography fontWeight="bold">授業テーマ</Typography>
          <div>
            <LessonThemeChip name={recruitment?.tag?.name} />
          </div>
        </Box>
        {recruitment?.lesson_contents && (
          <CommonInfoContent
            title="授業内容"
            content={recruitment?.lesson_contents}
            multiline
          />
        )}
        {recruitment?.want_to && (
          <CommonInfoContent
            title="こんな先生に来てほしい"
            content={recruitment?.want_to}
            multiline
          />
        )}
        {recruitment?.student_condition && (
          <CommonInfoContent
            title="生徒の今の様子"
            content={recruitment?.student_condition}
            multiline
          />
        )}
        {recruitment?.issue && (
          <CommonInfoContent
            title="生徒の課題"
            content={recruitment?.issue}
            multiline
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem"
          }}
        >
          <Typography fontWeight="bold">報酬</Typography>
          {currentUser.is_reward ? (
            <Typography>￥{reward.toLocaleString()}(税抜)</Typography>
          ) : (
            <Typography>
              現在報酬なしの設定になっております。
              <br />
              報酬ありに変更する場合は
              <RouterLink to="/teachers/mypage/settings?accordion=basic">
                こちら
              </RouterLink>
              からお願いします。
            </Typography>
          )}
        </Box>
        {recruitment?.travel_cost && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem"
            }}
          >
            <Typography fontWeight="bold">交通費の取り扱い</Typography>
            <Typography>{recruitment?.travel_cost}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RecruitmentDetails;
