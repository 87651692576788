import React, { useState } from "react";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import { Methods as LessonMeetingPossibleDatimesMethods } from "api/api/v1/lessons/_lessonId@string/lesson_meeting_possible_datetimes";
import { Methods as UpdateLessonMethods } from "api/api/v1/lessons/_lessonId@string";

import SelectWay from "../molecules/SelectWay";
import SuggestedDateForm from "../molecules/SuggestedDateForm";
import MeetingDateForm from "../molecules/MeetingDateForm";

type postLessonMeetingPossibleDatimesType =
  LessonMeetingPossibleDatimesMethods["post"]["reqBody"];

export type MeetingScheduleStepType =
  | "selectWay"
  | "suggestedDateForm"
  | "setDate";

type MeetingScheduleBlockProps = {
  postLessonMeetingPossibleDatimes: (
    data: postLessonMeetingPossibleDatimesType
  ) => void;
  updateLesson: (data: UpdateLessonMethods["patch"]["reqBody"]) => void;
};

const MeetingScheduleBlock: React.FC<MeetingScheduleBlockProps> = (props) => {
  const { postLessonMeetingPossibleDatimes, updateLesson } = props;
  const theme = useTheme();
  // MEMO: Chatのタブ化に伴い、storeに置くことも検討
  const [meetingScheduleStep, setMeetingScheduleStep] =
    useState<MeetingScheduleStepType>("selectWay");

  const getMainContents = () => {
    switch (meetingScheduleStep) {
      case "selectWay":
        return (
          <SelectWay
            setStep={setMeetingScheduleStep}
            updateLesson={updateLesson}
          />
        );
      case "suggestedDateForm":
        return (
          <SuggestedDateForm
            setStep={setMeetingScheduleStep}
            postLessonMeetingPossibleDatimes={postLessonMeetingPossibleDatimes}
          />
        );
      case "setDate":
        return (
          <MeetingDateForm
            setStep={setMeetingScheduleStep}
            patchLessonMeetingPossibleDatimes={updateLesson}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%",
        boxSizing: "border-box"
      }}
    >
      {getMainContents()}
    </Box>
  );
};

export default MeetingScheduleBlock;
