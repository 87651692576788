import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";

import type React from "react";

type LessonExampleItemProps = {
  id: number;
  lessonTitle: string;
  lessonLabel: string;
  teacherOrganization?: string;
  teacherName: string;
  teacherImageUrl: string;
};

const LessonExampleItem: React.FC<LessonExampleItemProps> = (props) => {
  const {
    id,
    lessonTitle,
    lessonLabel,
    teacherOrganization,
    teacherName,
    teacherImageUrl
  } = props;
  const theme = useTheme();
  const displayId = id < 10 ? `0${id.toString()}` : id.toString();

  return (
    <Box
      component="tr"
      sx={{
        backgroundColor:
          id % 2 === 0
            ? theme.palette.backgroundColor?.main
            : theme.palette.common.white,
        display: { xs: "block", sm: "table-row" },
        width: { xs: "100%", sm: "auto" },
        border: { xs: `solid 1px ${theme.palette.border?.main}`, sm: "none" },
        borderRadius: { xs: "4px", sm: "0" },
        margin: { xs: "2rem auto", sm: 0 },
        boxShadow: { xs: "0px 0px 20px -12px #858585", sm: "none" },
        padding: { xs: "1rem 0", sm: 0 },
        "& > td": {
          padding: { xs: ".5rem 1rem", sm: "1rem" },
          display: { xs: "block", sm: "table-cell" },
          width: { xs: "100%", sm: "auto" }
        },
        "& td:first-child": {
          paddingLeft: { xs: "1rem", sm: "2rem" }
        },
        "& td:last-child": {
          paddingRight: { xs: "1rem", sm: "2rem" }
        }
      }}
    >
      <Box component="td" sx={{ width: "4.5rem" }}>
        <Typography
          align="center"
          sx={{
            fontSize: "2rem",
            color: theme.palette.primary.dark,
            fontWeight: "bold",
            width: "100%"
          }}
        >
          {displayId}
        </Typography>
      </Box>
      <td>
        <div>
          <Typography fontSize="0.9rem" color={theme.palette.sub.main}>
            {id}コマ目：{lessonLabel}
          </Typography>
          <Typography fontSize="1.1rem" fontWeight="bold">
            {lessonTitle}
          </Typography>
        </div>
      </td>
      <td>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem"
          }}
        >
          <Avatar
            src={teacherImageUrl}
            alt={teacherName}
            sx={{
              height: "60px",
              width: "60px"
            }}
          />
          <div>
            <Typography fontSize="0.9rem" color={theme.palette.sub.main}>
              {teacherOrganization}
            </Typography>
            <Typography fontSize="1.1rem" fontWeight="bold">
              {teacherName}
            </Typography>
          </div>
        </Box>
      </td>
    </Box>
  );
};

export default LessonExampleItem;
