import { atom } from "jotai";

import { Methods } from "api/api/v1/teachers/_teacherId@string";

export const requestModalOpenAtom = atom(false);
export const teacherInfoAtom = atom<Methods["get"]["resBody"] | null>(null);

export const ratingAtom = atom<Methods["get"]["resBody"]["rating"] | null>(
  null
);

export const listRatingAtom = atom<
  Methods["get"]["resBody"]["list_rating"]["data"]
>([]);

export const teacherLessonRequestsAtom = atom<
  Methods["get"]["resBody"]["lesson_requests"]
>([]);

// for: UI control
export const ratingTargetAtom = atom<HTMLDivElement | null>(null);
