import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAtom } from "jotai";

import { paginationData } from "store/MasterData";

import type React from "react";

const TeachersCount: React.FC = () => {
  const [pagination] = useAtom(paginationData);
  const theme = useTheme();

  if (!pagination) return null;

  return (
    <Box
      sx={{
        padding: "1rem 1rem 0",
        width: "100%"
      }}
    >
      {pagination.count !== 0 && (
        <Typography color={theme.palette.sub.main}>
          全{pagination.count}件中
          {(pagination.current - 1) * pagination.limit_value + 1} -{" "}
          {pagination.count >= pagination.current * pagination.limit_value
            ? pagination.current * pagination.limit_value
            : pagination.count}
          件を表示中
        </Typography>
      )}
    </Box>
  );
};

export default TeachersCount;
