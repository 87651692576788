import Box from "@mui/material/Box";

import { lpClassiFrontData } from "constants/lpClassiFrontData";
import type React from "react";
import LessonIdea from "../molecules/LessonIdea";
import SectionTitle from "../molecules/SectionTilte";

import type { exampleTeacherTypes } from "../store";

type LessonIdeasProps = {
  title: string;
  teachers: Array<exampleTeacherTypes>;
};

const LessonIdeas: React.FC<LessonIdeasProps> = (props) => {
  const { title, teachers } = props;

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        rowGap: "2rem",
        flexDirection: "column"
      }}
    >
      <SectionTitle mainTitle={`${title} 授業イメージ`} />
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          flexDirection: { xs: "column", sm: "row" }
        }}
      >
        {teachers.map((teacher) => {
          const lessonTitle =
            lpClassiFrontData.find((item) => item.id === teacher.teacher_id)
              ?.lessonTitle || "";

          return (
            <LessonIdea
              key={teacher.teacher_id}
              id={teacher.teacher_id}
              teacherName={teacher.teacher_name}
              teacherImageUrl={teacher.teacher_image.url}
              teacherOrganization={teacher.organization_name}
              lessonTitle={lessonTitle}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default LessonIdeas;
