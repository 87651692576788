import Typography from "@mui/material/Typography";

import ViewMoreTeachersButton from "features/root/index/atoms/ViewMoreTeachersButton";

import type React from "react";
import { Box } from "@mui/material";

const Conclusion: React.FC = () => (
  <section>
    <Box
      sx={{
        background: "linear-gradient(to right, #FFEFC7, #FFDBAA)",
        padding: "calc(3rem + .25vw) 0 2rem",
        overflow: "hidden"
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "80%",
          maxWidth: "960px",
          margin: "0 auto"
        }}
      >
        <Typography
          variant="h3"
          align="center"
          fontSize={{ xs: "1.5rem !important", md: "1.8rem !important" }}
          sx={{
            fontSize: { xs: "1.5rem !important", md: "1.8rem !important" },
            lineHeight: "2 !important"
          }}
        >
          <Box sx={{ display: "inline-block" }}>教員経験がなくてもOK！</Box>
          <br />
          <Box sx={{ display: "inline-block" }}>“複業先生”になって、</Box>
          <Box sx={{ display: "inline-block" }}>学校を支援しませんか？</Box>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ViewMoreTeachersButton />
        </Box>

        {/* 一時的なコメントアウト */}
        {/* <img alt="man" src={ManImage} className={classes.manImage} />
        <img alt="woman" src={WomanImage} className={classes.womanImage} /> */}
      </Box>
    </Box>
  </section>
);

export default Conclusion;
