import React from "react";

import Pagination from "@mui/material/Pagination";

import { paginationType } from "types/masterDataTypes";

type CommonOrganismPaginationProps = {
  // MEMO: このorganisms向けpaginationは1ページで複数回使われる可能性があるため、propsでpaginationを受け取る
  pagination: paginationType | null;
  setPagination: React.Dispatch<React.SetStateAction<paginationType | null>>;
  setPage: (page: number) => void;
};

// MEMO: Modalを始めとする、organismsでpaginationがほしい場合に利用する
const CommonOrganismPagination: React.FC<CommonOrganismPaginationProps> = (
  props
) => {
  const { pagination, setPagination, setPage } = props;

  if (!pagination) return null;

  const handleChange = (page: number) => {
    setPagination({ ...pagination, current: page });
    setPage(page);
  };

  if (pagination.count === 0) return null;

  return (
    <Pagination
      count={pagination?.pages}
      color="primary"
      variant="outlined"
      shape="rounded"
      onChange={(e, page) => handleChange(page)}
      page={pagination.current}
    />
  );
};

export default CommonOrganismPagination;
