import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";

import IndicatePublic from "components/atoms/fieldLabels/IndicatePublic";
import { useFormContext } from "react-hook-form";
import { useAtom } from "jotai";
import FormLabel from "components/atoms/FormLabel";

import {
  lastNameAtom,
  firstNameAtom,
  lastNameKanaAtom,
  firstNameKanaAtom,
  lastNameIsErrorAtom,
  firstNameIsErrorAtom,
  lastNameKanaIsErrorAtom,
  firstNameKanaIsErrorAtom,
  temporarilySavedAtAtom
} from "features/teachers/profile/new/store";

type FormFullNameFieldProps = {
  label: string;
  isRequired?: boolean;
  isPublic?: boolean;
};

// MEMO: 一時保存のためには結局atomを使うしかないため、componentを新しく作る
const FormFullNameField: React.FC<FormFullNameFieldProps> = (props) => {
  const { label, isRequired, isPublic } = props;
  const { setValue, clearErrors, setError } = useFormContext();

  // MEMO: 一時保存の処理
  const [lastName, setLastName] = useAtom(lastNameAtom);
  const [firstName, setFirstName] = useAtom(firstNameAtom);
  const [lastNameKana, setLastNameKana] = useAtom(lastNameKanaAtom);
  const [firstNameKana, setFirstNameKana] = useAtom(firstNameKanaAtom);
  const [, setTemporarilySavedAt] = useAtom(temporarilySavedAtAtom);

  const [lastNameIsError] = useAtom(lastNameIsErrorAtom);
  const [firstNameIsError] = useAtom(firstNameIsErrorAtom);
  const [lastNameKanaIsError] = useAtom(lastNameKanaIsErrorAtom);
  const [firstNameKanaIsError] = useAtom(firstNameKanaIsErrorAtom);

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setNameAndError("lastName", event.target.value);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
    setNameAndError("firstName", event.target.value);
  };

  const handleLastNameKanaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLastNameKana(event.target.value);
    setKanaNameAndError("lastName", event.target.value);
  };

  const handleFirstNameKanaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstNameKana(event.target.value);
    setKanaNameAndError("firstName", event.target.value);
  };

  const setNameAndError = (type: "lastName" | "firstName", value: string) => {
    if (type === "lastName") {
      setLastName(value);
      setValue("name", `${value}${firstName}`, {
        shouldValidate: true
      });
    }
    if (type === "firstName") {
      setFirstName(value);
      setValue("name", `${lastName}${value}`, {
        shouldValidate: true
      });
    }
    setTemporarilySavedAt(dayjs().format());

    if (!lastNameIsError && !firstNameIsError) {
      setError("name", { type: "invalid" });
    } else {
      clearErrors("name");
    }
  };

  const setKanaNameAndError = (
    type: "lastName" | "firstName",
    value: string
  ) => {
    if (type === "lastName") {
      setLastNameKana(value);
      setValue("kana_name", `${value}${firstNameKana}`, {
        shouldValidate: true
      });
    }
    if (type === "firstName") {
      setFirstNameKana(value);
      setValue("kana_name", `${lastNameKana}${value}`, {
        shouldValidate: true
      });
    }
    setTemporarilySavedAt(dayjs().format());

    if (!lastNameKanaIsError && !firstNameKanaIsError) {
      setError("kana_name", { type: "invalid" });
    } else {
      clearErrors("kana_name");
    }
  };

  return (
    <Box>
      <FormLabel labelName={label} isRequired={isRequired} />
      <Grid container>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <TextField
            variant="outlined"
            placeholder="姓：山田"
            fullWidth
            value={lastName}
            onChange={handleLastNameChange}
            error={lastNameIsError}
            helperText={lastNameIsError && "この項目は必須です"}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <TextField
            variant="outlined"
            placeholder="名：花子"
            fullWidth
            value={firstName}
            onChange={handleFirstNameChange}
            error={firstNameIsError}
            helperText={firstNameIsError && "この項目は必須です"}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <TextField
            variant="outlined"
            placeholder="セイ：ヤマダ"
            fullWidth
            value={lastNameKana}
            onChange={handleLastNameKanaChange}
            error={lastNameKanaIsError}
            helperText={lastNameKanaIsError && "全角カタカナで入力してください"}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: ".5rem" }}>
          <TextField
            variant="outlined"
            placeholder="メイ：ハナコ"
            fullWidth
            value={firstNameKana}
            onChange={handleFirstNameKanaChange}
            error={firstNameKanaIsError}
            helperText={
              firstNameKanaIsError && "全角カタカナで入力してください"
            }
          />
        </Grid>
      </Grid>
      {isPublic && <IndicatePublic />}
    </Box>
  );
};

export default FormFullNameField;
