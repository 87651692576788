import { useState, useEffect } from "react";
import useAspidaSWR from "@aspida/swr";
import { TeacherProfileEdit } from "api/@types";
import { Methods as TeacherProfileMethods } from "api/api/v1/teacher_profiles/_teacherProfileId@string";

import { licenses } from "constants/teachingLicenses";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import aspida from "libs/aspida";
import {
  organizationCategories,
  prefectures,
  tags
} from "features/teachers/profile/new/store";
import { teacherVideosAtom } from "store/TeacherVideosStore";
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import { currentUserAtom } from "store/AuthStore";
import { yupResolver } from "@hookform/resolvers/yup";
import lodash from "lodash";
import profileEditFormSchema from "./constants/profileEditFormSchema";
import { postTeacherVideoParams } from "./types/teacherVideoApiTypes";

const getProfileEditFormDefaultValue = (data: Partial<TeacherProfileEdit>) => {
  const { details } = data;
  if (!details) return undefined;
  const result = {
    id: details.teacher_id,
    enthusiasm: details.enthusiasm,
    introduction: details.introduction,
    tag_ids: details.tags.map((tag) => tag.id),
    lesson_contents: details.lesson_contents,
    organization_name: details.organization_name,
    is_organization_name_public: details.is_organization_name_public,
    organization_category_ids: details.organization_categories.map(
      (category) => category.id
    ),
    origin_prefecture_id: details.origin_prefecture_id,
    residence_prefecture_id: details.residence_prefecture_id,
    origin_high_school_name: details.origin_high_school_name,
    origin_school_name: details.origin_school_name,
    origin_graduate_school_name: details.origin_graduate_school_name,
    // URLからbase64に変換
    image: details.image.url,
    image_file_name: "profile",
    teaching_licenses: details.teaching_licenses.map((license) => ({
      id: licenses.find((l) => l.label === license.name)?.id,
      special_subject: license.special_subject
    })),
    license: details.license,
    websites: details.websites,
    teacher_videos: details.teacher_videos.map((video) => ({
      id: video.id,
      key: video.key,
      url: video.file.url,
      is_public: video.is_public
    }))
  };

  return result as TeacherProfileMethods["put"]["reqBody"];
};

const useEditPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const [currentUser] = useAtom(currentUserAtom);
  const [, setPrefectures] = useAtom(prefectures);
  const [, setOrganizationCategories] = useAtom(organizationCategories);
  // FIXME: 動画アップロード部分のReact-hook-form化が必要
  const [, setTeacherVideos] = useAtom(teacherVideosAtom);
  const [, setTags] = useAtom(tags);

  const [postIsLoading, setPostIsLoading] = useState(false);
  const [removeIsLoading, setRemoveIsLoading] = useState(false);

  const methods = useForm<TeacherProfileMethods["put"]["reqBody"]>({
    mode: "onChange",
    resolver: yupResolver(profileEditFormSchema)
  });

  const { data, mutate, isLoading } = useAspidaSWR(
    // eslint-disable-next-line no-underscore-dangle
    aspida(headers).api.v1.teacher_profiles._teacherProfileId(
      currentUser.teacher_profile_id.toString()
    ).edit,
    {
      onError: () => {
        addErrorMessage("データの取得に失敗しました");
      }
    }
  );

  const updateProfile = async (
    params: TeacherProfileMethods["put"]["reqBody"]
  ) => {
    // websiteのtitleとurlが空の場合は除外する
    const webSites = params?.websites?.filter(
      (website) => website.title !== "" && website.url !== ""
    );
    params.websites = webSites;

    const {
      formState: { dirtyFields }
    } = methods;
    // dirtyフィールドのみを送信する
    const dirtyParams = lodash.pick(params, Object.keys(dirtyFields));
    // dirtyParamsにimageが含まれている場合はimage_file_nameを追加
    if (dirtyFields.image) {
      dirtyParams.image_file_name = "profile";
    }

    try {
      // eslint-disable-next-line no-underscore-dangle
      const response = await aspida(headers)
        .api.v1.teacher_profiles._teacherProfileId(
          currentUser.teacher_profile_id.toString()
        )
        .put({
          body: dirtyParams
        });
      if (response.status === 200) {
        mutate();
        addSuccessMessage("プロフィールを更新しました");
      } else {
        addErrorMessage("プロフィールの更新に失敗しました");
      }
    } catch {
      addErrorMessage("プロフィールの更新に失敗しました");
    }
  };

  // for teacher_videos
  const postTeacherVideo = async (params: postTeacherVideoParams) => {
    setPostIsLoading(true);
    const { key, file, file_name } = params;

    if (file.size > 100000000) {
      addErrorMessage("動画ファイルは100MB以下のみアップロード可能です。");
      setPostIsLoading(false);

      return;
    }

    if (!file.name.endsWith(".mp4") && !file.name.endsWith(".mov")) {
      addErrorMessage("動画ファイルはmp4かmovのみアップロード可能です。");
      setPostIsLoading(false);

      return;
    }

    try {
      const response = await aspida(headers)
        .api.v1.teachers._teacherId_string(currentUser.id.toString())
        .teacher_videos.post({
          body: {
            key,
            file,
            file_name
          }
        });

      if (response.status === 200) {
        // MEMO: 処理に成功したら、その場でstoreをresetする
        await mutate();

        addSuccessMessage("動画をアップロードしました");
      }

      setPostIsLoading(false);
    } catch {
      addErrorMessage("動画のアップロードに失敗しました");

      setPostIsLoading(false);
    }
  };

  const removeTeacherVideo = async (videoId: number) => {
    setRemoveIsLoading(true);
    try {
      const response = await aspida(headers)
        .api.v1.teachers._teacherId_string(currentUser.id.toString())
        .teacher_videos._teacherVideoId(videoId.toString())
        .delete();

      if (response.status === 200) {
        // MEMO: 処理に成功したら、その場でstoreをresetする
        await mutate();

        addSuccessMessage("動画を削除しました");
      }

      setRemoveIsLoading(false);
    } catch {
      addErrorMessage("動画の削除に失敗しました");

      setRemoveIsLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setPrefectures(data.prefectures);
      setOrganizationCategories(data.organization_categories);
      setTags(data.tags);

      const profilePutDefaultValues = getProfileEditFormDefaultValue(data);
      methods.reset(profilePutDefaultValues);

      // MEMO: 動画アップロード部分はReact-hook-form化せず、aspidaのみの調整を施す方向
      setTeacherVideos(data.details.teacher_videos);
    }
  }, [data]);

  return {
    methods,
    updateProfile,
    isLoading,
    postTeacherVideo,
    removeTeacherVideo,
    postIsLoading,
    removeIsLoading
  };
};

export default useEditPageApi;
