import { useEffect } from "react";
import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import useQueryParams from "hooks/useQueryParams";
import { useAtom, useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentUserAtom } from "store/AuthStore";
import { paginationData } from "store/MasterData";
import { kindOfTeacherLessonsAtom, teacherLessonsAtom } from "./store";

type QueryParamsType = {
  pages?: number;
};

const useMyLessonsPageApi = () => {
  const currentUser = useAtomValue(currentUserAtom);
  const [kindOfTeacherLessons, setKindOfTeacherLessons] = useAtom(
    kindOfTeacherLessonsAtom
  );
  const [, setTeacherLessons] = useAtom(teacherLessonsAtom);
  const [, setPagination] = useAtom(paginationData);

  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const { getQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();

  const query = getQueryParams(["pages"]);

  useEffect(() => {
    deleteQueryParams("pages");
  }, [kindOfTeacherLessons]);

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(currentUser.id.toString())
      .lessons,
    {
      query: { status: kindOfTeacherLessons, pages: query.pages?.toString() },
      onSuccess: (data) => {
        setTeacherLessons(data);
        setPagination(data.pagination || null);
      },
      onError: () => {
        addErrorMessage("授業一覧の取得に失敗しました。");
      }
    }
  );

  return { isLoading, kindOfTeacherLessons, setKindOfTeacherLessons };
};

export default useMyLessonsPageApi;
