import React from "react";

import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

type RequestInfoItemProps = {
  title: string;
  value: string | React.ReactNode;
};

const RequestInfoItem: React.FC<RequestInfoItemProps> = (props) => {
  const theme = useTheme();
  const { title, value } = props;

  return (
    <>
      <Grid item xs={12} md={3}>
        <Typography color={theme.palette.sub.main}>{title}</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        {typeof value === "string" ? (
          <Typography sx={{ whiteSpace: "pre-wrap" }}>{value}</Typography>
        ) : (
          value
        )}
      </Grid>
    </>
  );
};

export default RequestInfoItem;
