import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import { useAtom, useAtomValue } from "jotai";
import aspida from "libs/aspida";
import { currentUserAtom } from "store/AuthStore";
import {
  teacherRequestsAtom,
  teacherRecruitmentsAtom,
  teacherLessonsAtom
} from "./store";

const useMyPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const currentUser = useAtomValue(currentUserAtom);
  const teacherId = currentUser.id.toString();
  const teacherProfileId = currentUser.teacher_profile_id.toString();

  const [, setteacherRequests] = useAtom(teacherRequestsAtom);
  const [, setTeacherRecruitments] = useAtom(teacherRecruitmentsAtom);
  const [, setTeacherLessons] = useAtom(teacherLessonsAtom);

  const { data, mutate } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(teacherId),
    {
      onError: () => {
        addErrorMessage("複業先生情報の取得に失敗しました");
      }
    }
  );

  const { isLoading: requestsIsLoading } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(teacherId)
      .lesson_requests,
    {
      query: {
        status: "only_request",
        limit: "3"
      },
      onSuccess: (data) => {
        setteacherRequests(data);
      },
      onError: () => {
        addErrorMessage("依頼の取得に失敗しました");
      }
    }
  );

  const { isLoading: recuitmentsIsLoading } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(teacherId)
      .lesson_recruitments,
    {
      query: {
        limit: "3"
      },
      onSuccess: (data) => {
        setTeacherRecruitments(data);
      },
      onError: () => {
        addErrorMessage("応募情報の取得に失敗しました");
      }
    }
  );

  const { isLoading: lessonsIsLoading } = useAspidaSWR(
    aspida(headers).api.v1.teachers._teacherId_string(teacherId).lessons,
    {
      query: {
        limit: "3"
      },
      onSuccess: (data) => {
        setTeacherLessons(data);
      },
      onError: () => {
        addErrorMessage("授業の取得に失敗しました");
      }
    }
  );

  const handleRejudge = async () => {
    try {
      const res = await aspida(headers)
        .api.v1.teacher_profiles._teacherProfileId(teacherProfileId)
        .judge_request.post({});
      await mutate();
      if (res.status === 200) {
        addSuccessMessage("再審査を依頼しました");
      }
    } catch (error) {
      addErrorMessage("再審査の処理に失敗しました");
    }
  };

  const judgeNg = data?.is_public_judge_ng;
  const isJudging = !judgeNg && !currentUser.is_public;
  const judgeNgReason = data?.public_judge_ng_reason;

  return {
    judgeNg,
    isJudging,
    judgeNgReason,
    handleRejudge,
    recuitmentsIsLoading,
    requestsIsLoading,
    lessonsIsLoading
  };
};

export default useMyPageApi;
