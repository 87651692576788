import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

export type LessonStatusLabelProps = {
  status: string;
};

const LessonStatusLabel: React.FC<LessonStatusLabelProps> = (props) => {
  const { status } = props;
  const theme = useTheme();

  const displayStatus = () => {
    switch (status) {
      case "suggest_meeting_date":
      case "select_meeting_date":
      case "adjust_meeting_place":
      case "before_meeting":
      case "preparation":
        return "授業準備";
      case "lesson_end":
        return "授業終了";
      case "canceled":
        return "キャンセル";
      case "completed":
        return "完了";
      default:
        return "";
    }
  };

  const getBackgroundColor = () => {
    // MEMO: 今後status, デザインカラーが増えた場合を考え、switch文で分岐
    switch (status) {
      case "suggest_meeting_date":
      case "select_meeting_date":
      case "adjust_meeting_place":
      case "before_meeting":
      case "preparation":
        return theme.palette.primary.main;
      case "canceled":
      case "lesson_end":
      case "completed":
        return theme.palette.muted?.main;
      default:
        return theme.palette.muted?.main;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: getBackgroundColor(),
        borderRadius: "4px",
        width: "5rem",
        padding: "0.2rem"
      }}
    >
      <Typography
        align="center"
        fontSize=".8rem"
        color={theme.palette.common.white}
        fontWeight="bold"
      >
        {displayStatus()}
      </Typography>
    </Box>
  );
};

export default LessonStatusLabel;
