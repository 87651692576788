import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import { useRef } from "react";
import { useHistory } from "react-router";

import Loading from "components/atoms/Loading";
import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import { currentSchoolAtom } from "store/SchoolAuthStore";
import { FormProvider } from "react-hook-form";
import FacePhotoCard from "./organisms/FacePhotoCard";
import SchoolInfoCard from "./organisms/SchoolInfoCard";
import SchoolTeacherInfoCard from "./organisms/SchoolTeacherInfoCard";
import SectionList from "./organisms/SectionList";

import useEditPageApi from "./useApi";

const Edit: React.FC = () => {
  const [currentSchool] = useAtom(currentSchoolAtom);

  const theme = useTheme();
  const history = useHistory();

  const FacePhotoRef = useRef<HTMLDivElement>(null);
  const SchoolInfoRef = useRef<HTMLDivElement>(null);
  const SchoolTeacherInfoRef = useRef<HTMLDivElement>(null);

  const { methods, handleSubmit, getIsLoading, postIsLoading } = useEditPageApi(
    currentSchool.school_teacher_profile_id
  );

  const handleSectionChange = (
    editSectionRef: React.RefObject<HTMLDivElement>
  ) => {
    editSectionRef?.current?.scrollIntoView({ behavior: "smooth" });
    /*
    FIXME: 以下の記述を使うとヘッダーがかぶらないように設定できるが、サイドメニューを固定するためにposition: fixedやabsoluteをかけると途端に荒ぶる。

    const elementPosition = editSectionRef?.current?.getBoundingClientRect().top;
    const offsetPosition = elementPosition ? elementPosition - 72 : 0;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    }); */
  };

  const handleCancel = () => {
    history.push("/schools/mypage");
  };

  return (
    <CommonLayout>
      <Meta title="複業先生｜プロフィール編集" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          paddingTop: "1.8rem"
        }}
      >
        {(() => {
          if (!postIsLoading && !getIsLoading) {
            return (
              <Container>
                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: { xs: "block", md: "none" }
                    }}
                  >
                    <Typography variant="h2">プロフィールを編集</Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{
                      display: { xs: "none", md: "block" }
                    }}
                  >
                    <SectionList
                      FacePhotoRef={FacePhotoRef}
                      SchoolInfoRef={SchoolInfoRef}
                      SchoolTeacherInfoRef={SchoolTeacherInfoRef}
                      handleSectionChange={handleSectionChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    sx={{ marginTop: { xs: "-1.5rem", md: "inherit" } }}
                  >
                    <FormProvider {...methods}>
                      <form onSubmit={handleSubmit}>
                        <FacePhotoCard editSectionRef={FacePhotoRef} />
                        <SchoolInfoCard editSectionRef={SchoolInfoRef} />
                        <SchoolTeacherInfoCard
                          editSectionRef={SchoolTeacherInfoRef}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            margin: "1rem auto"
                          }}
                        >
                          <Box sx={{ flex: { xs: 5, md: 4 } }}>
                            <Button
                              fullWidth
                              color="inherit"
                              variant="contained"
                              size="large"
                              sx={{
                                backgroundColor: theme.palette.common.white
                              }}
                              onClick={handleCancel}
                            >
                              キャンセル
                            </Button>
                          </Box>
                          <Box sx={{ flex: { xs: 7, md: 8 } }}>
                            <FormSubmitButton label="保存する" fullWidth />
                          </Box>
                        </Box>
                      </form>
                    </FormProvider>
                  </Grid>
                </Grid>
              </Container>
            );
          }
          return <Loading headerOnly />;
        })()}
      </Box>
    </CommonLayout>
  );
};

export default Edit;
