import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";

import type React from "react";
import { ratingAtom } from "../store";

import TotalRating from "./TotalRating";
import TotalRatingsItem from "./TotalRatingsItem";

const TotalRatingsBlockSp: React.FC = () => {
  const theme = useTheme();
  const rating = useAtomValue(ratingAtom);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.backgroundColor?.main,
        padding: { xs: ".5rem 1rem", sm: ".5rem 2.5rem" }
      }}
    >
      <Box
        sx={{
          margin: "1rem auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <TotalRating rating={rating?.total_rating || 0} />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "1rem auto",
          gap: "0 1rem"
        }}
      >
        <TotalRatingsItem
          rating={rating?.total_lesson_rating || 0}
          ratingName="授業の満足度"
        />
        <TotalRatingsItem
          rating={rating?.total_teacher_rating || 0}
          ratingName="講師への評価"
        />
      </Box>
    </Box>
  );
};

export default TotalRatingsBlockSp;
