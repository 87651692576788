import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import SendIcon from "components/atoms/SendIcon";

import type React from "react";

interface ThanksAnswerBlockProps {
  title: string;
  descriptions: React.ReactNode;
}

const ThanksAnswerBlock: React.FC<ThanksAnswerBlockProps> = (props) => {
  const { title, descriptions } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100%"
      }}
    >
      <Grid item xs={10} sm={9} md={7}>
        <SendIcon />
        <Typography
          align="center"
          variant="h1"
          sx={{
            margin: "1rem auto",
            width: "100%"
          }}
        >
          {title}
        </Typography>
        <div>{descriptions}</div>
      </Grid>
    </Grid>
  );
};

export default ThanksAnswerBlock;
