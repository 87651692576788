import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import type React from "react";
import { useHistory } from "react-router-dom";
import Button from "components/atoms/mui/Button";
import { teachingPlanResponseAtom } from "../../new/store";

const AiTeachingPlanDisplay: React.FC = () => {
  const theme = useTheme();
  const teachingPlanResponse = useAtomValue(teachingPlanResponseAtom);
  const history = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        backgroundColor: theme.palette.common.white
      }}
    >
      <Typography variant="h3">AIによる授業内容のご提案</Typography>
      <Typography fontSize=".9rem" color={theme.palette.sub.main}>
        こちらはChatGPTのモデルを利用して生成されたのものになっています。
        <br />
        文章が不十分な点もありますが、授業内容を考える際の参考になれば幸いです。
      </Typography>
      <Typography sx={{ whiteSpace: "pre-wrap" }}>
        {teachingPlanResponse}
      </Typography>
      <Button
        color="primary"
        fullWidth
        variant="contained"
        onClick={() => history.push("/teachers")}
      >
        複業先生を探してみる
      </Button>
      <Button
        variant="contained"
        color="inherit"
        fullWidth
        // まずは相談してみる フォームへのリンク
        onClick={() => history.push("/schools/mypage/ai-teaching-plan/new")}
      >
        もう一度授業案を作成する
      </Button>
    </Box>
  );
};

export default AiTeachingPlanDisplay;
