import { atom } from "jotai";

import { Methods } from "api/api/v1/teachers/lp_classi";

export type exampleTeacherTypes = {
  teacher_id: number;
  teacher_name: string;
  organization_name?: string;
  teacher_image: {
    url: string;
  };
};

export const exampleTeachersAtom = atom<Methods["get"]["resBody"]["data"]>([]);
