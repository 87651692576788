import { Box } from "@mui/material";
import FormTextArea from "components/molecules/FormTextArea";

import StepTitle from "features/teachers/profile/new/atoms/StepTitle";

import type React from "react";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import {
  allowOptions,
  knowFromOptions,
  rewardOptions,
  mailMagazineOptions
} from "constants/selectOptions";
import IntroductionText from "../molecules/InctoductionText";

const NewProfileFormStep4: React.FC = () => (
  <Box
    component="section"
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
      padding: "0.5rem 0"
    }}
  >
    <StepTitle
      stepName={["最後に自己紹介の作成をお願いします。"]}
      indicateStatus="public"
      supplementMsg="本登録後の変更もできるので、まずは気軽に記入してみてください。"
    />
    <FormTextArea
      name="enthusiasm"
      label="意気込みを一言で（50文字以内）"
      placeholder="例：自分たちが今生きている場所が全てではない、そこからさらに面白い世界や未来が広がっていることを伝えたい"
      isRequired
      maxCharacters={50}
    />
    <FormTextArea
      name="introduction"
      label="講師紹介文"
      isRequired
      supplementalText={<IntroductionText />}
      placeholder="例：実績やバックグラウンドなど"
    />
    <FormRadioGroup
      name="is_reward"
      label="報酬の有無"
      options={rewardOptions}
      isRequired
      supplementalText="報酬なしを選択した場合は、地域のNPO等に寄付されます。"
    />
    <FormRadioGroup
      name="can_interview"
      label="メディアからの取材"
      options={allowOptions}
      isRequired
      supplementalText="許可するを選択した場合、LX事務局を通じて、授業に取材が入ることがあります。"
    />
    <FormRadioGroup
      name="is_mail_magazine"
      label="メールマガジンの配信"
      options={mailMagazineOptions}
      isRequired
      supplementalText="イベントなど、LX DESIGN（『複業先生』事務局）からのお知らせ・情報が届きます。『複業先生』プラットフォームから自動的に配信されるメールは停止されません。例＞授業募集　等"
    />
    <FormTextArea
      name="register_reason"
      label="複業先生に登録した理由やきっかけを教えて下さい（200文字以内）"
      placeholder=""
      isRequired
      maxCharacters={200}
    />
    <FormRadioGroup
      name="know_from"
      label="「複業先生」をどのように知りましたか？"
      options={knowFromOptions}
      row
    />
  </Box>
);

export default NewProfileFormStep4;
