import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import Link from "components/atoms/mui/Link";

import type React from "react";

export type TextWithLinkItemType =
  | { kindOf: "text"; content: string }
  | { kindOf: "link"; content: string; link: string; isExternal?: boolean };

type PreListItemProps = {
  title: Array<TextWithLinkItemType>;
  text?: string;
};

const PreListItemWithLink: React.FC<PreListItemProps> = (props) => {
  const { title, text } = props;

  const getTextWithLinkItem = (item: TextWithLinkItemType) => {
    switch (item.kindOf) {
      case "text":
        return (
          <Typography component="span" sx={{ verticalAlign: "middle" }}>
            {item.content}
          </Typography>
        );
      case "link":
        return (
          <Link href={item.link} newTab={item.isExternal}>
            {item.content}
          </Link>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", margin: "1rem auto" }}>
      <DoneOutlineIcon color="primary" sx={{ marginRight: "1rem" }} />
      <Typography sx={{ display: "flex", alignItems: "center" }}>
        {title.map((item) => getTextWithLinkItem(item))}
        {text && (
          <Typography sx={{ whiteSpace: "pre-wrap" }}>{text}</Typography>
        )}
      </Typography>
    </Box>
  );
};

export default PreListItemWithLink;
