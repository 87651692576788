import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import type React from "react";
import { teacherInfoAtom } from "../store";

import WebsiteLinkBox from "../atoms/WebsiteLinkBox";

const WebsitesBox: React.FC = () => {
  const teacherInfo = useAtomValue(teacherInfoAtom);

  return (
    <Box
      sx={{
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem"
      }}
    >
      {teacherInfo?.websites.map((website, index) => (
        <WebsiteLinkBox key={index} title={website.title} url={website.url} />
      ))}
    </Box>
  );
};

export default WebsitesBox;
