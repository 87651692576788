import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { useAtomValue } from "jotai";
import React from "react";

import CommonMypageHomeSectionTitle from "components/molecules/CommonMypageHomeSectionTitle";
import RequestItem from "features/teachers/mypage/index/molecules/RequestItem";
import CommonLoading from "components/atoms/CommonLoading";
import NoContentItem from "features/teachers/mypage/index/molecules/NoContentItem";

import RequestIcon from "assets/common/sidebar/unselected/teachers/request.svg";
import { checkDeadline } from "utils/date";
import { teacherRequestsAtom } from "../store";

interface HomeRequestsProps {
  isLoading: boolean;
}

const HomeRequests: React.FC<HomeRequestsProps> = ({ isLoading }) => {
  const theme = useTheme();
  const teacherRequests = useAtomValue(teacherRequestsAtom);
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        width: "100%",
        height: "100%"
      }}
    >
      <CommonMypageHomeSectionTitle
        Icon={<img src={RequestIcon} alt="授業依頼" />}
        title="授業依頼"
        count={teacherRequests?.data_count || 0}
        linkName="すべての依頼を見る"
        link="/teachers/mypage/requests"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
          backgroundColor: theme.palette.common.white,
          width: "100%",
          height: "100%"
        }}
      >
        {!isLoading ? (
          teacherRequests && teacherRequests.data.length > 0 ? (
            teacherRequests.data.map((teacherRequest, index) => {
              const { limit } = checkDeadline(
                teacherRequest.details.accept_dead_at
              );

              return (
                <React.Fragment key={index}>
                  <RequestItem
                    id={teacherRequest.details.id}
                    schoolName={teacherRequest.send_user.school_name}
                    schoolTeacherName={teacherRequest.send_user.name}
                    schoolTeacherImage={teacherRequest.send_user.image.url}
                    isCertification={teacherRequest.send_user.is_match_accepted}
                    limit={limit}
                    step={teacherRequest.details.step}
                  />
                  <Divider sx={{ width: "100%" }} />
                </React.Fragment>
              );
            })
          ) : (
            <NoContentItem
              title="まだ授業依頼はありません"
              lead="講師紹介文を充実させると依頼がきやすくなります。"
              link="/teachers/profile/edit"
              buttonText="プロフィールを編集する"
            />
          )
        ) : (
          <CommonLoading />
        )}
      </Box>
    </Box>
  );
};

export default HomeRequests;
