import * as yup from "yup";

import {
  updateLessonFormSchemaBase,
  updateLessonFormSchemaType
} from "constants/UpdateLessonFormSchemaBase";

export const patchMeetingUrlFormSchema: yup.ObjectSchema<updateLessonFormSchemaType> =
  updateLessonFormSchemaBase.concat(
    yup.object().shape({
      meeting_url: yup
        .string()
        .required("打ち合わせを行うURLを入力してください")
        .url("正しいURLを入力してください。")
    })
  );

export const patchMeetingPlaceFormSchema: yup.ObjectSchema<updateLessonFormSchemaType> =
  updateLessonFormSchemaBase.concat(
    yup.object().shape({
      meeting_place: yup.string().required("打ち合わせ場所を入力してください")
    })
  );
