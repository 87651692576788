import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";

import type React from "react";
import { CommonDialogProps } from "types/commonDialogProps";
import Button from "components/atoms/mui/Button";
import CommonDialog from "components/molecules/CommonDialog";

type CancelModalProps = {
  cancelRecruitment: () => void;
} & CommonDialogProps;

const CancelModal: React.FC<CancelModalProps> = (props) => {
  const { cancelRecruitment, isOpen, onClose } = props;
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleCancel = () => {
    cancelRecruitment();
    onClose();
  };

  return (
    <CommonDialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem"
        }}
      >
        <Typography align="center" fontWeight="bold">
          キャンセルした募集は元に戻せません。
          <br />
          本当にキャンセルしますか？
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexDirection: { xs: "column-reverse", sm: "row" }
          }}
        >
          <Button
            variant={isMobile ? "text" : "outlined"}
            onClick={onClose}
            fullWidth
          >
            戻る
          </Button>
          <Button variant="contained" onClick={handleCancel} fullWidth>
            募集をキャンセルする
          </Button>
        </Box>
      </Box>
    </CommonDialog>
  );
};

export default CancelModal;
