export const beforeQuestionnairesProductionFormKeys = {
  formUrl:
    "https://docs.google.com/forms/u/0/d/e/1FAIpQLScbXvUgluzS-A0y4EvMphkB7IXbt1B46o57_FYtr7_MwpS6IA/formResponse",
  lessonRequestIdKeys: "entry.583827525",
  schoolPrefectureNameKeys: "entry.699512014",
  schoolNameKeys: "entry.1960217994",
  // 以下、入力値のキー
  schoolClassKeys: "entry.835137462",
  studentIDNumberKeys: "entry.1892306227",
  isSurveyKeys: "entry.1871998813",
  howMuchFunKeys: "entry.609639225",
  wantToLearnKeys: "entry.783041301"
};

export const beforeQuestionnairesDevelopmentFormKeys = {
  formUrl:
    "https://docs.google.com/forms/u/0/d/e/1FAIpQLSf-TFNfDERT6MibcZVry_QcB-SJugnkF_S_v8QU8kEC5H0Kvw/formResponse",
  lessonRequestIdKeys: "entry.583827525",
  schoolPrefectureNameKeys: "entry.699512014",
  schoolNameKeys: "entry.1960217994",
  // 以下、入力値のキー
  schoolClassKeys: "entry.835137462",
  studentIDNumberKeys: "entry.1892306227",
  isSurveyKeys: "entry.1871998813",
  howMuchFunKeys: "entry.609639225",
  wantToLearnKeys: "entry.783041301"
};
