import { katakanaSchema } from "utils/yupUtils";
import * as yup from "yup";

// FormDataに送信する前、react-hook-formに使用しているキー
export type ContactFormType = {
  name: string;
  kana_name: string;
  email: string;
  contactType: string;
  contactContents: string;
};

const contactFormSchema = yup.object().shape({
  name: yup.string().required("必須の項目です"),
  kana_name: katakanaSchema.required("必須の項目です"),
  email: yup
    .string()
    .email("メールアドレスの形式が正しくありません")
    .required("必須の項目です"),
  contactType: yup.string().required("必須の項目です"),
  contactContents: yup.string().required("必須の項目です")
});

export default contactFormSchema;
