import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import RatingDisplay from "features/teachers/id/atoms/RatingDisplay";

import type React from "react";

type TotalRatingItemType = {
  rating: number;
  ratingName: string;
};

const TotalRatingsItem: React.FC<TotalRatingItemType> = (props) => {
  const { rating, ratingName } = props;

  return (
    <div>
      <Typography fontSize=".9rem" sx={{ marginBottom: ".25rem" }}>
        {ratingName}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0 .5rem"
        }}
      >
        <RatingDisplay rating={rating / 10} />
        <Typography>{rating ? (rating / 10).toFixed(1) : "-"}</Typography>
      </Box>
    </div>
  );
};

export default TotalRatingsItem;
