import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Meta from "components/organisms/Meta";

import useQueryParams from "hooks/useQueryParams";
import CommonLayout from "components/layouts/CommonLayout";

import {
  teacherProfileAtom,
  lessonOptionsAtom
} from "features/requests/new/store";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import useNewPageApi from "features/requests/new/useApi";

import newFormSchema, {
  NewFormSchemaType
} from "features/requests/new/constants/newFormSchema";
import newFormDefaultValues from "features/requests/new/constants/newFormDefaultValues";
import { useAlertMessages } from "hooks/useAlertMessages";
import TeacherCard from "./organisms/TeacherCard";
import PossibleDateForm from "./organisms/PossibleDateForm";
import RequestForm from "./organisms/RequestForm";
import PriceBox from "./organisms/PriceBox";
import FirstView from "./organisms/FirstView";

type QueryParamsType = {
  id?: number;
};

const New: React.FC = () => {
  const { getQueryParams } = useQueryParams<QueryParamsType>();
  const [teacherProfile] = useAtom(teacherProfileAtom);
  const [lesson_options] = useAtom(lessonOptionsAtom);
  const { id } = getQueryParams(["id"]);
  const { postNewRequest, postGenerateTeachingPlan, getIsLoading } =
    useNewPageApi(id || 0);
  const theme = useTheme();

  const history = useHistory();
  const { addErrorMessage } = useAlertMessages();

  const [currentSchool] = useAtom(currentSchoolAtom);

  const methods = useForm<NewFormSchemaType>({
    resolver: yupResolver(newFormSchema),
    mode: "onChange",
    defaultValues: newFormDefaultValues
  });

  const onSubmit = methods.handleSubmit(async (data) => {
    const { response, error } = await postNewRequest(data);
    if (error) {
      addErrorMessage("リクエストの送信に失敗しました。");
      return;
    }
    history.push("/requests/thanks-request");
    if (response && response.body.details?.id) {
      const { error } = await postGenerateTeachingPlan(
        response.body.details.id.toString()
      );
      if (error) {
        addErrorMessage("授業案の作成に失敗しました。");
      }
    }
  });

  useEffect(() => {
    methods.setValue("teacher_id", teacherProfile?.teacher_id || 0);
    !currentSchool.is_match_accepted &&
      methods.setValue(
        "lesson_option_ids",
        lesson_options.map((option) => option.id)
      );
  }, [teacherProfile?.teacher_id]);

  return (
    <CommonLayout isLoading={getIsLoading}>
      <Meta />
      <FirstView />
      <Box sx={{ backgroundColor: theme.palette.backgroundColor?.main }}>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Container maxWidth="md">
              <Grid container>
                <Grid item xs={12} md={8}>
                  <Box
                    sx={{
                      padding: "1.5rem 0",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem"
                    }}
                  >
                    <TeacherCard />
                    <PossibleDateForm />
                    <RequestForm />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <PriceBox handleSubmit={onSubmit} />
                </Grid>
              </Grid>
            </Container>
          </form>
        </FormProvider>
      </Box>
    </CommonLayout>
  );
};

export default New;
