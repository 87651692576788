import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import useConvertFileSize from "hooks/useConvertFileSize";

import type React from "react";
import { fileType } from "types/fileType";
import { CommonDialogProps } from "types/commonDialogProps";
import CommonDialog from "components/molecules/CommonDialog";
import Button from "components/atoms/mui/Button";
import { Stack } from "@mui/material";

type FileDownloadModalProps = {
  file: fileType;
} & CommonDialogProps;

const DownloadCheckModal: React.FC<FileDownloadModalProps> = (props) => {
  const { isOpen, onClose, file } = props;
  const convertFileSize = useConvertFileSize();

  const handleDownload = () => {
    window.open(file.url, "_blank");
    onClose();
  };

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <Stack gap="1rem" alignItems="center">
        <Typography>
          <Box component="span" sx={{ display: "inline-block" }}>
            以下のファイルを
          </Box>
          <Box component="span" sx={{ display: "inline-block" }}>
            ダウンロードします。
          </Box>
          <br />
          <Box component="span" sx={{ display: "inline-block" }}>
            よろしいですか？
          </Box>
        </Typography>
        <Typography fontWeight="bold">
          {file.name}（{convertFileSize(file.size)}）
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem"
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            キャンセル
          </Button>
          <Button variant="contained" color="primary" onClick={handleDownload}>
            ダウンロード
          </Button>
        </Box>
      </Stack>
    </CommonDialog>
  );
};

export default DownloadCheckModal;
