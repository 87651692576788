// MEMO: textに手を入れるコードをまとめる

export const shortSentences = (
  sentences: string,
  customLength?: number,
  customRow?: number
) => {
  const maxLength = customLength || 100;
  const displayRow = customRow || 3;

  // 文字数制限
  if (sentences.length > maxLength) {
    return `${sentences.substring(0, maxLength)}...`;
  }

  // 行数制限
  const lines = sentences.split("\n");
  if (lines.length > displayRow) {
    return `${lines.slice(0, displayRow).join("\n")}...`;
  }

  return sentences;
};

// MEMO: テキストからキーワードのインデックスを取得する
// FIXME: 明らかにゴミコードなので、リファクタリングが必要
export const findKeywordsIndex = (
  text: string,
  keywords: string[]
): number[] => {
  const limitedKeywords = keywords.slice(0, 3);
  const indexWithKeywordArray: Array<{ keyword: string; index: number }> = [];

  const searchOrRemove = (keyword: string, startPoint: number) => {
    const keywordIndex = text.indexOf(keyword, startPoint);

    if (keywordIndex !== -1) {
      indexWithKeywordArray.push({ keyword, index: keywordIndex });
    } else {
      limitedKeywords.splice(limitedKeywords.indexOf(keyword), 1);
    }
  };

  while (indexWithKeywordArray.length < 3 && limitedKeywords.length > 0) {
    limitedKeywords.forEach((keyword) => {
      // MEMO: 最後の検索結果から検索を再開する
      const startPoint = indexWithKeywordArray.findLast(
        (item) => item.keyword === keyword
      )?.index;
      searchOrRemove(keyword, startPoint ? startPoint + 1 : 0);
    });
  }

  const limitedIndexWithKeywordArray = indexWithKeywordArray.slice(0, 3);

  return limitedIndexWithKeywordArray.map((item) => item.index);
};

// MEMO: Indexの配列をマージし、取得文字の箇所を指定する
// MEMO: 対象となる各スタート地点の前後の文字を取得する（1：50文字、2：25文字、3：15文字）
export const mergeIndex = (indexArray: number[], text: string): number[][] => {
  const mergedIndexArray: number[][] = [];
  // MEMO: インデックスを昇順にソートし、重複を削除する

  const getStartEnd = (index: number, range: number, length: number) => {
    if (length === 1 && index < 50) {
      mergedIndexArray.push([0, 99]);
      return;
    }
    mergedIndexArray.push([
      Math.max(0, index - range),
      Math.min(index + range, text.length)
    ]);
  };

  const sortedIndexArray = indexArray
    .sort((a, b) => a - b)
    .filter((value, index, self) => self.indexOf(value) === index);

  switch (sortedIndexArray.length) {
    case 1:
      getStartEnd(sortedIndexArray[0], 50, sortedIndexArray.length);
      break;
    case 2:
      sortedIndexArray.forEach((index) => {
        getStartEnd(index, 25, sortedIndexArray.length);
      });
      break;
    case 3:
      sortedIndexArray.forEach((index) => {
        getStartEnd(index, 15, sortedIndexArray.length);
      });
      break;
    default:
      break;
  }

  // MEMO: 重複する箇所をマージする
  for (let i = 0; i < mergedIndexArray.length - 1; i++) {
    if (mergedIndexArray[i][1] > mergedIndexArray[i + 1][0]) {
      const [, end] = mergedIndexArray[i + 1];
      mergedIndexArray[i][1] = end;
      mergedIndexArray.splice(i + 1, 1);
      i--;
    }
  }

  return mergedIndexArray;
};

export const highlightResult = (
  mergedIndexArray: number[][],
  text: string
): string => {
  // mergedIndexArrayの要素で文字列を取得し、3点リーダで結合。
  const { start, end } = mergedIndexArray.flat().reduce(
    (acc, current) => ({
      start: Math.min(acc.start, current),
      end: Math.max(acc.end, current)
    }),
    { start: text.length, end: 0 }
  );

  const mergedString = mergedIndexArray
    .map(([start, end]) => text.substring(start, end))
    .join("...");

  return `${start > 0 ? "..." : ""}${mergedString}${end < text.length ? "..." : ""}`;
};
