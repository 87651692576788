import { useAtom } from "jotai";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";

import useHeaders from "hooks/useHeaders";
import { schoolTeacherCommonDataAtom } from "store/SchoolTeacherCommonDataStore";

const useSchoolTeacherCommonData = () => {
  const [schoolIsLoggedIn] = useAtom(schoolIsLoggedInAtom);
  const [, setSchoolTeacherCommonData] = useAtom(schoolTeacherCommonDataAtom);
  const headers = useHeaders();

  const { mutate } = useAspidaSWR(
    aspida(headers).api.v1.tools.school_teacher_common_data,
    {
      enabled: schoolIsLoggedIn,
      onSuccess: (data) => {
        setSchoolTeacherCommonData(data);
      }
    }
  );

  return { mutate };
};

export default useSchoolTeacherCommonData;
