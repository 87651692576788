import { FiberManualRecord } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import Button from "components/atoms/mui/Button";
import CommonDialog from "components/molecules/CommonDialog";
import { useAlertMessages } from "hooks/useAlertMessages";
import { useRef, useEffect, useState } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import { CommonDialogProps } from "types/commonDialogProps";
import { postTeacherVideo } from "../types/teacherVideoApiTypes";

type ProfileVideoRecorderDialogProps = {
  onSubmit: postTeacherVideo;
} & CommonDialogProps;

const ProfileVideoRecorderDialog = (props: ProfileVideoRecorderDialogProps) => {
  const { isOpen, onClose, onSubmit } = props;
  const videoPreviewRef = useRef<HTMLVideoElement | null>(null);
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);
  const [countdownTime, setCountdownTime] = useState<number | null>(null);
  const { addErrorMessage } = useAlertMessages();

  const startCountdown = (startRecording: () => void) => {
    let countdown = 3; // 3秒のカウントダウン
    setCountdownTime(countdown);
    const interval = setInterval(() => {
      countdown -= 1;
      setCountdownTime(countdown);
      if (countdown === 0) {
        startRecording(); // カウントダウン終了後に録画を開始
        clearInterval(interval);
        setCountdownTime(null);
      }
    }, 1000);
  };

  return (
    <CommonDialog open={isOpen} onClose={onClose}>
      <ReactMediaRecorder
        video
        audio
        render={({ status, startRecording, stopRecording, mediaBlobUrl }) => {
          useEffect(() => {
            // カメラ映像を取得してプレビューに設定
            const startCamera = async () => {
              try {
                const stream = await navigator.mediaDevices.getUserMedia({
                  video: { aspectRatio: 16 / 9 } // 縦横比を16:9に設定
                });
                setCameraStream(stream);
                if (videoPreviewRef.current) {
                  videoPreviewRef.current.srcObject = stream;
                }
              } catch (err) {
                addErrorMessage("カメラの起動に失敗しました");
              }
            };

            startCamera();

            // クリーンアップ時にカメラを停止
            return () => {
              if (cameraStream) {
                cameraStream.getTracks().forEach((track) => track.stop());
              }
            };
          }, [status, !!countdownTime]);

          return (
            <Stack spacing={2} pt={4}>
              {countdownTime !== null && (
                <Typography variant="h2" align="center">
                  {countdownTime}
                </Typography>
              )}
              {status === "recording" && (
                <Stack direction="row" alignItems="center">
                  <FiberManualRecord color="error" sx={{ mr: 1 }} />
                  <Typography variant="body2">撮影中</Typography>
                </Stack>
              )}
              {/* 撮影中のプレビュー */}
              {(status !== "stopped" || countdownTime) && (
                <video
                  ref={videoPreviewRef}
                  autoPlay
                  playsInline
                  muted // 自分の声をハウリングさせないためにミュート
                  style={{
                    width: "100%",
                    height: "auto",
                    aspectRatio: "16/9", // 縦横比を16:9に指定
                    backgroundColor: "#000" // 黒背景を設定
                  }}
                />
              )}
              <Stack width="100%" direction="row" gap={2}>
                <Button
                  onClick={() => startCountdown(startRecording)}
                  fullWidth
                  disabled={status === "recording" || countdownTime !== null}
                  color={status === "stopped" ? "inherit" : "primary"}
                >
                  {status === "stopped" ? "再撮影" : "撮影開始"}
                </Button>
                <Button
                  onClick={stopRecording}
                  color="inherit"
                  fullWidth
                  disabled={status !== "recording" || countdownTime !== null}
                >
                  撮影終了
                </Button>
              </Stack>
              {/* 録画後のビデオ再生 */}
              {status === "stopped" && mediaBlobUrl && !countdownTime && (
                <Stack>
                  <Typography variant="h4">プレビュー</Typography>
                  <video
                    src={mediaBlobUrl}
                    controls
                    style={{ width: "100%", marginTop: "10px" }}
                  />
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                    my={2}
                  >
                    <Button
                      onClick={async () => {
                        const file = await fetch(mediaBlobUrl)
                          .then((res) => res.blob())
                          .then(
                            (blob) =>
                              new File([blob], "self_introduction.mp4", {
                                type: "video/mp4"
                              })
                          );

                        onSubmit({
                          file,
                          key: "self_introduction",
                          file_name: "self_introduction"
                        });
                        onClose();
                      }}
                    >
                      アップロード
                    </Button>
                  </Box>
                </Stack>
              )}
            </Stack>
          );
        }}
      />
    </CommonDialog>
  );
};

export default ProfileVideoRecorderDialog;
