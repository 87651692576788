import { atom } from "jotai";

import { Methods } from "api/api/v1/school_teachers/_schoolTeacherId@string/lesson_recruitments";

export type kindOfSchoolRecruitmentsType =
  | ""
  | "select_candidate"
  | "recruting"
  | "closed_or_canceled";

export const schoolRecruitmentsAtom = atom<Methods["get"]["resBody"] | null>(
  null
);
export const kindOfSchoolRecruitmentsAtom =
  atom<kindOfSchoolRecruitmentsType>("");
