import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type React from "react";
import AnswerField from "../molecules/AnswerField";
import AnswerResult from "../molecules/AnswerResult";

type PartProps = {
  isResult?: boolean;
};

const Part2: React.FC<PartProps> = (props) => {
  const { isResult } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Typography fontSize="1.2rem" fontWeight="bold">
        ②授業の準備では、
      </Typography>
      <Typography sx={{ lineHeight: "2rem !important" }}>
        <span>授業内容の</span>
        {isResult ? (
          <AnswerResult index={4} />
        ) : (
          <AnswerField index={4} wordCount={4} />
        )}
        <span>
          や授業の目的を意識し、授業のゴールから逆算して話の流れを組み立てましょう。
        </span>
        <br />
        <span>学年に応じた</span>
        {isResult ? (
          <AnswerResult index={5} />
        ) : (
          <AnswerField index={5} wordCount={2} />
        )}
        <span>
          選びや表現を意識し、どんな表現であれば分かりやすく伝わるのか、自分の言葉にして語りましょう。
        </span>
        <br />
        <span>専門用語や長文を避け、視覚的な要素を取り入れるなど、資料の</span>
        {isResult ? (
          <AnswerResult index={6} />
        ) : (
          <AnswerField index={6} wordCount={4} />
        )}
        <span>を意識することで、興味を引きつける授業が実現します。</span>
      </Typography>
    </Box>
  );
};

export default Part2;
