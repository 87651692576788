import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";

import { currentSchoolAtom } from "store/SchoolAuthStore";

import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";

import { Methods } from "api/api/v1/lesson_requests/_lessonRequestId@string";
import { detailsAtom, teacherAtom, supporterAtom } from "./store";

export type requestCancelDataType = Methods["put"]["reqBody"];

const useShowPageApi = (lessonRequestId: string) => {
  const headers = useHeaders();
  const { addErrorMessage, addSuccessMessage } = useAlertMessages();
  const currentSchool = useAtom(currentSchoolAtom)[0];
  const [, setDetails] = useAtom(detailsAtom);
  const [, setTeacher] = useAtom(teacherAtom);
  const [, setSupporter] = useAtom(supporterAtom);

  const { isLoading: getRequestIsLoading, mutate: mutateRequest } =
    useAspidaSWR(
      // eslint-disable-next-line no-underscore-dangle
      aspida(headers)
        .api.v1.school_teachers._schoolTeacherId_string(
          currentSchool?.id.toString()
        )
        .lesson_requests._lessonRequestId(lessonRequestId),
      {
        onSuccess: (data) => {
          setDetails(data.details);
          setTeacher(data.teacher);
          setSupporter(data.supporter);
        },
        onError: () => {
          addErrorMessage("依頼情報の取得に失敗しました");
        }
      }
    );

  const requestCancel = async (data: requestCancelDataType) => {
    try {
      await aspida(headers)
        .api.v1.lesson_requests._lessonRequestId_string(lessonRequestId)
        .put({ body: data });

      mutateRequest();

      addSuccessMessage("依頼をキャンセルしました");
    } catch (error) {
      addErrorMessage("依頼のキャンセルに失敗しました");
    }
  };

  return {
    getRequestIsLoading,
    requestCancel
  };
};

export default useShowPageApi;
