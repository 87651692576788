import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import Button from "components/atoms/mui/Button";
import { currentUserAtom } from "store/AuthStore";
import { useAtom, useAtomValue } from "jotai";
import { recruitmentAtom } from "features/recruitments/id/store";

const CandidateCreateFormOverlay: React.FC = () => {
  const [currentUser] = useAtom(currentUserAtom);
  const isIdentification = currentUser?.is_identification;
  const isPublic = currentUser?.is_public;
  const recruitment = useAtomValue(recruitmentAtom);
  const step = recruitment?.step || "";

  const reason = () => {
    if (!isPublic) {
      return "現在登録内容の確認中です";
    }
    if (!isIdentification) {
      return "本人確認が完了していません";
    }
    if (step === "canceled") {
      return "募集がキャンセルされました";
    }
    if (
      [
        "closed",
        "no_candidate",
        "early_closed",
        "candidate_select",
        "accepted",
        "early_accepted"
      ].includes(step)
    ) {
      return "この募集は終了しています";
    }
    return "";
  };

  const theme = useTheme();

  if (!reason()) {
    return null;
  }
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 100,
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.common.white,
        opacity: 0.8,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px"
      }}
    >
      <Typography fontSize="1.2rem" fontWeight="bold">
        {reason()}
      </Typography>
      {!isIdentification && (
        <Button
          variant="text"
          color="secondary"
          href="/teachers/mypage/settings?accordion=identification-check"
          size="small"
          sx={{
            fontWeight: "bold",
            padding: ".25rem 1rem"
          }}
        >
          本人確認へ
        </Button>
      )}
    </Box>
  );
};

export default CandidateCreateFormOverlay;
