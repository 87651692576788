import defaultDayjs, { ConfigType } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";

// プラグインを拡張
defaultDayjs.extend(utc);
defaultDayjs.extend(timezone);
defaultDayjs.extend(isBetween);

// 日本時間（Asia/Tokyo）をデフォルトのタイムゾーンとして設定
defaultDayjs.tz.setDefault("Asia/Tokyo");

// dayjsを拡張し、日本時間への自動変換を行うラッパー関数を作成
const dayjs = (date?: ConfigType) => defaultDayjs(date).tz("Asia/Tokyo");

export default dayjs;
