import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import EditableText from "components/atoms/lessons/EditableText";
import Button from "components/atoms/mui/Button";
import FormDatetime from "components/molecules/FormDatetime";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import dayjs from "libs/dayjs";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formatDateTimeStringWithDay } from "utils/date";
import { futureDateStringSchema } from "utils/yupUtils";
import * as yup from "yup";

interface LessonDateFieldProps {
  exact_start_at?: string;
  onSubmit: (data: { exact_start_at: string }) => void;
  disabled?: boolean;
}

const LessonDateField: React.FC<LessonDateFieldProps> = ({
  exact_start_at,
  disabled,
  onSubmit
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const dateString = exact_start_at
    ? `${formatDateTimeStringWithDay(dayjs(exact_start_at))} -`
    : undefined;
  const form = useForm({
    resolver: yupResolver(
      yup.object().shape({
        exact_start_at:
          futureDateStringSchema.required("授業日時を入力してください")
      })
    )
  });
  const { reset } = form;

  useEffect(() => {
    if (exact_start_at) {
      reset({ exact_start_at });
      setIsEditing(false);
    }
  }, [exact_start_at]);

  return isEditing ? (
    <FormProvider {...form}>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <FormDatetime
          name="exact_start_at"
          label=""
          noLabel
          minDate={dayjs().add(3, "day")}
        />
        <Box sx={{ display: "flex", mt: "16px", gap: "8px" }}>
          <Button
            variant="outlined"
            onClick={() => setIsEditing(false)}
            sx={{ padding: "0 24px", height: "32px" }}
          >
            キャンセル
          </Button>
          <FormSubmitButton
            label="保存する"
            sx={{ padding: "0 24px", height: "32px" }}
          />
        </Box>
      </Box>
    </FormProvider>
  ) : (
    <EditableText
      value={dateString}
      strong
      onChange={() => setIsEditing(true)}
      disabled={disabled}
    />
  );
};

export default LessonDateField;
