import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import type React from "react";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import { schoolMinutesOptions } from "constants/selectOptions";
import FormSelect from "components/molecules/FormSelect";
import { SelectOptions } from "types/SelectOptions";
import FormTextArea from "components/molecules/FormTextArea";

type AiTeachingPlanFormProps = {
  schoolGrades: SelectOptions;
};

const AiTeachingPlanForm: React.FC<AiTeachingPlanFormProps> = (props) => {
  const { schoolGrades } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        padding: "1rem",
        backgroundColor: theme.palette.common.white
      }}
    >
      <Typography variant="h3">
        以下の項目に必要事項を記入し、
        <br />
        AIに授業案を考えてもらいましょう！
      </Typography>
      <FormTextArea
        label="こんな授業がしたい"
        name="idea_request"
        placeholder="例：プログラミングを手を動かしながら学ぶような授業がしたい"
        isRequired
        customColor={theme.palette.advanced?.main}
      />
      <FormSelect
        label="授業を受ける学年"
        name="school_grade_id"
        options={schoolGrades}
        isRequired
        customColor={theme.palette.advanced?.main}
        fullWidth
      />
      <FormRadioGroup
        name="school_minutes"
        label="授業時間"
        options={schoolMinutesOptions}
        isRequired
        customColor={theme.palette.advanced?.main}
      />
      <Typography align="center" variant="caption" mb={-1}>
        授業案の生成には30~40秒程度かかります。
      </Typography>
      <FormSubmitButton
        label="授業案を作成する"
        sx={{
          backgroundColor: theme.palette.advanced?.main,
          "&:hover": {
            backgroundColor: theme.palette.advanced?.dark
          },
          "&:disabled": {
            backgroundColor: theme.palette.advanced?.main,
            opacity: 0.3
          }
        }}
      />
    </Box>
  );
};

export default AiTeachingPlanForm;
