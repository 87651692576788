import { useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";

import { exampleTeachersAtom } from "../store";

import type { exampleTeacherTypes } from "../store";

const useFilterTeachers = (values: Array<number>) => {
  const teachers = useAtomValue(exampleTeachersAtom);
  const [pickedTeachers, setPickedTeachers] = useState<
    Array<exampleTeacherTypes>
  >([]);

  const pickTeacher = useCallback(() => {
    const pickedTeachers = teachers.filter((teacher) =>
      values.includes(teacher.teacher_id)
    );

    pickedTeachers.sort(
      (x, y) => values.indexOf(x.teacher_id) - values.indexOf(y.teacher_id)
    );

    setPickedTeachers(pickedTeachers);
  }, [teachers, values]);

  useEffect(() => {
    pickTeacher();
  }, [pickTeacher, values]);

  return pickedTeachers;
};

export default useFilterTeachers;
