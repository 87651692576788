import type React from "react";
import { useAtomValue } from "jotai";
import FormSelect from "components/molecules/FormSelect";
import FormTextField from "components/molecules/FormTextField";
import FormTextArea from "components/molecules/FormTextArea";
import { schoolGradesAtom } from "../store";
import FieldsFrame from "./FieldsFrame";

const StudentsInfoFields: React.FC = () => {
  const schoolGrades = useAtomValue(schoolGradesAtom);
  const schoolGradeOptions = schoolGrades.map((schoolGrade) => ({
    value: schoolGrade.id,
    label: schoolGrade.name
  }));

  return (
    <FieldsFrame title="対象生徒（児童）">
      <FormSelect
        name="school_grade_id"
        label="授業を受ける学年"
        options={schoolGradeOptions}
        isRequired
      />
      <FormTextField
        name="student_number"
        label="生徒数（半角数字）"
        placeholder="例：30"
        isRequired
        type="number"
      />
      <FormTextArea
        name="student_condition"
        label="生徒の今の様子"
        placeholder="例）多くの生徒が将来の職業について具体的なイメージを持てていない状態です。特に、実際の職業世界の多様性や、そこで求められるスキルについての理解が不足しています。"
      />
      <FormTextArea
        name="issue"
        label="今感じている課題"
        placeholder="例）現実的なキャリアプランを立てる機会が少なく、将来への不安を感じている生徒もいます。また、実務経験を持つ方からのリアルな話を聞く機会が限られているため、実社会の理解を深めることが難しい点です。"
      />
    </FieldsFrame>
  );
};

export default StudentsInfoFields;
