import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link, useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import type React from "react";

const SpDrawerContentsGuest: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();

  const handleToSignup = () => {
    history.push("/signup");
  };

  const handleToTeacherSignin = () => {
    history.push("/teachers/login");
  };

  const handleToSchoolSignin = () => {
    history.push("/schools/login");
  };

  return (
    <>
      <div>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={handleToSignup}
          sx={{
            width: "100%",
            height: "48px",
            margin: ".5rem auto",
            fontWeight: 600
          }}
        >
          新規登録する（無料）
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={handleToTeacherSignin}
          sx={{
            width: "100%",
            height: "48px",
            margin: ".5rem auto",
            backgroundColor: theme.palette.backgroundColor?.main,
            color: theme.palette.textColor?.main
          }}
        >
          複業先生アカウントログイン
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={handleToSchoolSignin}
          sx={{
            width: "100%",
            height: "48px",
            margin: ".5rem auto",
            backgroundColor: theme.palette.backgroundColor?.main,
            color: theme.palette.textColor?.main
          }}
        >
          教員アカウントログイン
        </Button>
      </div>
      <Divider />
      <Typography sx={{ margin: "1rem auto" }}>
        <span>複業先生を導入したい</span>
        <br />
        <Box
          component={Link}
          to="/lp/school"
          sx={{
            textDecoration: "none",
            color: theme.palette.secondary.main
          }}
        >
          <span>教職員の方はこちら</span>
          <KeyboardArrowRightIcon sx={{ verticalAlign: "bottom" }} />
        </Box>
      </Typography>
    </>
  );
};

export default SpDrawerContentsGuest;
