import FormChipGroup from "components/molecules/FormChipGroup";
import FormTextArea from "components/molecules/FormTextArea";
import ProfileEditContentsAccordion from "features/teachers/profile/edit/organisms/ProfileEditContentsAccordion";
import { useAtomValue } from "jotai";

import type React from "react";
import { tags } from "../../new/store";

const ActivitiesCard: React.FC = () => {
  const tagsValue = useAtomValue(tags);

  const tagsOptions = tagsValue.map((tag) => ({
    value: tag.id,
    label: tag.name
  }));
  return (
    <ProfileEditContentsAccordion
      title="先生の活動"
      label="activities"
      fields={["tag_ids", "lesson_contents"]}
    >
      <FormChipGroup
        name="tag_ids"
        label="授業ができるテーマがあれば教えてください。（複数選択可）"
        options={tagsOptions}
        isRequired
        multiple
      />
      <FormTextArea
        name="lesson_contents"
        label="こんな授業ができます（250文字以内）"
        placeholder="例：金融業務で学んできたお金の流れについてお話することができます。企業や個人がお金を借りたり貸したりするメカニズムや、銀行がどのように預金を使ってローンを提供するかなどを具体的な事例を用いて学びます。また、金融市場や投資の仕組みも触れ、経済の動きとお金の関係を理解することで、将来のキャリアや投資判断に役立てる知識を身につけることができます。"
        isRequired
        maxCharacters={250}
      />
    </ProfileEditContentsAccordion>
  );
};

export default ActivitiesCard;
