import SitesField from "features/teachers/profile/new/molecules/SitesField";
import ProfileEditContentsAccordion from "features/teachers/profile/edit/organisms/ProfileEditContentsAccordion";

import type React from "react";

const WebsitesCard: React.FC = () => (
  <ProfileEditContentsAccordion
    title="ウェブサイト"
    label="sites"
    fields={["websites"]}
  >
    <SitesField />
  </ProfileEditContentsAccordion>
);

export default WebsitesCard;
