import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import type React from "react";
import { teacherInfoAtom } from "../store";

import TeachingPlanCard from "./TeachingPlanCard";

const TeachingPlansBox: React.FC = () => {
  const teacherInfo = useAtomValue(teacherInfoAtom);

  return (
    <div>
      <Typography variant="h4" fontSize="1rem" sx={{ margin: "0 auto .5rem" }}>
        指導案
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0 1rem",
          overflowX: "auto"
        }}
      >
        {teacherInfo?.teaching_plan_links.map((teachingPlanLink, index) => (
          <TeachingPlanCard
            key={index}
            title={teachingPlanLink.title}
            url={teachingPlanLink.url}
          />
        ))}
      </Box>
    </div>
  );
};

export default TeachingPlansBox;
