export const meetingScheduleHoursOptions: { label: string; value: number }[] = [
  { label: "6時", value: 6 },
  { label: "7時", value: 7 },
  { label: "8時", value: 8 },
  { label: "9時", value: 9 },
  { label: "10時", value: 10 },
  { label: "11時", value: 11 },
  { label: "12時", value: 12 },
  { label: "13時", value: 13 },
  { label: "14時", value: 14 },
  { label: "15時", value: 15 },
  { label: "16時", value: 16 },
  { label: "17時", value: 17 },
  { label: "18時", value: 18 },
  { label: "19時", value: 19 },
  { label: "20時", value: 20 },
  { label: "21時", value: 21 },
  { label: "22時", value: 22 },
  { label: "23時", value: 23 }
];
