import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useAtomValue } from "jotai";

import { teacherVideosShowAtom } from "store/TeacherVideosStore";
import type React from "react";
import LessonThemeBox from "../molecules/LessonThemeBox";
import PastClassesBox from "../molecules/PastClassesBox";
import TeachingPlansBox from "../molecules/TeachingPlansBox";
import TeachingVideosBox from "../molecules/TeachingVideosBox";
import { teacherInfoAtom, teacherLessonRequestsAtom } from "../store";

const LessonInfoBox: React.FC = () => {
  const theme = useTheme();
  const teacherInfo = useAtomValue(teacherInfoAtom);
  const teacherLessonRequests = useAtomValue(teacherLessonRequestsAtom);
  const teacherVideos = useAtomValue(teacherVideosShowAtom);

  return (
    <Box component="section" sx={{ boxSizing: "border-box" }}>
      <Typography
        variant="h3"
        sx={{
          margin: "0 auto .5rem",
          padding: { xs: "0 1rem", sm: "inherit" }
        }}
      >
        どんな授業ができるか
      </Typography>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          padding: { xs: "1rem", sm: "1.5rem" },
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <LessonThemeBox />
        {teacherLessonRequests.length > 0 && <PastClassesBox />}
        {teacherInfo?.teaching_plan_links &&
          teacherInfo.teaching_plan_links.length > 0 && <TeachingPlansBox />}
        {teacherVideos.length > 0 && <TeachingVideosBox />}
      </Box>
    </Box>
  );
};

export default LessonInfoBox;
