import * as yup from "yup";
import { Methods } from "api/api/v1/lessons/_lessonId@string/ratings";

export type postRatingsSchemaType = Methods["post"]["reqBody"];

const postRatingsSchema: yup.ObjectSchema<postRatingsSchemaType> = yup
  .object()
  .shape({
    lesson_rating: yup.number().required("授業評価を選択してください"),
    lesson_rating_reason: yup.string().required("授業評価理由は必須です"),
    teacher_rating: yup.number().required("先生評価を選択してください"),
    teacher_rating_reason: yup.string().required("先生評価理由は必須です")
  });

export default postRatingsSchema;
