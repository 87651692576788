import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";

import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import AnnouncementDialog from "components/organisms/AnnouncementDialog";
import Sidebar from "components/organisms/TeacherSidebar";
import TeacherRankCard from "./organisms/TeacherRankCard";
import TitleBlock from "./organisms/TitleBlock";
import useMyPageApi from "./useApi";
import HomeLessons from "./organisms/HomeLessons";
import HomeRecruitments from "./organisms/HomeRecruitments";
import HomeRequests from "./organisms/HomeRequests";
import JudgeNgAlert from "./molecules/JudgeNgAlert";
import JudgeConfirmationAlert from "./molecules/JudgeConfirmationAlert";
import useAnnouncement from "./hooks/useAnnouncement";

const Mypage: React.FC = () => {
  const theme = useTheme();
  const { announcementDialogOpen, announcements, confirmAnnouncement } =
    useAnnouncement();
  const {
    isJudging,
    judgeNg,
    judgeNgReason,
    handleRejudge,
    requestsIsLoading,
    recuitmentsIsLoading,
    lessonsIsLoading
  } = useMyPageApi();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <CommonLayout>
      <Meta title="複業先生｜マイページ" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem"
            }}
          >
            {isJudging && <JudgeConfirmationAlert />}
            {judgeNg && (
              <JudgeNgAlert
                handlePostRequest={handleRejudge}
                reason={judgeNgReason}
              />
            )}
            <TitleBlock title="マイページ" />
            <TeacherRankCard />
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "start", sm: "center" },
                gap: "1.5rem",
                width: "100%",
                flexDirection: { xs: "column", sm: "row" }
              }}
            >
              <HomeRequests isLoading={requestsIsLoading} />
              <HomeRecruitments isLoading={recuitmentsIsLoading} />
            </Box>
            <HomeLessons isLoading={lessonsIsLoading} />
          </Box>
        </Container>
      </Box>
      <AnnouncementDialog
        isOpen={announcementDialogOpen}
        announcements={announcements}
        confirmAnnouncement={confirmAnnouncement}
      />
    </CommonLayout>
  );
};

export default Mypage;
