import React from "react";
import { useAtomValue } from "jotai";
import { useFormContext } from "react-hook-form";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { selectedSchoolAtom } from "store/MasterSchoolStore";
import { formatDateString } from "utils/date";
import ConfirmField from "../molecules/ConfirmField";

import {
  schoolDivisionsData,
  schoolTypesData,
  prefecturesData,
  knowReasonsData
} from "../store";

const NewProfileConfirm: React.FC = () => {
  const theme = useTheme();
  const { getValues } = useFormContext();
  // MEMO: 表示用のStoreの情報のみを学校情報として表示
  const selectedSchool = useAtomValue(selectedSchoolAtom);
  const schoolDivisions = useAtomValue(schoolDivisionsData);
  const schoolTypes = useAtomValue(schoolTypesData);
  const prefectures = useAtomValue(prefecturesData);
  const knowReasons = useAtomValue(knowReasonsData);

  const schoolDivisionName = schoolDivisions.find(
    (schoolDivision) =>
      schoolDivision.id === Number(getValues("school_division_id"))
  )?.name;
  const schoolTypeName = schoolTypes.find(
    (schoolType) => schoolType.id === Number(getValues("school_type_id"))
  )?.name;
  const prefectureName = prefectures.find(
    (prefecture) => prefecture.id === Number(getValues("school_prefecture_id"))
  )?.name;
  const birthday = getValues("birthday")
    ? formatDateString(getValues("birthday"))
    : null;
  const knowReasonName = knowReasons.find(
    (knowReason) => knowReason.id === Number(getValues("know_reason_id"))
  )?.name;

  return (
    <Box>
      <Box sx={{ padding: "1rem 0 0" }}>
        <Typography variant="h1" align="center">
          以下の内容で送信してもよろしいでしょうか？
        </Typography>
      </Box>
      <Container maxWidth="sm">
        <Box
          component="section"
          sx={{
            margin: "1rem auto",
            padding: "1rem 1rem .5rem",
            backgroundColor: theme.palette.common.white,
            borderRadius: "4px"
          }}
        >
          <Typography variant="h2">学校の情報</Typography>
          <ConfirmField
            title="学校名"
            value={selectedSchool?.name || getValues("school_name")}
          />
          <Divider />
          <ConfirmField
            title="学校区分（小・中・高・大・高専）"
            value={selectedSchool?.school_division_name || schoolDivisionName}
          />
          <Divider />
          <ConfirmField
            title="運営主体（公立・私立・国立・付属）"
            value={selectedSchool?.school_type_name || schoolTypeName}
          />
          <Divider />
          <ConfirmField
            title="学校住所"
            value={
              <Typography>
                {selectedSchool?.prefecture_name || prefectureName}
                {selectedSchool?.address || getValues("school_address")}
              </Typography>
            }
          />
        </Box>
        <Box
          component="section"
          sx={{
            margin: "1rem auto",
            padding: "1rem 1rem .5rem",
            backgroundColor: theme.palette.common.white,
            borderRadius: "4px"
          }}
        >
          <Typography variant="h2">ご自身の情報</Typography>
          <ConfirmField
            title="顔写真"
            value={
              getValues("image") ? (
                <img src={getValues("image")} alt="登録予定の顔写真" />
              ) : (
                "未登録"
              )
            }
          />
          <Divider />
          <ConfirmField
            title="氏名"
            value={
              <div>
                <Typography fontSize=".75rem">
                  {getValues("kana_name")}
                </Typography>
                <Typography>{getValues("name")}</Typography>
              </div>
            }
          />
          <Divider />
          <ConfirmField title="生年月日" value={birthday || "未登録"} />
          <Divider />
          <ConfirmField
            title="担当学年"
            value={getValues("manage_grade") || "未登録"}
          />
          <Divider />
          <ConfirmField
            title="担当教科"
            value={getValues("manage_subject") || "未登録"}
          />
          <Divider />
          <ConfirmField
            title="校務分掌"
            value={getValues("school_job") || "未登録"}
          />
          <Divider />
          <ConfirmField
            title="メールマガジン受信設定"
            value={getValues("is_mail_magazine") ? "受信する" : "受信しない"}
          />
          <Divider />
          <ConfirmField
            title="複業先生を知ったきっかけ"
            value={knowReasonName || "未登録"}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default NewProfileConfirm;
