import React from "react";

import Typography from "@mui/material/Typography";

import QuestionnairesLayout from "components/layouts/QuestionnairesLayout";
import Meta from "components/organisms/Meta";
import ThanksAnswerBlock from "./organisms/ThanksAnswerBlock";

const SendedBeforeQuestionnaires: React.FC = () => (
  <QuestionnairesLayout title="授業前アンケート">
    <Meta
      title="複業先生 | 授業前アンケートへの回答完了"
      description="お疲れ様でした。この後の授業についてのアンケートは正常に送信されました。"
    />
    <ThanksAnswerBlock
      title="授業前アンケート、お疲れ様でした！"
      descriptions={
        <Typography textAlign="center">
          <Typography textAlign="center">
            アンケートを先生に提出しました。
          </Typography>
          <Typography textAlign="center">
            ぜひ楽しく授業受けてください！
          </Typography>
        </Typography>
      }
    />
  </QuestionnairesLayout>
);

export default SendedBeforeQuestionnaires;
