import Container from "@mui/material/Container";
import { CookiesProvider } from "react-cookie";

import Footer from "components/organisms/Footer";
import Meta from "components/organisms/Meta";
import Appeal from "features/root/lp/classi/organisms/Appeal";
import Conclusion from "features/root/lp/classi/organisms/Conclusion";
import FirstView from "features/root/lp/classi/organisms/FirstView";
import MainContents from "features/root/lp/classi/organisms/MainContents";

import type React from "react";
import AuthLayout from "components/layouts/AuthLayout";
import useLPClassiApi from "./useApi";

const LPClassi: React.FC = () => {
  useLPClassiApi();
  return (
    <CookiesProvider>
      <AuthLayout>
        <Meta noIndex />
        <FirstView />
        <Container maxWidth="lg">
          <MainContents />
          <Appeal />
        </Container>
        <Conclusion />
        <Footer />
      </AuthLayout>
    </CookiesProvider>
  );
};

export default LPClassi;
