import React from "react";

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type LessonInfoItemProps = {
  title: string;
  value: string | React.ReactNode;
};

const LessonInfoItem: React.FC<LessonInfoItemProps> = (props) => {
  const { title, value } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem"
      }}
    >
      <Typography sx={{ color: theme.palette.sub.main }}>{title}</Typography>
      {typeof value === "string" ? <Typography>{value}</Typography> : value}
    </Box>
  );
};

export default LessonInfoItem;
