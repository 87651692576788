import React from "react";

import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import Button from "components/atoms/mui/Button";

import { useFormContext } from "react-hook-form";

type FormStepProps = {
  step: number;
  activeStep: number;
  setActiveStep: (step: number) => void;
  stepLabel: string;
  children: React.ReactNode;
  stepCount: number;
};

// MEMO: formContext外では使わないこと
const FormStepContents: React.FC<FormStepProps> = (props) => {
  const { step, activeStep, setActiveStep, stepLabel, children, stepCount } =
    props;
  const { trigger } = useFormContext();

  const handleBack = async () => {
    await trigger();

    if (activeStep === 0) {
      return;
    }

    setActiveStep(activeStep - 1);
  };

  const handleNext = async () => {
    await trigger();

    if (activeStep === stepCount) {
      return;
    }

    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <StepLabel>{stepLabel}</StepLabel>
      <StepContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {children}
          {activeStep === stepCount || activeStep !== step ? null : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: ".5rem"
              }}
            >
              {activeStep !== 0 && (
                <Button size="small" color="inherit" onClick={handleBack}>
                  戻る
                </Button>
              )}
              <Button size="small" onClick={handleNext}>
                次へ
              </Button>
            </Box>
          )}
        </Box>
      </StepContent>
    </>
  );
};

export default FormStepContents;
