import React from "react";
import { useAtom } from "jotai";

import Pagination from "@mui/material/Pagination";
import useQueryParams from "hooks/useQueryParams";

import { paginationData } from "store/MasterData";

type QueryParamsType = {
  pages?: number;
};

// MEMO: page単位でPaginationがほしい場合に利用。1ページにつき1つのPaginationのみ利用。
const CommonPagePagination: React.FC = () => {
  const { updateQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();
  const [pagination, setPagination] = useAtom(paginationData);

  if (!pagination) return null;

  const handleChange = (pages: number) => {
    setPagination({ ...pagination, current: pages });
    if (pagination.current !== pages) {
      if (pages === 1) {
        deleteQueryParams("pages");
      } else {
        updateQueryParams("pages", pages);
      }
    }
  };

  if (pagination.count === 0) return null;

  return (
    <Pagination
      count={pagination?.pages}
      color="primary"
      variant="outlined"
      shape="rounded"
      onChange={(e, pages) => handleChange(pages)}
      page={pagination.current}
    />
  );
};

export default CommonPagePagination;
