import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import MasterSchoolField from "features/schools/profile/new/molecules/MasterSchoolField";

import type React from "react";

type EditSectionProps = {
  editSectionRef: React.RefObject<HTMLDivElement>;
};

const SchoolInfoCard: React.FC<EditSectionProps> = ({ editSectionRef }) => (
  <Card
    sx={{
      borderRadius: 0,
      margin: "1rem auto"
    }}
    elevation={0}
    ref={editSectionRef}
  >
    <CardContent sx={{ margin: ".5rem 0" }}>
      <Typography variant="h3">学校情報</Typography>
      <MasterSchoolField />
    </CardContent>
  </Card>
);

export default SchoolInfoCard;
