import React from "react";
import { useAtomValue } from "jotai";
import { useFormContext, useFieldArray } from "react-hook-form";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

import FormTextArea from "components/molecules/FormTextArea";
import FormSubmitButton from "components/molecules/FormSubmitButton";
import Button from "components/atoms/mui/Button";
import Check from "components/atoms/fields/Check";
import type { Methods } from "api/api/v1/account_deletion_requests";
import FormStep from "../molecules/FormStepContents";

import { leavingReasonsAtom } from "../store";

type AccountDeletionRequestProps = {
  requestAccountDeletion: () => Promise<void>;
};

const AccountDeletionRequestForm: React.FC<AccountDeletionRequestProps> = (
  props
) => {
  const { requestAccountDeletion } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const leavingReasons = useAtomValue(leavingReasonsAtom);

  const { control } = useFormContext<Methods["post"]["reqBody"]>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "leaving_reasons",
    keyName: "_id"
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { checked } = event.target;

    if (checked) {
      append({ id, reason_details: "" });
    } else {
      const index = fields.findIndex((field) => field.id === id);
      if (index >= 0) {
        remove(index);
      }
    }
  };

  return (
    <Box
      component="form"
      onSubmit={requestAccountDeletion}
      sx={{ width: "100%" }}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step active>
          <FormStep
            step={0}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            stepLabel="退会の理由を選択してください。"
            stepCount={2}
          >
            {leavingReasons?.map((leavingReason) => {
              const isChecked = fields.some(
                (field) => field.id === leavingReason.id
              );

              return (
                <Check
                  label={leavingReason.reason}
                  checked={isChecked}
                  onChange={(event) =>
                    handleChange(
                      event as React.ChangeEvent<HTMLInputElement>,
                      leavingReason.id
                    )
                  }
                />
              );
            })}
          </FormStep>
        </Step>
        <Step active={activeStep >= 1}>
          <FormStep
            step={1}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            stepLabel="具体的な理由を教えてください。"
            stepCount={2}
          >
            {fields.map((field, index) => {
              const getSelectedLeavingReason = () =>
                leavingReasons?.find(
                  (leavingReason) => leavingReason.id === field.id
                );

              return (
                <FormTextArea
                  key={field._id}
                  name={`leaving_reasons[${index}].reason_details`}
                  label={getSelectedLeavingReason()?.reason || ""}
                  placeholder=""
                  isRequired={getSelectedLeavingReason()?.id === 4}
                />
              );
            })}
          </FormStep>
        </Step>
        <Step active={activeStep >= 2}>
          <FormStep
            step={2}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            stepLabel="最後にフィードバックなどがあれば教えてください。"
            stepCount={2}
          >
            <FormTextArea name="feedback" label="" noLabel placeholder="例）" />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: ".5rem"
              }}
            >
              <Button
                size="small"
                color="inherit"
                onClick={() => setActiveStep(activeStep - 1)}
              >
                戻る
              </Button>
              <FormSubmitButton label="退会申請を送信する" sx={{ flex: 1 }} />
            </Box>
          </FormStep>
        </Step>
      </Stepper>
    </Box>
  );
};

export default AccountDeletionRequestForm;
