import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useAtomValue } from "jotai";

import SchoolCertificationBadge from "assets/SchoolCertificationBadge.png";
import { currentUserAtom } from "store/AuthStore";
import { currentSchoolAtom } from "store/SchoolAuthStore";

import type React from "react";
import useTeacherRankBadge from "hooks/useTeacherRankBadge";

type SidebarAccountInfoProps = {
  type: "teacher" | "school";
};

const SidebarAccountInfo: React.FC<SidebarAccountInfoProps> = (props) => {
  const { type } = props;
  const currentSchool = useAtomValue(currentSchoolAtom);
  const currentUser = useAtomValue(currentUserAtom);

  let accountName = "";
  let accountImage = "";
  let isCertified = null;

  if (type === "teacher") {
    accountName = currentUser.name;
    accountImage = currentUser.image?.url;
  } else if (type === "school") {
    accountName = currentSchool.name;
    accountImage = currentSchool.image?.url;
    isCertified = currentSchool.is_match_accepted;
  }

  const teacherRankBadge = useTeacherRankBadge(currentUser.teacher_rank);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        padding: ".5rem 1rem"
      }}
    >
      <Avatar src={accountImage} alt={accountName} />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        <Typography fontWeight="bold">{accountName}</Typography>
        {isCertified && (
          <Box
            component="img"
            src={SchoolCertificationBadge}
            alt="認定校"
            sx={{
              display: "block",
              height: "18px"
            }}
          />
        )}
        {teacherRankBadge.src && (
          <Box
            component="img"
            src={teacherRankBadge.src}
            alt={teacherRankBadge.alt}
            sx={{
              display: "block",
              height: "18px"
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default SidebarAccountInfo;
