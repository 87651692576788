import Box from "@mui/material/Box";
import { useRef } from "react";

import InquiryBasedImage from "assets/lpClassi/InquiryBased.png";
import type React from "react";
import Overview from "../molecules/Overview";
import useFilterTeachers from "../hooks/useFilterTeachers";

import LessonExample from "./LessonExample";
import LessonIdeas from "./LessonIdeas";

const InquiryBased: React.FC = () => {
  // MEMO: 基本的に複数回のレンダリングが必要ないと目されるため。
  const teacherWithVideoIdsRef = useRef([965, 92]);
  const teacherIdsRef = useRef([965, 92, 1303, 1133]);
  const teachersWithVideo = useFilterTeachers(teacherWithVideoIdsRef.current);
  const teachers = useFilterTeachers(teacherIdsRef.current);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "3rem"
      }}
    >
      <Overview
        title="探究学習"
        desc="探究活動の導入期に最適。STEAM教育をベースに、実践者の生き方や考え方に触れ、生徒の皆様が自分の探究テーマを見つけるきっかけを作ります。"
        Image={InquiryBasedImage}
      />
      <LessonIdeas title="探究学習" teachers={teachersWithVideo} />
      <LessonExample title="探究学習" teachers={teachers} />
    </Box>
  );
};

export default InquiryBased;
