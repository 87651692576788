import FormChipGroup from "components/molecules/FormChipGroup";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import { existenceOptions, frequencyOptions } from "constants/selectOptions";

import { useAtomValue } from "jotai";

import type React from "react";
import FormTextArea from "components/molecules/FormTextArea";
import { Box } from "@mui/material";
import { organizationCategories, tags } from "../store";
import StepTitle from "../atoms/StepTitle";

const NewProfileFormStep3: React.FC = () => {
  const organizationCategoriesValue = useAtomValue(organizationCategories);
  const tagsValue = useAtomValue(tags);
  const organizationCategoriesOptions = organizationCategoriesValue.map(
    (category) => ({
      value: category.id,
      label: category.name
    })
  );
  const tagsOptions = tagsValue.map((tag) => ({
    value: tag.id,
    label: tag.name
  }));
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        padding: "0.5rem 0"
      }}
    >
      <StepTitle
        stepName={["複業先生登録にあたり、", "必要なことを教えてください。"]}
        indicateStatus="public"
      />
      <FormRadioGroup
        name="is_experience_lesson"
        label="学校機関などで授業・講演などを行ったことがありますか？"
        options={existenceOptions}
        isRequired
      />
      <FormChipGroup
        name="organization_category_ids"
        label="現在所属している企業の業界・専門領域で該当するものはどれですか？（複数選択可）"
        options={organizationCategoriesOptions}
        isRequired
        multiple
      />
      <FormChipGroup
        name="tag_ids"
        label="授業ができるテーマがあれば教えてください。（複数選択可）"
        options={tagsOptions}
        isRequired
        multiple
      />
      <FormTextArea
        name="lesson_contents"
        label="こんな授業ができます（250文字以内）"
        placeholder="例：金融業務で学んできたお金の流れについてお話することができます。企業や個人がお金を借りたり貸したりするメカニズムや、銀行がどのように預金を使ってローンを提供するかなどを具体的な事例を用いて学びます。また、金融市場や投資の仕組みも触れ、経済の動きとお金の関係を理解することで、将来のキャリアや投資判断に役立てる知識を身につけることができます。"
        isRequired
        maxCharacters={250}
      />
      <FormRadioGroup
        name="frequency"
        label="希望活動頻度"
        options={frequencyOptions}
        row
      />
    </Box>
  );
};

export default NewProfileFormStep3;
