import * as yup from "yup";
import { Methods } from "api/api/v1/account_deletion_requests";

type AccountDeletionRequestFormSchemaType = Methods["post"]["reqBody"];

export const accountDeletionRequestFormSchema: yup.ObjectSchema<AccountDeletionRequestFormSchemaType> =
  yup.object().shape({
    feedback: yup.string().optional(),
    leaving_reasons: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(),
          reason_details: yup
            .string()
            .when("id", (id, schema) =>
              Number(id) === 4
                ? schema.required("その他の理由を入力してください")
                : schema.optional()
            )
        })
      )
      .required()
  });
