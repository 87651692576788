import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type React from "react";
import { AnnouncementType } from "types/announcementTypes";
import AnnouncementBox from "components/molecules/AnnouncementBox";

// onCloseを渡す必要がないため、CommonDialogを使わない
interface AnnouncementDialogProps {
  isOpen: boolean;
  announcements: AnnouncementType[];
  confirmAnnouncement: (id: number) => void;
}

const AnnouncementDialog: React.FC<AnnouncementDialogProps> = ({
  isOpen,
  announcements,
  confirmAnnouncement
}) => (
  <Dialog open={isOpen} maxWidth="sm" fullWidth>
    <Box sx={{ padding: "1rem" }}>
      <Typography
        fontSize="1.2rem"
        fontWeight="bold"
        margin="0 auto 1rem"
        align="center"
      >
        重要なお知らせ
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".75rem 0"
        }}
      >
        {announcements.map((announcement, index) => (
          <AnnouncementBox
            key={index}
            title={announcement.title}
            contents={announcement.contents}
            handleConfirm={() => confirmAnnouncement(announcement.id)}
          />
        ))}
      </Box>
    </Box>
  </Dialog>
);

export default AnnouncementDialog;
