import { FormProvider } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";
import { Typography } from "@mui/material";
import AccountDeletionRequestForm from "./organisms/AccountDeletionRequestForm";

import useAccountDeletionApi from "./useApi";

const AccountDeletionRequest: React.FC = () => {
  const theme = useTheme();

  const { requestAccountDeletion, methods } = useAccountDeletionApi();

  return (
    <CommonLayout>
      <Meta title="複業先生｜退会申請" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          height: "100%",
          padding: "1.5rem 0"
        }}
      >
        <Container maxWidth="lg">
          <Box>
            <Typography variant="h1">退会申請</Typography>
          </Box>
        </Container>
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem",
            margin: "1.5rem auto",
            backgroundColor: theme.palette.common.white,
            borderRadius: "8px"
          }}
        >
          <FormProvider {...methods}>
            <AccountDeletionRequestForm
              requestAccountDeletion={methods.handleSubmit(
                requestAccountDeletion
              )}
            />
          </FormProvider>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default AccountDeletionRequest;
