import useAspidaSWR from "@aspida/swr";
import { useAlertMessages } from "hooks/useAlertMessages";
import useHeaders from "hooks/useHeaders";
import aspida from "libs/aspida";
import { useForm } from "react-hook-form";

import type { Methods } from "api/api/v1/teaching_plan_ideas";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { useAtom } from "jotai";
import { teachingPlanResponseAtom } from "./store";

const useAiTeachingPlanNewPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const history = useHistory();
  const [, setTeachingPlanResponse] = useAtom(teachingPlanResponseAtom);
  const [postIsLoading, setPostIsLoading] = useState(false);

  const { data, isLoading: getIsLoading } = useAspidaSWR(
    aspida(headers).api.v1.teaching_plan_ideas.new,
    {
      onError: () => {
        addErrorMessage("情報の取得に失敗しました");
      }
    }
  );

  const form = useForm<Methods["post"]["reqBody"]>({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        school_grade_id: yup.number().required("必須の項目です"),
        school_minutes: yup.number().required("必須の項目です"),
        idea_request: yup.string().required("必須の項目です")
      })
    )
  });

  const createNewPlan = async () => {
    try {
      setPostIsLoading(true);
      const body = form.getValues();
      const response = await aspida(headers).api.v1.teaching_plan_ideas.post({
        body
      });
      if (response.body) {
        setTeachingPlanResponse(
          response.body.data.details.teaching_plan_response
        );
        history.push("/schools/mypage/ai-teaching-plan/result");
      }
    } catch (error) {
      addErrorMessage("授業案の作成に失敗しました");
    } finally {
      setPostIsLoading(false);
    }
  };

  const schoolGrades = data?.map((grade) => ({
    label: grade.name,
    value: grade.id
  }));

  const isLoading = getIsLoading || postIsLoading;

  return { schoolGrades, createNewPlan, form, isLoading };
};

export default useAiTeachingPlanNewPageApi;
